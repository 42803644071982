import React from "react"

import { IconProps } from "components/Icons/Icons"

const Laptop: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 42 42"
    fill={color}
    {...props}
  >
    <path d="M35,31.6005472 L35,29.8194254 C36.925,29.8194254 38.4825,28.2164159 38.4825,26.2571819 L38.5,8.44596443 C38.5,6.48673051 36.925,4.88372093 35,4.88372093 L7,4.88372093 C5.075,4.88372093 3.5,6.48673051 3.5,8.44596443 L3.5,26.2571819 C3.5,28.2164159 5.075,29.8194254 7,29.8194254 L7,31.6005472 L0,31.6005472 L0,35.1627907 L42,35.1627907 L42,31.6005472 L35,31.6005472 Z M7,8.44596443 L35,8.44596443 L35,26.2571819 L7,26.2571819 L7,8.44596443 Z" />
  </svg>
)
export default Laptop
