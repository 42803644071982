import React from "react"

import { IconProps } from "components/Icons/Icons"

const Angry: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M11.9999999,-5.00000006e-08 C5.39999997,-5.00000006e-08 -5.00000006e-08,5.39999997 -5.00000006e-08,11.9999999 C-5.00000006e-08,18.6 5.39999997,23.9999999 11.9999999,23.9999999 C18.6,23.9999999 23.9999999,18.6 23.9999999,11.9999999 C23.9999999,5.39999997 18.6,-5.00000006e-08 11.9999999,-5.00000006e-08 Z M12,22.4 C6.29999998,22.4 1.59999996,17.7 1.59999996,12 C1.59999996,6.29999998 6.29999998,1.59999996 12,1.59999996 C17.7,1.59999996 22.4,6.29999998 22.4,12 C22.4,17.7 17.7,22.4 12,22.4 Z M8.79999999,11.2 C9.69999999,11.2 10.4,10.5 10.4,9.59999999 L10.4,7.99999998 L6.39999998,7.99999998 L6.39999998,9.59999999 L7.19999998,9.59999999 C7.19999998,10.5 7.89999998,11.2 8.79999999,11.2 Z M15.2,11.2 C16.1,11.2 16.8,10.5 16.8,9.59999999 L17.6,9.59999999 L17.6,7.99999998 L13.6,7.99999998 L13.6,9.59999999 C13.6,10.5 14.3,11.2 15.2,11.2 Z M12,14.4 C9.39999999,14.4 7.09999998,15.8 5.79999997,17.9 L7.19999998,18.7 C8.19999998,17.1 9.99999999,16 12,16 C14,16 15.8,17.1 16.8,18.7 L18.2,17.9 C16.9,15.8 14.6,14.4 12,14.4 Z" />
  </svg>
)
export default Angry
