import React from "react"
import { Navigate, Outlet, RouteProps } from "react-router-dom"

import SessionManager from "api/SessionManager"
import { dashboardRouteRewriter, Role } from "types/Role"

type PrivateRouteProps = {
  roles: Role[]
} & RouteProps

const PrivateRoute = ({ roles }: PrivateRouteProps) => {
  const userRole = SessionManager.getUserRole()

  if (!userRole) {
    return <Navigate to={dashboardRouteRewriter.UNKNOWN} replace={true} />
  }

  if (roles && roles.indexOf(userRole) === -1) {
    return <Navigate to={dashboardRouteRewriter[userRole]} replace />
  }

  return <Outlet />
}
export default PrivateRoute