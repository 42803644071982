import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { PromiseAction } from "types/RemoteAction"
import { CustomTag } from "types/Template"
import { showErrorNotification } from "utils/errors"

export const fetchCustomTags =
  () =>
  (dispatch: Dispatch): PromiseAction<ResultModel<CustomTag[]>> =>
    dispatch({
      type: "customTag/FETCH",
      promise: Api.getCustomTags()
    })

export const addCustomTag = (name: string) => async (dispatch: Dispatch) => {
  try {
    const res = await Api.addCustomTag(name)
    dispatch({
      type: "customTag/ADD",
      resolved: "ADD",
      payload: res.result
    })
  } catch (err) {
    showErrorNotification(err, "Nie udało się dodać etykiety")
  }
}

export const deleteCustomTag = (id: string) => async (dispatch: Dispatch) => {
  try {
    await Api.deleteCustomTag(id)
    dispatch({
      type: "customTag/REMOVE",
      resolved: "REMOVE",
      payload: id
    })
  } catch (err) {
    showErrorNotification(err, "Nie udało się usunąć etykiety")
  }
}
