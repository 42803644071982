import { schema, normalize } from "normalizr"
import { createSelector } from "reselect"

import { FormActivity } from "types/Template"

import { AppState } from "../AppState"

export const selectFormActivityFetchError = (s: AppState) =>
  s.formActivity.error

export const selectFormActivityListData = (s: AppState) => s.formActivity.data

export const selectFormActivityList = createSelector(
  selectFormActivityListData,
  (formActivityList) => formActivityList || []
)

export const selectNormalizedAllActivityForms = createSelector(
  selectFormActivityList,
  (formActivityList) => {
    if (formActivityList.length === 0) {
      return {}
    }

    const activityForm = new schema.Entity<FormActivity>(
      "activityForm",
      {},
      { idAttribute: "value" }
    )

    return (
      normalize(formActivityList, [activityForm]).entities.activityForm || {}
    )
  }
)
