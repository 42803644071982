import React from "react"

import { IconProps } from "components/Icons/Icons"

const Clipboard: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill={color}
    {...props}
  >
    <path
      d="M6.75 32.9609C6.15 32.9609 5.625 32.7359 5.175 32.2859C4.725 31.8359 4.5 31.3109 4.5 30.7109V8.09844H6.75V30.7109H24.525V32.9609H6.75ZM11.25 28.4609C10.65 28.4609 10.125 28.2359 9.675 27.7859C9.225 27.3359 9 26.8109 9 26.2109V5.21094C9 4.61094 9.225 4.08594 9.675 3.63594C10.125 3.18594 10.65 2.96094 11.25 2.96094H27.75C28.35 2.96094 28.875 3.18594 29.325 3.63594C29.775 4.08594 30 4.61094 30 5.21094V26.2109C30 26.8109 29.775 27.3359 29.325 27.7859C28.875 28.2359 28.35 28.4609 27.75 28.4609H11.25ZM11.25 26.2109H27.75V5.21094H11.25V26.2109Z"
      fill={color}
    />
  </svg>
)
export default Clipboard
