import { Dispatch } from "redux"

import { Api } from "api"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import { News } from "types/News"
import { PromiseAction } from "types/RemoteAction"

export const fetchPublishedNews =
  (pagination: PaginationRequest, search?: string) =>
  (dispatch: Dispatch): PromiseAction<ResultModel<News[]>> =>
    dispatch({
      type: "published/FETCH",
      promise: search
        ? Api.searchPublishedNews(search, pagination)
        : Api.fetchNews("Published", pagination)
    })
