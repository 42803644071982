import { AnyAction } from "redux"

import { Error } from "types/ApiResponse"

export const FAILURE = "FAILURE"

export interface Failure<T = any, E extends Error = any> extends AnyAction {
  resolved: typeof FAILURE
  type: T
  payload: E
}
