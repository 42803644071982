import { CSSProperties } from "react"
import { css } from "styled-components"

export interface BorderProps {
  $border?: CSSProperties["border"]
  $borderWidth?: CSSProperties["borderWidth"]
  $borderStyle?: CSSProperties["borderStyle"]
  $borderColor?: CSSProperties["borderColor"]
  $borderRadius?: CSSProperties["borderRadius"]
  $borderTop?: CSSProperties["borderTop"]
  $borderRight?: CSSProperties["borderRight"]
  $borderBottom?: CSSProperties["borderBottom"]
  $borderLeft?: CSSProperties["borderLeft"]
}

export const borderProps = css<BorderProps>`
  border: ${({ $border }) => $border};
  border-width: ${({ $borderWidth }) => $borderWidth};
  border-style: ${({ $borderStyle }) => $borderStyle};
  border-color: ${({ $borderColor }) => $borderColor};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  border-top: ${({ $borderTop }) => $borderTop};
  border-right: ${({ $borderRight }) => $borderRight};
  border-bottom: ${({ $borderBottom }) => $borderBottom};
  border-left: ${({ $borderLeft }) => $borderLeft};
`
