import { normalize, schema } from "normalizr"
import { createSelector } from "reselect"

import { PKD } from "types/Template"

import { AppState } from "../AppState"

export const selectPKDFetchError = (s: AppState) => s.pkd.error

export const selectPKDListData = (s: AppState) => s.pkd.data

export const selectPKDList = createSelector(
  selectPKDListData,
  (_: AppState, formType: "client" | "news") => formType,
  (pkdList, formType) => {
    if (formType === "client") {
      return pkdList?.filter((pkd) => pkd.level === 4) || []
    }
    return pkdList || []
  }
)

export const selectNormalizedAllPKD = createSelector(
  selectPKDListData,
  (pkdList) => {
    if (!pkdList || pkdList.length === 0) {
      return {}
    }

    const pkd = new schema.Entity<PKD>("pkd", {}, { idAttribute: "value" })

    return normalize(pkdList, [pkd]).entities.pkd || {}
  }
)
