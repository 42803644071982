import React from "react"

import { IconProps } from "components/Icons/Icons"

const Eye: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M11.9999999,3.19999996 C6.89999998,3.19999996 -5.00000006e-08,8.39999998 -5.00000006e-08,12 C-5.00000006e-08,15.6 6.89999998,20.8 11.9999999,20.8 C17.1,20.8 23.9999999,15.6 23.9999999,12 C23.9999999,8.39999998 17.1,3.19999996 11.9999999,3.19999996 Z M12,19.2 C7.29999998,19.2 1.59999996,14.4 1.59999996,12 C1.59999996,9.59999999 7.29999998,4.79999997 12,4.79999997 C16.7,4.79999997 22.4,9.59999999 22.4,12 C22.4,14.4 16.7,19.2 12,19.2 Z M12,6.39999998 C8.89999998,6.39999998 6.39999998,8.89999998 6.39999998,12 C6.39999998,15.1 8.89999998,17.6 12,17.6 C15.1,17.6 17.6,15.1 17.6,12 C17.6,8.89999998 15.1,6.39999998 12,6.39999998 Z M12,16 C9.79999999,16 7.99999998,14.2 7.99999998,12 C7.99999998,9.79999999 9.79999999,7.99999998 12,7.99999998 C14.2,7.99999998 16,9.79999999 16,12 C16,14.2 14.2,16 12,16 Z" />
  </svg>
)
export default Eye
