import React from "react"

import { IconProps } from "components/Icons/Icons"

const Plus: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <polygon points="22.4 11.2 12.8 11.2 12.8 1.59999996 11.2 1.59999996 11.2 11.2 1.59999996 11.2 1.59999996 12.8 11.2 12.8 11.2 22.4 12.8 22.4 12.8 12.8 22.4 12.8" />
  </svg>
)

export default Plus
