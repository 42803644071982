import { Dispatch } from "redux"

import { Api } from "api"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import { NewsletterConfirmation } from "types/Newsletter"
import { PromiseAction } from "types/RemoteAction"

export const fetchConfirmations =
  (pagination: PaginationRequest) =>
  (dispatch: Dispatch): PromiseAction<ResultModel<NewsletterConfirmation[]>> =>
    dispatch({
      type: "confirmation/FETCH",
      promise: Api.getNewsletterConfirmations(pagination)
    })
