import React from "react"

export const useRenderTooltip = (tooltips: string[]) => {
  const filteredTooltips = tooltips.filter((tooltip) => tooltip?.length)
  const lastTooltipIndex = filteredTooltips.length - 1

  return (
    <>
      {filteredTooltips.map((tooltip, index) => (
        <p
          key={index}
          style={{ marginBottom: index === lastTooltipIndex ? 0 : "8px" }}
        >
          {tooltip}
        </p>
      ))}
    </>
  )
}
