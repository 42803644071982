import { createSelector } from "reselect"

import SessionManager from "api/SessionManager"
import { AccountingOfficeStageEnum, RoleEnum } from "enums"
import { selectAccountingOfficeConfigurationStage } from "store/accountingOffice"

export const selectCreatorModeStatus = createSelector(
  selectAccountingOfficeConfigurationStage,
  (accountingOfficeConfigurationStage) => {
    return (
      accountingOfficeConfigurationStage ===
        AccountingOfficeStageEnum.Creator &&
      SessionManager.getSession()?.userRole === RoleEnum.AO_Publisher
    )
  }
)