import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import placeholder from "assets/img/placeholder.png"
import { AppState } from "store/AppState"
import { fetchThumbnail, selectThumbnailById } from "store/thumbnail"

import { ImgThumbnail } from "./styles"

interface ImgMinatureProps {
  imageId?: string | null
  size?: "small" | "normal" | "large"
}

export const ImgMinature: React.FC<ImgMinatureProps> = ({
  imageId,
  ...props
}) => {
  const dispatch = useDispatch()
  const thumbnail = useSelector((s: AppState) =>
    selectThumbnailById(s, imageId)
  )

  useEffect(() => {
    !thumbnail && dispatch(fetchThumbnail(imageId))
  }, [dispatch, imageId, thumbnail])

  return <ImgThumbnail {...props} src={thumbnail || placeholder} />
}
