import { Dispatch } from "redux"

import { Api } from "api"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import { NewsAO } from "types/News"
import { PromiseAction } from "types/RemoteAction"

export const fetchOriginalNewsAOAction =
  (pagination: PaginationRequest) =>
  (dispatch: Dispatch): PromiseAction<ResultModel<NewsAO[]>> =>
    dispatch({
      type: "originalNewsAO/FETCH",
      promise: Api.fetchOriginalNews(pagination)
    })
