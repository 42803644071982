import React from "react"

import { IconProps } from "components/Icons/Icons"

const Filter: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M0.799999953,1.59999996 L9.59999999,12.8 L9.59999999,22.4 L14.4,20 L14.4,12.8 L23.2,1.59999996 L0.799999953,1.59999996 Z M12.8,12.2 L12.8,19 L11.2,19.8 L11.2,12.2 L4.09999997,3.19999996 L19.9,3.19999996 L12.8,12.2 Z" />
  </svg>
)
export default Filter
