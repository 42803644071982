export enum TourEnum {
  ADD_TEST_CLIENT = "ADD_TEST_CLIENT",
  MAIN_PAGE = "MAIN_PAGE",
  TEST_MAIN_PAGE = "TEST_MAIN_PAGE",
  SELECT_TEST_NEWS_PAGE = "SELECT_TEST_NEWS_PAGE",
  SEND_TEST_NEWS_PAGE = "SEND_TEST_NEWS_PAGE"
}

export enum AccountingOfficeStageEnum {
  Creator = "Creator",
  AddTestClient = "AddTestClient",
  TestMainPage = "TestMainPage",
  SelectTestNewsPage = "SelectTestNewsPage",
  Done = "Done"
}

export const aoStageToTourMapper: Record<
  AccountingOfficeStageEnum,
  TourEnum | null
> = {
  [AccountingOfficeStageEnum.Creator]: null,
  [AccountingOfficeStageEnum.AddTestClient]: TourEnum.ADD_TEST_CLIENT,
  [AccountingOfficeStageEnum.TestMainPage]: TourEnum.TEST_MAIN_PAGE,
  [AccountingOfficeStageEnum.SelectTestNewsPage]:
    TourEnum.SELECT_TEST_NEWS_PAGE,
  [AccountingOfficeStageEnum.Done]: null
}