import { Modal } from "antd"
import { styled } from "styled-components"

import { device, responsiveContainerStyle } from "style"

export const Wrapper = styled.div`
  padding: 60px 50px;

  svg {
    margin-bottom: 12px;
  }
`

interface ClientCountProps {
  color: string
}

export const ClientCount = styled.div<ClientCountProps>`
  width: 100px;
  height: 64px;
  border-radius: 5px;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
`

export const ListWrapper = styled.div`
  margin: 16px 0 56px;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;

  & > div {
    border-top: 1px solid ${({ theme }) => theme.colors.borderGrey};

    &:last-child {
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderGrey};
    }
  }
`

export const RowWrapper = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  padding: 24px 80px;

  @media ${device.mobileS} {
    padding: 24px 6px;
    & .contact-data {
      text-align: left;
    }
  }

  @media ${device.tablet} {
    padding: 24px 12px;
    & .contact-data {
      text-align: right;
    }
  }

  @media ${device.laptop} {
    padding: 24px 24px;
  }

  @media ${device.laptopM} {
    padding: 24px 40px;
    .contact-data {
      text-align: left;
    }
  }

  h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
  }

  &:hover {
    box-shadow: 0px 1px 12px -4px ${({ theme }) => theme.colors.textPlaceholder};
  }
`

export const StyledModal = styled(Modal)`
  &&& {
    ${responsiveContainerStyle}
  }
`
