import { useCallback, useState } from "react"

export const useClientRect = () => {
  const [rect, setRect] = useState<ClientRect>()
  const ref = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect())
    }
  }, [])
  return [rect, ref] as const
}
