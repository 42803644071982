import groupBy from "lodash/groupBy"
import { normalize, schema } from "normalizr"
import { createSelector } from "reselect"

import { TagStructure } from "types/Template"

import { AppState } from "../AppState"

export const selectLabelFetchError = (s: AppState) => s.label.error

export const selectLabelListData = (s: AppState) => s.label.data

export const selectLabelList = createSelector(
  selectLabelListData,
  (labelList) => labelList || []
)

export const selectNormalizedAllLabels = createSelector(
  selectLabelList,
  (labelList) => {
    if (labelList.length === 0) {
      return {}
    }
    const label = new schema.Entity<TagStructure>(
      "label",
      {},
      { idAttribute: "code" }
    )

    const labelGroup = new schema.Entity<TagStructure>(
      "group",
      { children: [label] },
      { idAttribute: "name" }
    )

    return normalize(labelList, [labelGroup]).entities.label || {}
  }
)

export const selectFlatTags = createSelector(selectLabelList, (tags) => {
  return tags.flatMap((tag) => tag.children)
})

export const selectRequiredGroups = createSelector(selectFlatTags, (tags) => {
  return Object.values(groupBy(tags, (tag) => tag.group)).filter(
    (tags) => tags.length > 1
  )
})
