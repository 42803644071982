import { request, serialize } from "api/config"
import { ResultModel } from "types/ApiResponse"
import { NotificationSettings, UserNotifications } from "types/Notification"
import { RefreshTokenRequest, Session, Tokens } from "types/Session"
import { apiUrl } from "utils/config"

const PATH_NAME = "Users"

export const authenticate = async (
  borg_sessionId: string
): Promise<ResultModel<Session>> =>
  request.get(`${PATH_NAME}/ssologin${serialize({ borg_sessionId })}`)

export const getNotificationSettings = async (): Promise<
  ResultModel<NotificationSettings>
> => request.get(`${PATH_NAME}/Notifications`)

export const saveNotificationSettings = async (
  notifications: NotificationSettings
): Promise<ResultModel<NotificationSettings>> =>
  request.put(`${PATH_NAME}/Notifications`, notifications)

export const getUserNotifications = async (): Promise<
  ResultModel<UserNotifications>
> => request.get(`${PATH_NAME}/UserNotifications`)

export const refreshToken = async (
  payload: RefreshTokenRequest
): Promise<ResultModel<Tokens>> =>
  fetch(`${apiUrl}/${PATH_NAME}/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  }).then((res) => res.json())
