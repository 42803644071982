import { styled, css } from "styled-components"

import Flexbox from "components/Flexbox"

const sectionBoxStyles = css`
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.paddings.large};
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};

  .ant-form-item-label > label {
    width: 100%;
  }
`

export const SectionBox = styled.div`
  ${sectionBoxStyles}
`

export const SectionBoxRow = styled(Flexbox)`
  ${sectionBoxStyles}
  .ant-form-item-label > label {
    width: unset;
  }
`
