import { AppState } from "store/AppState"

export const selectNewsletterGroupsPending = (s: AppState) =>
  s.newsletter.newsletterGroups.pending

export const selectNewsletterGroupsFetchError = (s: AppState) =>
  s.newsletter.newsletterGroups.error

export const selectNewsletterGroupsPagination = (s: AppState) =>
  s.newsletter.newsletterGroups.pagination

export const selectNewsletterGroupsList = (s: AppState) =>
  s.newsletter.newsletterGroups.data || []
