import { css } from "styled-components"

export interface SpaceProps {
  $m?: string
  $margin?: string
  $mt?: string
  $marginTop?: string
  $mr?: string
  $marginRight?: string
  $mb?: string
  $marginBottom?: string
  $ml?: string
  $marginLeft?: string
  $p?: string
  $padding?: string
  $pt?: string
  $paddingTop?: string
  $pr?: string
  $paddingRight?: string
  $pb?: string
  $paddingBottom?: string
  $pl?: string
  $paddingLeft?: string
}

export const spaceProps = css<SpaceProps>`
  margin: ${({ $margin, $m }) => $margin ?? $m};
  margin-top: ${({ $marginTop, $mt }) => $marginTop ?? $mt};
  margin-right: ${({ $marginRight, $mr }) => $marginRight ?? $mr};
  margin-left: ${({ $marginLeft, $ml }) => $marginLeft ?? $ml};
  margin-bottom: ${({ $marginBottom, $mb }) => $marginBottom ?? $mb};

  padding: ${({ $padding, $p }) => $padding ?? $p};
  padding-top: ${({ $paddingTop, $pt }) => $paddingTop ?? $pt};
  padding-right: ${({ $paddingRight, $pr }) => $paddingRight ?? $pr};
  padding-left: ${({ $paddingLeft, $pl }) => $paddingLeft ?? $pl};
  padding-bottom: ${({ $paddingBottom, $pb }) => $paddingBottom ?? $pb};
`
