import { combineReducers } from "redux"

import { NewsAOState } from "./NewsAOReducerState"
import { allNewsAOReducer } from "./all"
import { archivedNewsAOReducer } from "./archived"
import { newNewsAOReducer } from "./new"
import { originalNewsAOReducer } from "./original"
import { sentNewsAOReducer } from "./sent"
import { testNewsAOReducer } from "./test"

export const newsAOReducer = combineReducers<NewsAOState>({
  archivedNewsAO: archivedNewsAOReducer,
  newNewsAO: newNewsAOReducer,
  sentNewsAO: sentNewsAOReducer,
  allNewsAO: allNewsAOReducer,
  testNewsAO: testNewsAOReducer,
  originalNewsAO: originalNewsAOReducer
})
