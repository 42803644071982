import { Collapse } from "antd"
import React from "react"
import { useSelector, useDispatch } from "react-redux"

import Flexbox from "components/Flexbox"
import Icon from "components/Icons"
import {
  selectIsFetching,
  selectNotifications,
  markAsRead,
  removeNotification
} from "store/notifications"
import { theme } from "style"
import { cleanHTML } from "utils/sanitization"
import { isTagManager } from "utils/tagManager"

import { Title } from "./Title"
import { ContentWrapper, RemoveBtnWrapper } from "./styles"

const { Panel } = Collapse

const NotificationContent = () => {
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetching)
  const notifications = useSelector(selectNotifications)

  const onExpandChange = (id?: string | string[]) => {
    if (typeof id === "string") {
      const notification = notifications.find((not) => not.id === id)
      if (!notification?.isRead) {
        dispatch(markAsRead(id))
      }
      if (isTagManager) {
        const tagManagerArgs = {
          event: "Notification list",
          dataGtm: `notifications-position-open-${id}`
        }

        window.dataLayer.push(tagManagerArgs)
      }
    }
  }

  const onDeleteNotification = (id: string) => {
    dispatch(removeNotification(id))
  }

  if (isFetching) {
    return (
      <Flexbox
        $justifyContent="center"
        $alignItems="center"
        style={{ height: "500px", width: "500px", overflow: "auto" }}
      >
        <Icon type="loading" />
      </Flexbox>
    )
  }

  return (
    <ContentWrapper>
      <Collapse
        ghost
        expandIconPosition="end"
        onChange={onExpandChange}
        accordion
      >
        {notifications.map((notification) => (
          <Panel
            key={notification.id}
            header={
              <Title
                title={notification.title}
                date={notification.createAtUtc}
                isRead={notification.isRead}
              />
            }
          >
            <div
              data-gtm="gtm-notifications-list-item-contentHtml"
              dangerouslySetInnerHTML={{
                __html: cleanHTML(notification.contentHtml)
              }}
            />
            <RemoveBtnWrapper>
              <Icon
                type="delete"
                onClick={() => onDeleteNotification(notification.id)}
                color={theme.colors.textSecondary}
                style={{ width: 15 }}
              />
            </RemoveBtnWrapper>
          </Panel>
        ))}
      </Collapse>
    </ContentWrapper>
  )
}

export default NotificationContent
