import { AutoNewsletterDeliveryType } from "types/AutoNewsletterDelivery"

export const fetchAutoNewsletterDeliveryRequest = () =>
  ({
    type: "autoNewsletterDelivery/FETCH_AUTO_NEWSLETTER_DELIVERY"
  }) as const

export const fetchAutoNewsletterDeliverySuccess = (
  data: AutoNewsletterDeliveryType
) =>
  ({
    type: "autoNewsletterDelivery/FETCH_AUTO_NEWSLETTER_DELIVERY_SUCCESS",
    data
  }) as const

export const fetchAutoNewsletterDeliveryError = (err: any) =>
  ({
    type: "autoNewsletterDelivery/FETCH_AUTO_NEWSLETTER_DELIVERY_ERROR",
    err
  }) as const

export type AutoNewsletterDeliveryActions = ReturnType<
  | typeof fetchAutoNewsletterDeliveryRequest
  | typeof fetchAutoNewsletterDeliverySuccess
  | typeof fetchAutoNewsletterDeliveryError
>
