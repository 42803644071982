import * as React from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

import Alert from "components/Alert/Alert"
import { fetchAutoNewsletterDelivery } from "store/autoNewsletterDelivery"
import { theme } from "style"
import { useDeliveryDateInformation } from "utils/newsletterDelivery/getDeliveryDetails"
import usePrefix from "utils/usePrefix"

export const AutoNewsletterDeliveryAlert = () => {
  const tNews = usePrefix("News")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAutoNewsletterDelivery())
  }, [dispatch])

  const { minutesDifference, formattedTime } = useDeliveryDateInformation()

  const isDeliveryWarningVisible =
    !!formattedTime && !!minutesDifference && minutesDifference < 120

  return (
    isDeliveryWarningVisible && (
      <Alert
        message={tNews("auto_delivery_warning", {
          time: formattedTime
        })}
        textColor={theme.colors.orange}
        type="warning"
      />
    )
  )
}