import React from "react"

import { IconProps } from "components/Icons/Icons"

const EmptyBox: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M19.2,1.59999996 L4.79999997,1.59999996 L1.59999996,12.8 L1.59999996,22.4 L22.4,22.4 L22.4,12.8 L19.2,1.59999996 Z M5.99999997,3.19999996 L18,3.19999996 L20.7,12.8 L15.2,12.8 C15.2,14.6 13.8,16 12,16 C10.2,16 8.79999996,14.6 8.79999996,12.8 L3.29999996,12.8 L5.99999997,3.19999996 Z M3.19999996,20.8 L3.19999996,14.4 L7.49999998,14.4 C8.19999996,16.3 9.89999999,17.6 12,17.6 C14.1,17.6 15.9,16.3 16.5,14.4 L20.8,14.4 L20.8,20.8 L3.19999996,20.8 L3.19999996,20.8 Z" />
  </svg>
)
export default EmptyBox
