import React from "react"

import { IconProps } from "components/Icons/Icons"

const Mail: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M-5.00000006e-08,3.19999996 L-5.00000006e-08,20.8 L23.9999999,20.8 L23.9999999,3.19999996 L-5.00000006e-08,3.19999996 Z M14.1,13.1 C12.9,14.2 12.7,14.4 12,14.4 C11.3,14.4 11.1,14.2 9.99999999,13.1 L1.59999996,4.79999997 L22.4,4.79999997 L14.1,13.1 Z M1.59999996,7.09999998 L7.29999998,12.8 L1.59999996,17.5 L1.59999996,7.09999998 Z M1.99999996,19.2 L8.49999996,13.9 L8.79999999,14.2 C9.99999996,15.3 10.6,16 12,16 C13.4,16 14.1,15.3 15.2,14.2 L15.5,13.9 L22,19.2 L1.99999996,19.2 Z M22.4,17.4 L16.7,12.7 L22.4,6.99999998 L22.4,17.4 Z" />
  </svg>
)
export default Mail
