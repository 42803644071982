import React from "react"

import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import usePrefix from "utils/usePrefix"

interface SimpleStepProps {
  content: React.ReactNode
  prevStepLabel?: string
  nextStepLabel?: string
  onPrevStep?: () => void
  onNextStep?: () => void
}

export const SimpleStep: React.FC<SimpleStepProps> = ({
  content,
  prevStepLabel,
  nextStepLabel,
  onPrevStep,
  onNextStep
}) => {
  const t = usePrefix("TestTour")

  return (
    <>
      {content}

      <Flexbox $justifyContent="flex-end">
        <Box>
          <Flexbox>
            {!!onPrevStep && (
              <Button type="text" onClick={onPrevStep}>
                {prevStepLabel || t("back")}
              </Button>
            )}
            {!!onNextStep && (
              <Button type="primary" onClick={onNextStep}>
                {nextStepLabel || t("next")}
              </Button>
            )}
          </Flexbox>
        </Box>
      </Flexbox>
    </>
  )
}
