import { format, isToday, isYesterday, parseISO } from "date-fns"

const dateFormatTemplate = "dd.MM.yyyy"
const timeFormatTemplate = "HH:mm"

/**
 * Get current datetime in a format expected by server
 * That is ISO string, without the milliseconds
 */
export const now = (): string =>
  new Date().toISOString().replace(/\.\d\d\dZ$/, "Z")

export const nowWithTime = () =>
  format(new Date(), `${dateFormatTemplate} ${timeFormatTemplate}`)

export const formatDateWithTime = (date?: string) =>
  date
    ? format(parseISO(date), `${dateFormatTemplate} ${timeFormatTemplate}`)
    : ""

export const formatDate = (date?: string) =>
  date ? format(parseISO(date), dateFormatTemplate) : ""

/**
 * @param {string} date - Date in string
 * @return {string} - Formatted date
 * @example
 * formatLiteralDate("2020-05-07T10:44:40+00:00")
 * >> "Dzisiaj, godz. 10:44"
 * >> "Wczoraj, godz. 10:44"
 * >> "07.05.2020, godz. 10:44"
 */
export const formatLiteralDate = (date?: string) => {
  if (!date) return ""
  if (isToday(parseISO(date)))
    return `Dzisiaj, godz. ${format(parseISO(date), timeFormatTemplate)}`
  if (isYesterday(parseISO(date)))
    return `Wczoraj, godz. ${format(parseISO(date), timeFormatTemplate)}`
  return `${format(parseISO(date), dateFormatTemplate)}, godz. ${format(
    parseISO(date),
    timeFormatTemplate
  )}`
}

export const getToday = () =>
  format(parseISO(new Date().toISOString()), dateFormatTemplate)

export const isSentMoreThan = (sentDate: string, days: number) => {
  const sendDate = new Date(sentDate)
  const currentDate = new Date()
  const daysDiff =
    (currentDate.getTime() - sendDate.getTime()) / (1000 * 60 * 60 * 24)

  return daysDiff > days
}