import { ButtonProps } from "antd/lib/button"
import React from "react"
import { styled } from "styled-components"

import { Button } from "./Button"

type ExtendedButtonProps = {
  $isHighlighted?: boolean
} & ButtonProps

export const GhostButton: React.FC<ExtendedButtonProps> = ({
  color,
  ...props
}: ExtendedButtonProps) => {
  return <StyledButton color={color} {...props} />
}

const StyledButton = styled(Button)<ExtendedButtonProps>`
  color: ${({ theme, color, $isHighlighted }) =>
    $isHighlighted ? theme.colors.white : color ?? theme.colors.textSecondary};
  background-color: ${({ theme, $isHighlighted, color }) =>
    $isHighlighted ? color ?? theme.colors.textSecondary : theme.colors.white};
  border-color: ${({ theme, color }) => color ?? theme.colors.textSecondary};

  &:hover {
    color: ${({ theme, $isHighlighted }) =>
      !$isHighlighted ? theme.colors.textSecondary : theme.colors.white};
    background-color: ${({ theme, $isHighlighted }) =>
      !$isHighlighted ? theme.colors.white : theme.colors.textSecondary};
    border-color: ${({ theme }) => theme.colors.textSecondary};
    opacity: 0.8;
  }
`
