import { ConfigProvider } from "antd"
import pl_PL from "antd/es/locale/pl_PL"
import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { ThemeProvider } from "styled-components"

import configureStore from "store/configureStore"
import { GlobalStyle, theme } from "style"
import initTagManager from "utils/tagManager"

import App from "./App"
import * as serviceWorker from "./serviceWorker"

import "./translations"
import "./style/theme/theme.less"

initTagManager()

export const store = configureStore()

window.onunload = function () {
  window.scrollTo(0, 0)
}

const rootElement = document.getElementById("root") as HTMLElement
if (!rootElement) {
  throw new Error("Root element with ID 'root' not found.")
}

const root = createRoot(rootElement)
root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <ConfigProvider locale={pl_PL}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </ThemeProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if ((module as any).hot) {
  // Accept hot update
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  module.hot.accept()
}