import React from "react"

import { IconButton } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import { theme, Header } from "style"
import { useCopyToClipboard } from "utils/hooks"
import usePrefix from "utils/usePrefix"

import { SectionDescription, Wrapper } from "./styles"

interface CustomerServiceSectionProps {
  layout: "horizontal" | "vertical"
}

export const CustomerServiceSection: React.FC<CustomerServiceSectionProps> = ({
  layout
}) => {
  const t = usePrefix("CustomerService")

  const copy = useCopyToClipboard()

  const style = {
    border: "none",
    boxShadow: "none",
    gap: "6px"
  }

  return (
    <Wrapper>
      <Box $flex={layout === "horizontal" ? 16 : 24}>
        <Header
          style={{
            color: theme.colors.textPrimary,
            letterSpacing: "2px",
            marginTop: 12,
            marginBottom: 8
          }}
        >
          {t("customer_service_header")}/{t("service_desk_header")}
        </Header>
        <SectionDescription style={{ color: theme.colors.textPrimary }}>
          {t("service_desk_description")}
        </SectionDescription>
      </Box>
      <Box $flex={layout === "horizontal" ? 8 : 24}>
        <Flexbox
          $justifyContent="space-between"
          $flexWrap={layout === "vertical" ? "nowrap" : "wrap"}
          $flexDirection="column-reverse"
          $alignItems={layout === "vertical" ? "flex-start" : "flex-end"}
        >
          <IconButton
            icon="mail"
            onClick={() => copy(t("email_service_desk"))}
            style={style}
          >
            {t("email_service_desk")}
          </IconButton>

          <IconButton
            icon="mail"
            style={style}
            onClick={() => copy(t("email_customer_service"))}
          >
            {t("email_customer_service")}
          </IconButton>

          <IconButton
            icon="call"
            style={style}
            onClick={() =>
              copy(`${t("contact_phone1")}, ${t("contact_phone2")}`)
            }
          >
            {t("contact_phone1")}, {t("contact_phone2")}
          </IconButton>
        </Flexbox>
      </Box>
    </Wrapper>
  )
}
