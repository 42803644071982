import React from "react"

import { IconProps } from "components/Icons/Icons"

const NoFile: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 59 55"
    fill={color}
    {...props}
  >
    <polygon points="36.7826089 22.1176472 29.2173915 29.7535015 21.652174 22.1176472 18.7826088 25.0140057 26.3478262 32.6498601 18.7826088 40.2857145 21.652174 43.182073 29.2173915 35.5462187 36.7826089 43.182073 39.6521741 40.2857145 32.0869567 32.6498601 39.6521741 25.0140057" />
    <path d="M29.2173914,4.21288517 L25.0434784,0 L-5.8388077e-13,0 L-5.8388077e-13,54.7675072 L58.4347829,54.7675072 L58.4347829,4.21288517 L29.2173914,4.21288517 Z M23.2173914,4.21288517 L27.3913045,8.42577034 L54.2608698,8.42577034 L54.2608698,12.6386555 L4.17391306,12.6386555 L4.17391306,4.21288517 L23.2173914,4.21288517 Z M4.17391306,50.5546221 L4.17391306,16.8515407 L54.2608698,16.8515407 L54.2608698,50.5546221 L4.17391306,50.5546221 Z" />
  </svg>
)
export default NoFile
