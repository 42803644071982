import { TimePicker } from "antd"
import moment from "moment"
import * as React from "react"

import Flexbox from "components/Flexbox"
import Select from "components/Inputs/Select"
import { FieldValueUpdate } from "components/Modal/AutoNewsletterDelivery/AutoNewsletterDeliveryForm"
import { SelectWrapper } from "components/Modal/AutoNewsletterDelivery/styles"
import { Text } from "style"
import {
  AutoNewsletterDeliveryType,
  options
} from "types/AutoNewsletterDelivery"
import usePrefix from "utils/usePrefix"

type BasicConfigProps = {
  cycle: AutoNewsletterDeliveryType["cycle"]
  time: AutoNewsletterDeliveryType["time"]
  onChange: FieldValueUpdate
}
export const BasicConfig: React.FC<BasicConfigProps> = ({
  cycle,
  time,
  onChange
}) => {
  const t = usePrefix("AutoDelivery")
  const format = "HH:mm"

  return (
    <Flexbox>
      <Flexbox
        $flexDirection="column"
        $width="200px"
        $gap="5px"
        $marginRight="8px"
      >
        <Text>{t("deliveryCycle")}</Text>
        <SelectWrapper>
          <Select
            showArrow
            showSearch={false}
            value={cycle}
            onChange={(value) => onChange("cycle", value)}
            options={options.cycles.map((cycle) => ({
              value: cycle,
              label: t(`${cycle}`)
            }))}
          />
        </SelectWrapper>
      </Flexbox>
      <Flexbox $flexDirection="column" $width="130px" $gap="5px">
        <Text> {t("deliveryHour")}</Text>
        <SelectWrapper>
          <TimePicker
            format={format}
            value={moment(time, format)}
            onChange={(_value, dateString) => {
              const formattedDate = moment(dateString, format).format(
                "HH:mm:ss"
              )
              onChange("time", formattedDate)
            }}
            allowClear={false}
          />
        </SelectWrapper>
      </Flexbox>
    </Flexbox>
  )
}