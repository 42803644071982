import { createSelector } from "reselect"

import { AppState } from "store/AppState"

export const selectToPublishPending = (s: AppState) => s.news.toPublish.pending

export const selectToPublishFetchError = (s: AppState) => s.news.toPublish.error

export const selectToPublishPagination = (s: AppState) =>
  s.news.toPublish.pagination

export const selectToPublishList = (s: AppState) => s.news.toPublish.data || []

export const selectToPublishListWithImage = createSelector(
  selectToPublishList,
  (toPublishList) => toPublishList.filter((el) => !!el.imageId)
)

export const selectToPublishNewsById = (s: AppState, id: string) =>
  s.news.toPublish.data?.find((news) => news.id === id)
