import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { NewsletterPreviewHtmlRequest } from "types/Newsletter"
import { PromiseAction } from "types/RemoteAction"
import { NewsPreview } from "types/Template"

export const fetchTemplatePreviewHtmlAction =
  (templatePreview: NewsletterPreviewHtmlRequest) =>
  (dispatch: Dispatch): PromiseAction<ResultModel<string>> =>
    dispatch({
      type: "templatePreviewHtml/FETCH",
      promise: Api.getTemplatePreviewHTML(templatePreview)
    })

export const fetchNewsPreviewHtmlAction =
  (payload: NewsPreview) =>
  (dispatch: Dispatch): PromiseAction<ResultModel<string>> =>
    dispatch({
      type: "templatePreviewHtml/FETCH",
      promise: Api.getNewsPreviewHTML(payload)
    })
