import React from "react"

import { IconProps } from "components/Icons/Icons"

const BookPage: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M2.39999996,1.59999996 L2.39999996,22.4 L21.6,22.4 L21.6,1.59999996 L2.39999996,1.59999996 Z M5.59999997,20.8 L3.99999997,20.8 L3.99999997,3.19999996 L5.59999997,3.19999996 L5.59999997,20.8 Z M20,20.8 L7.19999998,20.8 L7.19999998,3.19999996 L20,3.19999996 L20,20.8 Z M18.4,6.39999998 L8.79999999,6.39999998 L8.79999999,7.99999998 L18.4,7.99999998 L18.4,6.39999998 Z M18.4,9.59999999 L8.79999999,9.59999999 L8.79999999,11.2 L18.4,11.2 L18.4,9.59999999 Z" />
  </svg>
)

export default BookPage
