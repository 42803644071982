import React from "react"

import { IconProps } from "components/Icons/Icons"

const Warning: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={color}
    {...props}
  >
    <g
      id="Redaktor"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="RedaktorWK/Dash-Redaktor"
        transform="translate(-334.000000, -156.000000)"
      >
        <g id="Group-12" transform="translate(334.000000, 156.000000)">
          <circle id="Oval" fill="#FFFFFF" cx="8" cy="8" r="7" />
          <path
            d="M13.6593978,2.34064956 C10.5385584,-0.780216522 5.46146896,-0.780216522 2.34062955,2.34064956 C-0.780209852,5.46082782 -0.780209852,10.5393051 2.34062955,13.6595146 C3.90072098,15.2202759 5.95035951,16 8,16 C10.0496366,16 12.0993064,15.2202759 13.6593665,13.6595146 C16.7802059,10.5393051 16.7802059,5.46082782 13.6593978,2.34064956 Z M7.99999805,13.336013 C7.6318216,13.336013 7.33302215,13.037211 7.33302215,12.6690314 C7.33302215,12.3008518 7.6318216,12.0020498 7.99999805,12.0020498 C8.36817449,12.0020498 8.66697394,12.3008518 8.66697394,12.6690314 C8.66697394,13.037211 8.36817449,13.336013 7.99999805,13.336013 Z M8.66700521,10.0010425 C8.66700521,10.3692221 8.36886232,10.6680241 8.00002931,10.6680241 C7.6311963,10.6680241 7.33305341,10.3692221 7.33305341,10.0010425 L7.33305341,3.33113272 C7.33305341,2.96295312 7.6311963,2.66415112 8.00002931,2.66415112 C8.36886232,2.66415112 8.66700521,2.96295312 8.66700521,3.33113272 L8.66700521,10.0010425 Z"
            id="Shape"
            fill="#E5202E"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default Warning
