import { Avatar, Tooltip } from "antd"
import React from "react"

import Icon from "components/Icons"
import { theme } from "style"
import { getInitials } from "utils/strings"

import { AvatarWrapper, IconWrapper } from "./styles"

interface AuthorAvatarProps {
  fullName?: string
  tooltip?: string
  size?: number | "small" | "large" | "default"
  style?: React.CSSProperties
  withBurger?: boolean
  onClick?: () => void
}

export const AuthorAvatar: React.FC<AuthorAvatarProps> = ({
  fullName,
  tooltip,
  size = "small",
  style,
  withBurger,
  onClick
}) => {
  if (!fullName?.trim()) return null

  return (
    <Tooltip title={tooltip || fullName} arrowPointAtCenter>
      <AvatarWrapper onClick={onClick}>
        <Avatar
          style={{
            backgroundColor: theme.colors.blueActive,
            verticalAlign: "middle",
            boxSizing: "content-box",
            ...style
          }}
          size={size}
        >
          {getInitials(fullName)}
        </Avatar>
        {withBurger && (
          <IconWrapper>
            <Icon
              type="menu"
              width="15px"
              height="15px"
              color={theme.colors.textPlaceholder}
            />
          </IconWrapper>
        )}
      </AvatarWrapper>
    </Tooltip>
  )
}
