import React from "react"

import { IconProps } from "components/Icons/Icons"

const Excel: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M-5.00000006e-08,21.4999999 L13.6,23.9999999 L13.6,-5.00000006e-08 L-5.00000006e-08,2.49999995 L-5.00000006e-08,21.4999999 Z M1.59999996,3.79999997 L12,1.89999996 L12,22.1 L1.59999996,20.2 L1.59999996,3.79999997 Z M24,3.19999996 L24,20.8 L15.2,20.8 L15.2,19.2 L22.6,19.2 L22.6,4.79999997 L15.2,4.79999997 L15.2,3.19999996 L24,3.19999996 Z M20.8,17.6 L15.2,17.6 L15.2,16 L20.8,16 L20.8,17.6 Z M20.8,14.4 L15.2,14.4 L15.2,12.8 L20.8,12.8 L20.8,14.4 Z M20.8,11.2 L15.2,11.2 L15.2,9.59999999 L20.8,9.59999999 L20.8,11.2 Z M20.8,7.99999998 L15.2,7.99999998 L15.2,6.39999998 L20.8,6.39999998 L20.8,7.99999998 Z M10.8,7.19999998 L7.79999996,12 L10.8,16.8 L8.79999996,16.8 L6.79999996,13.6 L4.79999996,16.8 L2.79999996,16.8 L5.79999996,12 L2.79999996,7.19999998 L4.79999996,7.19999998 L6.79999996,10.4 L8.79999996,7.19999998 L10.8,7.19999998 Z" />
  </svg>
)
export default Excel
