import { Checkbox } from "antd"
import React, { useState } from "react"

import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import { TagList } from "components/Tag"
import { H4, H5, theme } from "style"
import { ClientGUS } from "types/Client"
import usePrefix from "utils/usePrefix"

import { ListWrapper, RowWrapper, StyledModal } from "./styles"

export interface MultipleClientNIPModalProps {
  isVisible: boolean
  clients: ClientGUS[]
  onClose: () => void
  onFillData: (client: ClientGUS) => void
}

export const MultipleClientNIPModal: React.FC<MultipleClientNIPModalProps> = ({
  isVisible,
  clients,
  onClose,
  onFillData
}) => {
  const t = usePrefix("MultipleNIP")
  const [selectedClient, onSelectClient] = useState<number | null>(null)

  const onFillGusData = () => {
    if (selectedClient !== null) {
      onFillData(clients[selectedClient])
    }
  }

  return (
    <StyledModal
      centered
      open={isVisible}
      footer={false}
      width="null"
      onCancel={onClose}
    >
      <Box $pb="24px">
        <h2>{t("modalTitle")}</h2>
      </Box>
      <ListWrapper>
        {clients.map((client, index) => (
          <ClientRow
            key={index}
            index={index}
            onSelectClient={onSelectClient}
            selectedClient={selectedClient}
            {...client}
          />
        ))}
      </ListWrapper>
      <Flexbox $justifyContent="end">
        <Button
          type="primary"
          size="large"
          onClick={onFillGusData}
          disabled={selectedClient === null}
        >
          {t("fillData")}
        </Button>
      </Flexbox>
    </StyledModal>
  )
}

interface ClientRowProps {
  index: number
  selectedClient: number | null
  onSelectClient: (index: number) => void
}

const ClientRow: React.FC<ClientGUS & ClientRowProps> = ({
  index,
  companyName,
  fullName,
  nip,
  tags,
  customTags,
  activityFormCode,
  pkdCode,
  onSelectClient,
  selectedClient
}) => {
  return (
    <RowWrapper>
      <Flexbox $alignItems="center">
        <Box>
          <Checkbox
            checked={selectedClient === index}
            onChange={() => onSelectClient(index)}
          />
        </Box>
        <Flexbox $pl="16px" $gap="8px" $width="100%">
          <Box $flex={1}>
            <H4>{companyName}</H4>
            <H5 color={theme.colors.textSecondary}>{nip}</H5>
          </Box>
          <Box $flex={1} style={{ whiteSpace: "normal" }}>
            {fullName}
          </Box>
          <Box $flex={1} style={{ whiteSpace: "normal" }}>
            <TagList
              tags={tags}
              customTags={customTags}
              activityFormCode={activityFormCode}
              pkdCode={pkdCode}
            />
          </Box>
        </Flexbox>
      </Flexbox>
    </RowWrapper>
  )
}
