import { applyMiddleware, createStore, Middleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"
import thunkMiddleware from "redux-thunk"

import { isPromiseAction } from "types/RemoteAction"

import { AppState } from "./AppState"
import createRootReducer from "./rootReducer"

const composeEnhancers = composeWithDevTools({})

const promiseMiddleware: Middleware<any, AppState> =
  () => (next) => (action) => {
    if (isPromiseAction(action)) {
      const { promise, ...rest } = action

      next({ ...rest, resolved: false })
      return promise
        .then((res) => res.data || res)
        .then((res) => {
          if (res.errors?.length > 0) {
            return Promise.reject(res.errors)
          }
          next({
            ...rest,
            resolved: "SUCCESS",
            payload: res?.result || res,
            pagination: res?.pagination
          })
          return Promise.resolve()
        })
        .catch((err) => {
          next({ ...rest, resolved: "FAILURE", payload: err?.errors || err })
          return Promise.reject()
        })
    }

    return next(action)
  }

const configureStore = () => {
  return createStore(
    createRootReducer(),

    composeEnhancers(applyMiddleware(thunkMiddleware, promiseMiddleware))
  )
}

export default configureStore
