import React from "react"

import { IconProps } from "components/Icons/Icons"

const CheckCircle: React.FC<IconProps> = ({
  color,
  width,
  height,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M11.9999999,-5.00000006e-08 C5.39999997,-5.00000006e-08 -5.00000006e-08,5.39999997 -5.00000006e-08,11.9999999 C-5.00000006e-08,18.6 5.39999997,23.9999999 11.9999999,23.9999999 C18.6,23.9999999 23.9999999,18.6 23.9999999,11.9999999 C23.9999999,5.39999997 18.6,-5.00000006e-08 11.9999999,-5.00000006e-08 Z M12,22.4 C6.19999998,22.4 1.59999996,17.8 1.59999996,12 C1.59999996,6.19999998 6.19999998,1.59999996 12,1.59999996 C17.8,1.59999996 22.4,6.19999998 22.4,12 C22.4,17.8 17.8,22.4 12,22.4 Z M9.59999999,15.3 L5.89999997,11.2 L4.79999997,12.3 L9.59999999,17.6 L19.2,7.49999998 L18.1,6.39999998 L9.59999999,15.3 Z" />
  </svg>
)
export default CheckCircle
