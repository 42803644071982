import React from "react"

import { IconProps } from "components/Icons/Icons"

const DownOutlined: React.FC<IconProps> = ({
  color,
  width,
  height,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <polygon points="21.6 8.49999999 12 18.6 2.39999996 8.59999999 3.49999996 7.39999998 12 16.3 20.5 7.39999998" />
  </svg>
)
export default DownOutlined
