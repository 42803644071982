import React from "react"

import { IconProps } from "components/Icons/Icons"

const UpOutlined: React.FC<IconProps> = ({
  color,
  width,
  height,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <polygon points="21.6 15.5 12 5.39999997 2.39999996 15.5 3.49999996 16.6 12 7.69999998 20.5 16.6" />
  </svg>
)
export default UpOutlined
