import React from "react"

import { IconProps } from "components/Icons/Icons"

const History: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M12,0.799999953 C8.39999999,0.799999953 5.29999995,2.49999996 3.19999996,5.09999997 L3.19999996,2.39999996 L1.59999996,2.39999996 L1.59999996,8.79999995 L7.99999998,8.79999995 L7.99999998,7.19999998 L3.69999997,7.19999998 C5.39999997,4.29999995 8.49999999,2.39999996 12,2.39999996 C17.3,2.39999996 21.6,6.69999998 21.6,12 C21.6,17.3 17.3,21.6 12,21.6 C6.69999998,21.6 2.39999996,17.3 2.39999996,12 L0.799999953,12 C0.799999953,18.2 5.79999995,23.2 12,23.2 C18.2,23.2 23.2,18.2 23.2,12 C23.2,5.79999995 18.2,0.799999953 12,0.799999953 Z M12.8,3.99999997 L11.2,3.99999997 L11.2,11.2 L5.59999997,11.2 L5.59999997,12.8 L12.8,12.8 L12.8,3.99999997 Z" />
  </svg>
)

export default History
