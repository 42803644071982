import * as React from "react"

import Flexbox, { Box } from "components/Flexbox"
import Select from "components/Inputs/Select"
import { FieldValueUpdate } from "components/Modal/AutoNewsletterDelivery/AutoNewsletterDeliveryForm"
import { SelectWrapper } from "components/Modal/AutoNewsletterDelivery/styles"
import { theme } from "style"
import {
  AutoNewsletterDeliveryType,
  options
} from "types/AutoNewsletterDelivery"
import usePrefix from "utils/usePrefix"

type WeeklyProps = {
  dayOfWeek: AutoNewsletterDeliveryType["dayOfWeek"]
  onChange: FieldValueUpdate
}

export const WeeklyConfig: React.FC<WeeklyProps> = ({
  dayOfWeek,
  onChange
}) => {
  const t = usePrefix("AutoDelivery")

  return (
    <Flexbox $padding="16px">
      <Box
        color={theme.colors.textSecondary}
        style={{ lineHeight: "24px", fontSize: "14px" }}
      >
        <Box $display="inline">{t("inEvery")}</Box>
        <SelectWrapper
          style={{
            width: "140px",
            display: "inline-block",
            margin: "0 6px"
          }}
        >
          <Select
            showArrow
            showSearch={false}
            onChange={(value) => onChange("dayOfWeek", value)}
            value={dayOfWeek}
            options={options.daysOfWeek.map((day) => ({
              value: day,
              label: t(`${day}`)
            }))}
          />
        </SelectWrapper>
      </Box>
    </Flexbox>
  )
}