import { request, serialize } from "api/config"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import {
  NewsletterGroupResponseDto,
  NewsletterGroupWithSendDateResponseDto,
  NewsletterGroupRefreshOpenings
} from "types/Newsletter"
import { showErrorNotification } from "utils/errors"

const PATH_NAME = "NewsletterGroup"

export const fetchNewsletterGroups = async (
  pagination: PaginationRequest,
  clientId?: string
): Promise<ResultModel<NewsletterGroupResponseDto[]>> =>
  request
    .get(
      clientId
        ? `${PATH_NAME}/${clientId}${serialize(pagination)}`
        : `${PATH_NAME}${serialize(pagination)}`
    )
    .catch((err) => {
      showErrorNotification(
        err,
        "Wystąpił błąd podczas pobierania danych o wysłanych biuletynach. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
      )
    })

export const fetchLastSentNewsletter = async (): Promise<
  ResultModel<NewsletterGroupWithSendDateResponseDto>
> => request.get(`${PATH_NAME}/RetrieveLastSent`)

export const refreshOpeningsCount = async (
  groupId: string,
  signal?: AbortSignal
): Promise<ResultModel<NewsletterGroupRefreshOpenings>> =>
  request.patch(`${PATH_NAME}/RefreshNewslettersOpeningCount/${groupId}`, {
    signal
  })
