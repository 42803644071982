import { UserNotifications } from "types/Notification"

export const fetchNotificationsRequest = () =>
  ({
    type: "notifications/FETCH_NOTIFICATIONS"
  } as const)

export const fetchNotificationsSuccess = (data: UserNotifications) =>
  ({
    type: "notifications/FETCH_NOTIFICATIONS_SUCCESS",
    data
  } as const)

export const fetchNotificationsError = (err: any) =>
  ({
    type: "notifications/FETCH_NOTIFICATIONS_ERROR",
    err
  } as const)

export const markAllAsReadRequest = () =>
  ({
    type: "notifications/MARK_ALL_AS_READ"
  } as const)

export const markAllAsReadSuccess = () =>
  ({
    type: "notifications/MARK_ALL_AS_READ_SUCCESS"
  } as const)

export const markAllAsReadError = (err: any) =>
  ({
    type: "notifications/MARK_ALL_AS_READ_ERROR",
    err
  } as const)

export const markAsReadRequest = () =>
  ({
    type: "notifications/MARK_AS_READ"
  } as const)

export const markAsReadSuccess = (id: string) =>
  ({
    type: "notifications/MARK_AS_READ_SUCCESS",
    id
  } as const)

export const markAsReadError = (err: any) =>
  ({
    type: "notifications/MARK_AS_READ_ERROR",
    err
  } as const)

export const removeNotificationRequest = () =>
  ({
    type: "notifications/REMOVE_NOTIFICATION"
  } as const)

export const removeNotificationSuccess = (id: string) =>
  ({
    type: "notifications/REMOVE_NOTIFICATION_SUCCESS",
    id
  } as const)

export const removeNotificationError = (err: any) =>
  ({
    type: "notifications/REMOVE_NOTIFICATION_ERROR",
    err
  } as const)

export type NotificationAction = ReturnType<
  | typeof fetchNotificationsRequest
  | typeof fetchNotificationsSuccess
  | typeof fetchNotificationsError
  | typeof markAllAsReadRequest
  | typeof markAllAsReadSuccess
  | typeof markAllAsReadError
  | typeof markAsReadRequest
  | typeof markAsReadSuccess
  | typeof markAsReadError
  | typeof removeNotificationRequest
  | typeof removeNotificationSuccess
  | typeof removeNotificationError
>
