import React from "react"

import { IconProps } from "components/Icons/Icons"

const Close: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <polygon points="20.8 4.29999997 19.7 3.19999996 12 10.9 4.29999997 3.19999996 3.19999996 4.29999997 10.9 12 3.19999996 19.7 4.29999997 20.8 12 13.1 19.7 20.8 20.8 19.7 13.1 12" />
  </svg>
)
export default Close
