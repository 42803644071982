import React from "react"

import { IconProps } from "components/Icons/Icons"

const Files: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path
      d="M-5.00000006e-08,4.79999997 L-5.00000006e-08,24.0000001 L14.4,24.0000001 L14.4,11.2 L7.99999995,4.79999997 L-5.00000006e-08,4.79999997 Z M12.8,22.4 L1.59999996,22.4 L1.59999996,6.39999998 L6.39999998,6.39999998 L6.39999998,12.8 L12.8,12.8 L12.8,22.4 Z M7.99999998,11.2 L7.99999998,7.09999998 L12.1,11.2 L7.99999998,11.2 Z M17.6,-5.00000006e-08 L9.59999999,-5.00000006e-08 L9.59999999,4.09999997 L11.2,5.69999997 L11.2,1.59999996 L16,1.59999996 L16,7.99999995 L22.4,7.99999995 L22.4,17.6 L16,17.6 L16,19.2 L24.0000001,19.2 L24.0000001,6.39999998 L17.6,-5.00000006e-08 Z M17.6,6.39999998 L17.6,2.29999996 L21.7,6.39999998 L17.6,6.39999998 L17.6,6.39999998 Z"
      fill={color}
    />
  </svg>
)
export default Files
