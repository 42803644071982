import React from "react"

import { IconProps } from "components/Icons/Icons"

const GoTo: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M9.29999999,18.1 L10.4,19.2 L17.6,12 L10.4,4.79999997 L9.29999999,5.89999997 L14.6,11.2 L3.19999996,11.2 L3.19999996,12.8 L14.6,12.8 L9.29999999,18.1 Z M6.39999998,1.59999996 L6.39999998,3.19999996 L19.2,3.19999996 L19.2,20.8 L6.39999998,20.8 L6.39999998,22.4 L20.8,22.4 L20.8,1.59999996 L6.39999998,1.59999996 Z" />
  </svg>
)
export default GoTo
