import { styled } from "styled-components"

interface ImgThumbnailProps {
  size?: "small" | "normal" | "large"
}

const imgWidth = {
  small: "25px",
  normal: "40px",
  large: "120px"
}

const imgHeight = {
  small: "25px",
  normal: "40px",
  large: "70px"
}

const imgMargin = {
  small: "8px",
  normal: "16px",
  large: "16px"
}

export const ImgThumbnail = styled.img<ImgThumbnailProps>`
  width: ${({ size = "normal" }) => imgWidth[size]};
  height: ${({ size = "normal" }) => imgHeight[size]};
  object-fit: cover;
  border-radius: 5px;
  margin-right: ${({ size = "normal" }) => imgMargin[size]};
`
