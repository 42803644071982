import React from "react"

import { IconProps } from "components/Icons/Icons"

const Tag: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M11.8,1.59999996 L1.59999996,1.59999996 L1.59999996,11.8 L12.2,22.4 L22.4,12.2 L11.8,1.59999996 Z M3.19999996,11.1 L3.19999996,3.19999996 L11.1,3.19999996 L20.1,12.2 L12.2,20.1 L3.19999996,11.1 Z M7.99999998,4.79999997 C6.19999998,4.79999997 4.79999997,6.19999998 4.79999997,7.99999998 C4.79999997,9.79999997 6.19999998,11.2 7.99999998,11.2 C9.79999997,11.2 11.2,9.79999997 11.2,7.99999998 C11.2,6.19999998 9.79999997,4.79999997 7.99999998,4.79999997 Z M7.99999998,9.59999999 C7.09999998,9.59999999 6.39999998,8.89999998 6.39999998,7.99999998 C6.39999998,7.09999998 7.09999998,6.39999998 7.99999998,6.39999998 C8.89999998,6.39999998 9.59999999,7.09999998 9.59999999,7.99999998 C9.59999999,8.89999998 8.89999998,9.59999999 7.99999998,9.59999999 Z" />
  </svg>
)
export default Tag
