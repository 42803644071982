import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { PromiseAction } from "types/RemoteAction"
import { PKD } from "types/Template"

export const fetchPKD =
  () =>
  (dispatch: Dispatch): PromiseAction<ResultModel<PKD[]>> =>
    dispatch({
      type: "pkd/FETCH",
      promise: Api.getPKD()
    })
