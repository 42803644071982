import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { News } from "types/News"
import { PromiseAction } from "types/RemoteAction"

export const fetchWorkingNews =
  () =>
  (dispatch: Dispatch): PromiseAction<ResultModel<News[]>> =>
    dispatch({
      type: "workingNews/FETCH",
      promise: Api.fetchNews("Draft", { pageIndex: 1, pageSize: 50 })
    })

export const saveNews = (news: News) => {
  if (news.id) {
    return Api.updateNews(news)
  }
  return Api.addNews(news)
}
