import React from "react"

import Flexbox, { Box } from "components/Flexbox"
import Icon from "components/Icons"
import { theme, Text } from "style"
import { formatLiteralDate } from "utils/date"

import { TitleWrapper } from "./styles"

interface TitleProps {
  title: string
  date: string
  isRead: boolean
}

export const Title: React.FC<TitleProps> = ({ title, date, isRead }) => {
  return (
    <Flexbox style={{ flexWrap: "nowrap" }} $width="100%">
      <Box $margin="auto 0">
        <Icon
          type="infoRound"
          color={theme.colors.white}
          style={{
            background: theme.colors.blueActive,
            borderRadius: "50%",
            padding: 3,
            marginRight: 12
          }}
        />
      </Box>
      <Box>
        <TitleWrapper $isRead={isRead}>{title}</TitleWrapper>
        <Text>{formatLiteralDate(date)}</Text>
      </Box>
    </Flexbox>
  )
}
