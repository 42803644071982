import { Tooltip } from "antd"
import CheckableTag from "antd/lib/tag/CheckableTag"
import React from "react"

import Icon from "components/Icons"
import { theme } from "style"
import { useRenderTooltip } from "utils/useRenderTooltip"

import { CloseIconWrapper, TagWrapper } from "./styles"

interface TagProps {
  checked: boolean
  children: React.ReactNode
  closable?: boolean
  tooltip?: string
  isIndeterminate?: boolean
  formType: "client" | "news"
  onChange?: (isChecked: boolean, isIndeterminate: boolean) => void
  onClose?: () => void
  tip?: string
}

export const Tag = ({
  checked,
  children,
  closable,
  tooltip,
  isIndeterminate = false,
  tip,
  formType,
  onChange,
  onClose
}: TagProps) => {
  const handleChangeState = (isChecked: boolean) => {
    if (formType === "news") {
      onChange?.(!isIndeterminate && isChecked, !isChecked)
    } else {
      onChange?.(isChecked, false)
    }
  }

  const titleTooltipPrefix = checked
    ? "Wymagany - "
    : isIndeterminate
      ? "Opcjonalny - "
      : ""

  const finalTooltip = useRenderTooltip([
    `${titleTooltipPrefix}${tooltip || children}`,
    tip ? tip : ""
  ])

  return (
    <Tooltip className="tag-wrapper" title={finalTooltip}>
      <TagWrapper $closable={closable} $isIndeterminate={isIndeterminate}>
        <CheckableTag checked={checked} onChange={handleChangeState}>
          {children}
        </CheckableTag>
        {closable && (
          <CloseIconWrapper onClick={onClose}>
            <Icon
              type="close"
              width="14px"
              height="14px"
              color={theme.colors.textAdditional}
            />
          </CloseIconWrapper>
        )}
      </TagWrapper>
    </Tooltip>
  )
}