import { useCallback } from "react"
import { useTranslation } from "react-i18next"

export type TransFunc = (
  postfix: string,
  variables?: Record<string, unknown>
) => string

const usePrefix = (prefix: string): TransFunc => {
  const { t } = useTranslation()

  return useCallback(
    (postfix: string, variables?: Record<string, unknown>) =>
      !variables
        ? t(`${prefix}.${postfix}`)
        : t(`${prefix}.${postfix}`, variables),
    [prefix, t]
  )
}

export default usePrefix

export const polishPluralForm = (
  singularNominativ: string,
  pluralNominativ: string,
  pluralGenitive: string,
  value: number
) => {
  if (value === 1) {
    return singularNominativ
  } else if (
    value % 10 >= 2 &&
    value % 10 <= 4 &&
    (value % 100 < 10 || value % 100 >= 20)
  ) {
    return pluralNominativ
  } else {
    return pluralGenitive
  }
}
