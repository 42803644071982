import React from "react"

import { IconProps } from "components/Icons/Icons"

const Call: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M16.7,13.2 L14.3,15.5 L8.39999999,9.69999999 L10.7,7.29999995 L5.39999997,0.799999953 L1.89999996,4.29999995 C1.09999995,5.19999997 0.799999953,6.29999995 0.799999953,7.19999998 C0.799999953,14.4 9.49999999,23.2 16.8,23.2 C17.8,23.2 18.8,22.9 19.7,22.1 L23.2,18.6 L16.7,13.2 Z M18.6,21 C18.1,21.4 17.6,21.6 16.8,21.6 C10.5,21.6 2.39999996,13.5 2.39999996,7.19999996 C2.39999996,6.49999998 2.59999996,5.89999997 3.09999996,5.39999997 L5.29999997,3.19999996 L8.59999999,7.29999998 L6.19999998,9.69999996 L14.3,17.8 L16.8,15.4 L20.9,18.7 L18.6,21 Z" />
  </svg>
)
export default Call
