import React from "react"

import Icon from "components/Icons"

import { LoadingFullScreenWrapper } from "./styles"

interface LoadingProps {
  fullHeight?: boolean
}

export const Loading = ({ fullHeight }: LoadingProps) => {
  return (
    <LoadingFullScreenWrapper $fullHeight={fullHeight}>
      <Icon type="loading" />
    </LoadingFullScreenWrapper>
  )
}
