import React from "react"

import { IconProps } from "components/Icons/Icons"

const FireIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M8.79999999,17.6 C8.79999999,19.4 10.2,20.8 12,20.8 C13.8,20.8 15.2,19.4 15.2,17.6 C15.2,16.2 13.7,12.8 12,11.2 C10.3,12.8 8.79999999,16.2 8.79999999,17.6 Z M12,19.2 C11.1,19.2 10.4,18.5 10.4,17.6 C10.4,16.9 11.1,15.2 12,13.7 C12.9,15.1 13.6,16.9 13.6,17.6 C13.6,18.5 12.9,19.2 12,19.2 Z M11.2,-5.00000006e-08 C10.2,5.89999997 3.19999996,8.49999995 3.19999996,15.2 C3.19999996,20.1 7.09999998,23.9999999 12,23.9999999 C16.9,23.9999999 20.8,20.1 20.8,15.2 C20.8,9.69999999 15,2.69999996 11.2,-5.00000006e-08 Z M12,22.4 C7.99999998,22.4 4.79999997,19.2 4.79999997,15.2 C4.79999997,10.1 9.59999999,7.89999998 12,2.79999996 C15.4,5.99999997 19.2,11.3 19.2,15.2 C19.2,19.2 16,22.4 12,22.4 Z"
    />
  </svg>
)
export default FireIcon
