import { Modal } from "antd"
import { FormikProps } from "formik"
import React, { useCallback, useState } from "react"

import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import { BasicConfig } from "components/Modal/AutoNewsletterDelivery/Sections/BasicConfig"
import { IgnoreNewsConfig } from "components/Modal/AutoNewsletterDelivery/Sections/IgnoreNewsConfig"
import { MonthlyConfig } from "components/Modal/AutoNewsletterDelivery/Sections/MonthlyConfig"
import { WeeklyConfig } from "components/Modal/AutoNewsletterDelivery/Sections/WeeklyConfig"
import { H2, Text, theme } from "style"
import { AutoNewsletterDeliveryValues } from "types/AutoNewsletterDelivery"
import { showErrorNotification } from "utils/errors"
import usePrefix from "utils/usePrefix"

export type FieldValueUpdate = (
  field: keyof AutoNewsletterDeliveryValues,
  value: unknown
) => void

interface AutoNewsletterDeliveryModalProps {
  onClose: () => void
  isOpen: boolean
  formikProps: FormikProps<AutoNewsletterDeliveryValues>
}

export const AutoNewsletterDeliveryModal = ({
  isOpen,
  onClose,
  formikProps
}: AutoNewsletterDeliveryModalProps) => {
  const t = usePrefix("AutoDelivery")
  const tGeneral = usePrefix("General")

  const [isLoading, setLoading] = useState(false)
  const { values, setFieldValue, submitForm } = formikProps
  const { dayOfWeek, time, cycle } = values

  const handleSave = useCallback(async () => {
    try {
      setLoading(true)
      await submitForm()
    } catch (err) {
      showErrorNotification(err)
    } finally {
      setLoading(false)
      onClose()
    }
  }, [onClose, submitForm])

  const handleChange: FieldValueUpdate = useCallback(
    (field, value) => setFieldValue(field, value),
    [setFieldValue]
  )

  return (
    <Modal
      centered
      open={isOpen}
      footer={false}
      width="750px"
      bodyStyle={{
        display: "flex",
        flexDirection: "column"
      }}
      title={
        <Box>
          <H2
            style={{
              padding: "8px 0",
              color: `${theme.colors.textPrimary}`
            }}
          >
            {t("modalTitle")}
          </H2>
          <Box
            $padding="16px"
            $marginTop="24px"
            $border={`1px solid ${theme.colors.orange}`}
            $borderRadius="6px"
          >
            <Text $color="textSecondary">{t("autoDeliveryWarning")}</Text>
          </Box>
        </Box>
      }
      onCancel={onClose}
      closable
    >
      <BasicConfig cycle={cycle} time={time} onChange={handleChange} />

      <Text style={{ paddingTop: "24px" }}>{t("delivery")}</Text>
      <Box
        style={{
          height: `${cycle === "Monthly" ? "130px" : "70px"}`,
          transition: "height 0.3s ease"
        }}
      >
        {cycle === "Monthly" ? (
          <MonthlyConfig values={values} onChange={handleChange} />
        ) : (
          <WeeklyConfig dayOfWeek={dayOfWeek} onChange={handleChange} />
        )}
      </Box>

      <IgnoreNewsConfig values={values} onChange={handleChange} />

      <Flexbox $justifyContent="space-between" style={{ marginTop: 32 }}>
        <Button onClick={onClose} size="large">
          {tGeneral("cancel")}
        </Button>

        <Button
          onClick={handleSave}
          type="primary"
          size="large"
          loading={isLoading}
        >
          {tGeneral("save")}
        </Button>
      </Flexbox>
    </Modal>
  )
}