import { request } from "api/config"
import { ResultModel } from "types/ApiResponse"
import { CustomTag } from "types/Template"

const PATH_NAME = "CustomTag"

export const getCustomTags = async (): Promise<ResultModel<CustomTag[]>> =>
  request.get(PATH_NAME)

export const addCustomTag = async (
  name: string
): Promise<ResultModel<CustomTag>> => request.post(PATH_NAME, { name })

export const editCustomTag = async (
  id: string,
  name: string
): Promise<ResultModel<CustomTag>> =>
  request.put(`${PATH_NAME}/${id}`, { name })

export const deleteCustomTag = async (
  id: string
): Promise<ResultModel<CustomTag>> => request.delete(`${PATH_NAME}/${id}`)
