import { styled } from "styled-components"

import Flexbox from "components/Flexbox"

export const SelectWrapper = styled(Flexbox)`
  background: transparent;

  .ant-select,
  .ant-picker {
    height: 36px;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.borderGrey};
    border-radius: 4px;
  }

  && .ant-select-selector {
    height: 34px;
    border-radius: 4px;
  }

  && .ant-select-selection-item {
    line-height: 36px;
  }

  input {
    height: 36px;
  }
`

export const RadioWrapper = styled.div`
  background: transparent;

  & .ant-radio-checked + span {
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  & .ant-radio-inner {
    display: block;
  }

  & .ant-radio-group {
    display: flex;
    padding: 16px;
    gap: 22px;
    flex-direction: column;

    & .ant-radio-wrapper {
      border: none;
      background: transparent;
    }
  }
`
