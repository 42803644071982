import { Alert as AntAlert } from "antd"
import { AlertProps } from "antd/lib/alert"
import * as React from "react"
import { styled } from "styled-components"

interface Props {
  type: AlertProps["type"]
  message: AlertProps["message"]
  banner?: AlertProps["banner"]
  textColor?: string
  bgColor?: string
}

const Alert = ({ message, type, banner = true, textColor, bgColor }: Props) => (
  <StyledAlertWrapper $color={textColor} $bgColor={bgColor}>
    <AntAlert
      type={type}
      message={message}
      banner={banner}
      closable
      showIcon={false}
    />
  </StyledAlertWrapper>
)

const StyledAlertWrapper = styled.div<{ $color?: string; $bgColor?: string }>`
  width: 100%;

  .ant-alert {
    height: 48px;
    text-align: center;
    font-weight: 700;
  }

  .ant-alert-warning {
    background: ${(props) => props.$bgColor};
  }

  .ant-alert-message {
    color: ${(props) => props.$color};
  }
`

export default Alert