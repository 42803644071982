import React from "react"

import { IconProps } from "components/Icons/Icons"

const LeftArrow: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <polygon points="23.2 11.2 3.89999997 11.2 10.8 4.29999997 9.59999999 3.19999996 0.799999953 12 9.59999999 20.8 10.7 19.7 3.79999997 12.8 23.1 12.8 23.1 11.2" />
  </svg>
)
export default LeftArrow
