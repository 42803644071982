import { PaginationResponse } from "types/ApiResponse"
import { AccountLicense } from "types/Technical"

export const fetchExpiredAccountsRequest = () =>
  ({
    type: "license/FETCH_EXPIRED_ACCOUNTS"
  } as const)

export const fetchExpiredAccountsSuccess = (
  data: Required<AccountLicense>[],
  pagination?: PaginationResponse
) =>
  ({
    type: "license/FETCH_EXPIRED_ACCOUNTS_SUCCESS",
    data,
    pagination
  } as const)

export const fetchExpiredAccountsError = (err: any) =>
  ({
    type: "license/FETCH_EXPIRED_ACCOUNTS_ERROR",
    err
  } as const)

export const fetchTestAccountsRequest = () =>
  ({
    type: "license/FETCH_TEST_ACCOUNTS"
  } as const)

export const fetchTestAccountsSuccess = (
  data: Required<AccountLicense>[],
  pagination?: PaginationResponse
) =>
  ({
    type: "license/FETCH_TEST_ACCOUNTS_SUCCESS",
    data,
    pagination
  } as const)

export const fetchTestAccountsError = (err: any) =>
  ({
    type: "license/FETCH_TEST_ACCOUNTS_ERROR",
    err
  } as const)

export const removeAccountRequest = () =>
  ({
    type: "license/REMOVE_ACCOUNT"
  } as const)

export const removeAccountSuccess = (borgId: number) =>
  ({
    type: "license/REMOVE_ACCOUNT_SUCCESS",
    borgId
  } as const)

export const removeAccountError = (err: any) =>
  ({
    type: "license/REMOVE_ACCOUNT_ERROR",
    err
  } as const)

export type LicenseAction = ReturnType<
  | typeof fetchExpiredAccountsRequest
  | typeof fetchExpiredAccountsSuccess
  | typeof fetchExpiredAccountsError
  | typeof fetchTestAccountsRequest
  | typeof fetchTestAccountsSuccess
  | typeof fetchTestAccountsError
  | typeof removeAccountRequest
  | typeof removeAccountSuccess
  | typeof removeAccountError
>
