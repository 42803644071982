import React from "react"

import { IconProps } from "components/Icons/Icons"

const GoBack: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <polygon points="3.19999996 1.59999996 3.19999996 3.19999996 19.2 3.19999996 19.2 16.8 6.29999998 16.8 9.19999999 13.9 7.99999998 12.8 3.19999996 17.6 7.99999998 22.4 9.09999999 21.3 6.19999998 18.4 20.7 18.4 20.7 1.59999996" />
  </svg>
)
export default GoBack
