export const toggleBottomBar = (withBottomBar: boolean) =>
  ({
    type: "layout/TOGGLE_BOTTOM_BAR",
    withBottomBar
  } as const)

export const toggleNavBar = (withNavBar: boolean) =>
  ({
    type: "layout/TOGGLE_NAV_BAR",
    withNavBar
  } as const)

export const setClientLimit = (limit: number) =>
  ({
    type: "layout/SET_CLIENT_LIMIT",
    limit
  } as const)


export const setFiltersVisible = (isVisible: boolean) =>
  ({
    type: "layout/SET_FILTERS_VISIBLE",
    isVisible
  } as const)

export type LayoutAction = ReturnType<
  | typeof toggleBottomBar
  | typeof toggleNavBar
  | typeof setClientLimit
  | typeof setFiltersVisible
>
