import { Dispatch } from "redux"

import { Api } from "api"
import {
  fetchSubmissionsError,
  fetchSubmissionsRequest,
  fetchSubmissionsSuccess
} from "store/submission/submissionActions"
import { PaginationRequest } from "types/ApiResponse"
import { SubmissionFilters } from "types/LeadForm"
import { showErrorNotification } from "utils/errors"

export const fetchSubmissions =
  (pagination: PaginationRequest, filters: SubmissionFilters) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchSubmissionsRequest())
    try {
      const res = await Api.getSubmissionsList(pagination)
      const canUpdateTotal = filters.submissionStatuses.length === 0
      dispatch(
        fetchSubmissionsSuccess(res.result, res.pagination, canUpdateTotal)
      )
    } catch (err) {
      dispatch(fetchSubmissionsError(err))
      showErrorNotification(
        err,
        "Wystąpił błąd podczas pobierania danych o zgłoszeniach. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
      )
    }
  }

export const searchSubmissions =
  (phrase: string, pagination: PaginationRequest) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchSubmissionsRequest())
    try {
      const res = await Api.searchSubmission(phrase, pagination)
      dispatch(fetchSubmissionsSuccess(res.result, res.pagination))
    } catch (err) {
      dispatch(fetchSubmissionsError(err))
    }
  }
