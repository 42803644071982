import { request, serialize } from "api/config"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import {
  ConsentsData,
  EmbeddedLeadFormValues,
  LeadFormLinks,
  LeadFormValues,
  Submission
} from "types/LeadForm"
import { showErrorNotification } from "utils/errors"

const PATH_NAME = "LeadForm"

export const getLeadFormLinks = async (): Promise<ResultModel<LeadFormLinks>> =>
  request.get(`${PATH_NAME}/links`).catch((err) => {
    showErrorNotification(
      err,
      "Wystąpił błąd podczas pobierania danych. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
    )
  })

export const getLeadFormConfiguration = async (): Promise<
  ResultModel<LeadFormValues>
> =>
  request.get(`${PATH_NAME}/configuration`).catch((err) => {
    showErrorNotification(
      err,
      "Wystąpił błąd podczas pobierania treści zgód. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
    )
  })
export const updateLeadFormConfig = async (
  payload: LeadFormValues
): Promise<ResultModel<LeadFormValues>> =>
  request.post(`${PATH_NAME}/configuration`, payload)

export const sendEmbeddedLeadForm = async (
  payload: Partial<EmbeddedLeadFormValues>
): Promise<ResultModel<EmbeddedLeadFormValues>> =>
  request.post(`${PATH_NAME}/form`, payload)

export const getLeadFormPublicConfiguration = async (
  formId: string
): Promise<ResultModel<LeadFormValues>> =>
  request.get(`${PATH_NAME}/form/${formId}`).catch((err) => {
    showErrorNotification(
      err,
      "Wystąpił błąd podczas pobierania treści zgód. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
    )
  })

export const getSubmissionsList = async (
  pagination: PaginationRequest
): Promise<ResultModel<Submission[]>> =>
  request
    .get(`${PATH_NAME}/submissionsList${serialize(pagination)}`)
    .catch((err) => {
      showErrorNotification(
        err,
        "Wystąpił błąd podczas pobierania listy zgłoszeń. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
      )
    })

export const searchSubmission = async (
  phrase: string,
  pagination: PaginationRequest
): Promise<ResultModel<Submission[]>> =>
  request.get(`${PATH_NAME}/Search${serialize({ phrase, ...pagination })}`)

export const approveSubmissionById = async (
  id: string
): Promise<ResultModel<Submission>> =>
  request.put(`${PATH_NAME}/approveSubmissionById/${id}`, id)

export const rejectSubmissionClientsList = async (
  ids: string[]
): Promise<string> => request.put(`${PATH_NAME}/rejectSubmissionsByIds`, ids)

export const getDefaultConfiguration = async (): Promise<
  ResultModel<LeadFormValues>
> => request.get(`${PATH_NAME}/defaultConfiguration`)

export const getLeadFormConsents = async (
  formId: string
): Promise<ResultModel<ConsentsData>> =>
  request.get(`${PATH_NAME}/getConsents/${formId}`).catch((err) => {
    showErrorNotification(err)
  })
