export interface AutoNewsletterDeliveryType {
  autoNewsletterDeliveryActive: boolean
  time: string
  cycle: "Monthly" | "Weekly"
  isDayOfMonth: boolean
  weekNumber: 1 | 2 | 3 | 4
  dayOfWeek:
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday"
    | "Sunday"
  dayOfMonth:
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 25
    | 26
    | 27
    | 28
    | 29
    | 30
    | 31
  ignoreNews: boolean
  amountDaysToIgnore: 1 | 2 | 3
  daysToNextDelivery: number
  dateNextDelivery: string
  serverDateTime: string
}

export type AutoNewsletterDeliveryValues = Omit<
  AutoNewsletterDeliveryType,
  "serverDateTime" | "dateNextDelivery"
>

type AutoNewsletterInitialValues = {
  [K in keyof Pick<
    AutoNewsletterDeliveryType,
    "cycle" | "weekNumber" | "dayOfWeek" | "dayOfMonth" | "amountDaysToIgnore"
  >]: AutoNewsletterDeliveryType[K][]
}

const autoNewsletterValues: AutoNewsletterInitialValues = {
  cycle: ["Monthly", "Weekly"],
  weekNumber: [1, 2, 3, 4],
  dayOfWeek: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ],
  dayOfMonth: Array.from(
    { length: 31 },
    (_, index) => (index + 1) as AutoNewsletterDeliveryType["dayOfMonth"]
  ),
  amountDaysToIgnore: [1, 2, 3]
}

export const options = {
  cycles: autoNewsletterValues.cycle,
  daysToIgnore: autoNewsletterValues.amountDaysToIgnore,
  daysOfWeek: autoNewsletterValues.dayOfWeek,
  weeksNumber: autoNewsletterValues.weekNumber,
  dayOfMonth: autoNewsletterValues.dayOfMonth
}