// eslint-disable-next-line import/no-named-as-default
import produce from "immer"
import { findIndex, pullAt } from "lodash"
import { Reducer } from "redux"

import { NotificationState } from "./NotificationState"
import { NotificationAction } from "./actions"

export const initialState: NotificationState = {
  notifications: [],
  meta: {
    hasNewInformationVersions: false,
    unreadCount: 0
  },
  isFetching: false,
  isFetched: false
}

export const notificationReducer: Reducer<
  NotificationState,
  NotificationAction
> = (state = initialState, action): NotificationState =>
  produce(state, (draft) => {
    switch (action.type) {
      case "notifications/FETCH_NOTIFICATIONS":
        draft.isFetching = true
        break
      case "notifications/FETCH_NOTIFICATIONS_SUCCESS":
        draft.notifications = action.data.list
        draft.meta = action.data.meta
        draft.isFetching = false
        draft.isFetched = true
        break
      case "notifications/FETCH_NOTIFICATIONS_ERROR":
        draft.isFetching = false
        break
      case "notifications/MARK_AS_READ_SUCCESS": {
        const index = findIndex(
          draft.notifications,
          (not) => not.id === action.id
        )
        if (index >= 0) {
          draft.notifications[index].isRead = true
          draft.meta.unreadCount = state.meta.unreadCount - 1
        }
        break
      }
      case "notifications/MARK_ALL_AS_READ_SUCCESS":
        draft.notifications.map((el) => {
          el.isRead = true
        })
        draft.meta.unreadCount = 0
        break
      case "notifications/REMOVE_NOTIFICATION_SUCCESS": {
        const index = findIndex(
          draft.notifications,
          (not) => not.id === action.id
        )
        if (index >= 0) {
          pullAt(draft.notifications, index)
        }
        break
      }
    }
  })
