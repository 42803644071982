import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  format,
  getTime
} from "date-fns"
import { useSelector } from "react-redux"

import { selectAutoNewsletterDelivery } from "store/autoNewsletterDelivery"
import { AutoNewsletterDeliveryType } from "types/AutoNewsletterDelivery"
import { TransFunc } from "utils/usePrefix"

export const useDeliveryDateInformation = () => {
  const formatTemplate = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
  const autoNewsletterDeliveryData = useSelector(selectAutoNewsletterDelivery)

  if (!autoNewsletterDeliveryData) {
    return {
      isToday: false,
      isTomorrow: false,
      isAfter: false,
      daysDifference: undefined,
      hoursDifference: undefined,
      minutesDifference: undefined,
      formattedTime: ""
    }
  }

  const { dateNextDelivery, serverDateTime } = autoNewsletterDeliveryData

  const deliveryDate = format(new Date(dateNextDelivery), formatTemplate)
  const minutesDifference = differenceInMinutes(deliveryDate, serverDateTime)
  const hoursDifference = differenceInHours(deliveryDate, serverDateTime)
  const daysDifference = differenceInCalendarDays(deliveryDate, serverDateTime)

  const isToday = differenceInCalendarDays(deliveryDate, serverDateTime) === 0
  const isTomorrow =
    differenceInCalendarDays(deliveryDate, serverDateTime) === 1

  return {
    isToday,
    isTomorrow,
    daysDifference,
    hoursDifference,
    minutesDifference,
    formattedTime: format(
      getTime(autoNewsletterDeliveryData.dateNextDelivery),
      "HH:mm"
    )
  }
}

export const getDeliveryHeaderTranslation = ({
  isToday,
  isTomorrow,
  minutesDifference,
  hoursDifference,
  daysDifference,
  t
}: {
  isToday: boolean
  isTomorrow: boolean
  minutesDifference?: number
  hoursDifference?: number
  daysDifference?: number
  t: TransFunc
}) => {
  if (minutesDifference === 0) {
    return t("deliveryInProgress")
  }

  if (minutesDifference && minutesDifference < 60) {
    return `${t("nextDelivery")} ${t("minutes", { minutes: minutesDifference })}`
  }

  if (isToday && minutesDifference && minutesDifference >= 60) {
    return `${t("nextDelivery")} ${t("hours", { hours: hoursDifference })}`
  }

  if (isTomorrow) {
    return `${t("nextDelivery")} ${t("tomorrow")}`
  }

  if (!isToday && !isTomorrow && daysDifference && daysDifference > 1) {
    const daysToNextDelivery = t("in_days", {
      daysToNextDelivery: daysDifference
    })
    return `${t("nextDelivery")} ${daysToNextDelivery}`
  } else return ""
}

export const getDeliveryDetailsTranslation = (
  autoNewsletterDeliveryData: AutoNewsletterDeliveryType,
  t: TransFunc
) => {
  const {
    dayOfMonth,
    amountDaysToIgnore,
    cycle,
    dayOfWeek,
    weekNumber,
    ignoreNews,
    isDayOfMonth,
    dateNextDelivery
  } = autoNewsletterDeliveryData
  const cycleTranslation = t(`${cycle}`)
  const dayOfWeekTranslation = t(`${dayOfWeek}`)

  const timeTranslation = `${t("at")} ${format(getTime(dateNextDelivery), "HH:mm")}`
  const dayOfMonthTranslation = t("dayOfMonth")
  const monthsTranslation = t("months")

  const deliveryDetails =
    cycle === "Monthly"
      ? isDayOfMonth
        ? `${dayOfMonth}. ${dayOfMonthTranslation}`
        : `${weekNumber}. ${dayOfWeekTranslation}`
      : `${weekNumber} ${dayOfWeekTranslation} ${monthsTranslation}`

  const deliveryCycle =
    cycle === "Monthly"
      ? `${cycleTranslation} (${deliveryDetails})`
      : `${cycleTranslation} (${dayOfWeekTranslation})`

  const ignoreNewsDetails = t("ignoreNewsDetails", {
    postProcess: "interval",
    count: amountDaysToIgnore,
    daysToIgnore: amountDaysToIgnore
  })

  const shouldIgnoreNews = ignoreNews && amountDaysToIgnore > 0

  return `${`${deliveryCycle} ${timeTranslation} ${shouldIgnoreNews ? ignoreNewsDetails : ""}`.trimEnd()}.`
}