import { styled } from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 10px;

  svg {
    cursor: pointer;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
`

export const ContentWrapper = styled.div`
  height: 500px;
  width: 500px;
  overflow: hidden;
  overflow-y: auto;

  .ant-collapse-content-box {
    word-break: break-word;
  }

  .ant-collapse-content.ant-collapse-content-active {
    position: relative;
  }
`

export const TitleWrapper = styled.div<{ $isRead: boolean }>`
  font-weight: ${({ $isRead }) => ($isRead ? 300 : 500)};
`

export const RemoveBtnWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`
