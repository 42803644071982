import { createSelector } from "reselect"

import { AppState } from "store/AppState"

export const selectLeadFormConfigurationContentPending = (s: AppState) =>
  s.leadForm.configuration.pending

export const selectLeadFormConfigurationContentData = (s: AppState) =>
  s.leadForm.configuration.data

export const selectLeadFormConfigurationContent = createSelector(
  selectLeadFormConfigurationContentData,
  (data) => data
)
