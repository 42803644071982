import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import SessionManager from "api/SessionManager"
import { Loading } from "components/Loading"
import { RoleEnum } from "enums"
import { fetchAccountingOfficeAction } from "store/accountingOffice"
import { fetchAutoNewsletterDelivery } from "store/autoNewsletterDelivery"
import { fetchClients } from "store/client"
import { fetchCustomTags } from "store/customTag"
import { fetchFormActivity } from "store/formActivity"
import { fetchTagLabels } from "store/label"
import { fetchClientLimit } from "store/layout/layoutApi"
import { fetchNotifications } from "store/notifications"
import { fetchPKD } from "store/pkd"
import { hitTagManager } from "utils/gtm"
import { DEFAULT_PAGE_SIZE } from "utils/hooks"

type Props = {
  children: React.ReactNode
}

const LoginManager = ({ children }: Props) => {
  const [isLogged, setIsLogged] = useState(false)

  useInitialData(isLogged)

  useEffect(() => {
    const authorize = async () => {
      const isLogged = await SessionManager.authorize()
      setIsLogged(isLogged)
      hitTagManager()
    }

    authorize()
  }, [])

  if (!isLogged) {
    return <Loading />
  }

  return <>{children}</>
}

const useInitialData = (isLogged: boolean) => {
  const dispatch = useDispatch()
  const session = SessionManager.getSession()

  const userRole = session?.userRole

  useEffect(() => {
    if (isLogged) {
      dispatch(fetchTagLabels())
      dispatch(fetchFormActivity())
      dispatch(fetchPKD())

      if (RoleEnum.AO_Publisher === userRole || RoleEnum.AO_User === userRole) {
        dispatch(fetchNotifications())
        dispatch(fetchCustomTags())
        dispatch(fetchClientLimit())
        dispatch(
          fetchClients(
            { pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE },
            { clientStatuses: [] }
          )
        )
      }

      if (RoleEnum.AO_Publisher === userRole) {
        dispatch(fetchAccountingOfficeAction())
        dispatch(fetchAutoNewsletterDelivery())
      }
    }
  }, [dispatch, isLogged, userRole])
}

export default LoginManager