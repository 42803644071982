import { combineReducers } from "redux"

import { NewsState } from "./NewsReducerState"
import { publishedReducer } from "./published"
import { toPublishReducer } from "./toPublish"
import { workingNewsReducer } from "./working"

export const newsReducer = combineReducers<NewsState>({
  workingNews: workingNewsReducer,
  toPublish: toPublishReducer,
  published: publishedReducer
})
