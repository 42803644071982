import { normalize, schema } from "normalizr"
import { createSelector } from "reselect"

import { SelectOption } from "types/Template"

import { AppState } from "../AppState"

export const selectCustomTagFetchError = (s: AppState) => s.customTag.error

export const selectCustomTagListData = (s: AppState) => s.customTag.data

export const selectCustomTagList = createSelector(
  selectCustomTagListData,
  (customTagList) =>
    customTagList?.map((el) => ({ label: el.name, value: el.id })) || []
)

export const selectNormalizedAllCustomTags = createSelector(
  selectCustomTagList,
  (customTagList) => {
    if (!customTagList || customTagList.length === 0) {
      return {}
    }

    const customTag = new schema.Entity<SelectOption>(
      "customTag",
      {},
      { idAttribute: "value" }
    )

    return normalize(customTagList, [customTag]).entities.customTag || {}
  }
)