import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`

    html, body {
        height: 100%;
    }

    #root {
        min-height: 100%;
        background: ${({ theme }) => theme.colors.bgGrey};
    }

    body {
        margin: 0;
        font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${({ theme }) => theme.colors.textSecondary};
    }

    h1, h2, h3, h4, h5, h6 {
        color: ${({ theme }) => theme.colors.textSecondary};
        margin-bottom: 0.3em;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    ol {
        padding: 0;
    }

    .ant-form-vertical .ant-form-item-label {
        padding: 0 0 4px;
    }

    .ant-form-item-label > label {
        color: ${({ theme }) => theme.colors.textSecondary};
        font-weight: 400;
        font-size: 13px;
    }

    .ant-input {
        height: 40px;
        border: 1px solid ${({ theme }) => theme.colors.textPlaceholder};
        padding: 4px 10px;
    }

    .ant-input-search-button {
        width: 40px;
        height: 40px;
        border: 1px solid ${({ theme }) => theme.colors.textPlaceholder};
    }

    .ant-input-affix-wrapper {
        padding: 0 10px;
        border: 1px solid ${({ theme }) => theme.colors.textPlaceholder};
    }

    .ant-input-group-addon {
        border: 1px solid ${({ theme }) => theme.colors.textPlaceholder};

        &:hover {
            border-color: ${({ theme }) => theme.colors.blueDark};
            opacity: 0.7;
        }

    }

    .ant-input-suffix {
        margin-right: 10px;
        color: ${({ theme }) => theme.colors.textPlaceholder};
    }

    .ant-input-search .ant-input {
        height: 38px;
    }

    .ant-input-search .ant-input-suffix {
        margin-right: 0;
    }

    .ant-input-prefix {
        color: ${({ theme }) => theme.colors.textPlaceholder};
    }

    .ant-checkbox {
        transform: scale(1.5);
        padding: 5px;
    }

    .ant-checkbox + span {
        margin: auto;

        & svg {
            transform: translateY(2px);
        }
    }

    .ant-table-thead > tr > th {
        color: ${({ theme }) => theme.colors.textAdditional};
        font-size: 12px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.bgGrey};
    }

    .ant-table-tbody > tr > td {
        padding: 18px;
    }

    table tr td.ant-table-selection-column {
        padding-left: 18px;
    }

    .ant-table td {
        vertical-align: top;
    }

    .ant-table-filter-column-title {
        flex: unset;
    }

    .ant-table-filter-trigger-container {
        position: relative;
    }

    .Select-menu-outer {
        z-index: 10;
    }

    .ant-btn-text, .ant-btn-link {
        display: flex;
        align-items: center;
    }

    .ant-pagination-item {
        border: none;
        border-radius: 0;

        &-active, &-active:focus {
            background: ${({ theme }) => theme.colors.blueDark};

            & a, & a:hover {
                color: ${({ theme }) => theme.colors.white};
            }
        }
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        border-radius: 0;
        cursor: pointer;
    }

    .ant-list-item-meta-title {
        word-break: break-word;
        min-height: 56px;
    }

    .ant-divider-horizontal.ant-divider-with-text::before,
    .ant-divider-horizontal.ant-divider-with-text::after {
        border-top: 1px solid ${({ theme }) => theme.colors.borderGreyDarker};

    }

    .ant-divider-horizontal.ant-divider-with-text {
        color: ${({ theme }) => theme.colors.textAdditional};
        font-weight: 300;
        margin: 32px 0;
    }

    .ant-table-filter-trigger {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-notification {
        z-index: 100000;
    }

    .ant-form-item-required:before {
        position: absolute;
        right: -12px;
        top: 1px;
    }

    .ant-btn-text, .ant-btn-text:focus {
        color: ${({ theme }) => theme.colors.blueDark};

        &:hover {
            color: ${({ theme }) => theme.colors.blueDark};
            opacity: 0.7;
        }
    }

    .ant-btn-link, .ant-btn-link:focus {
        color: ${({ theme }) => theme.colors.greenActive};

        &:hover {
            color: ${({ theme }) => theme.colors.greenActive};
            opacity: 0.7;
        }
    }


    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.colors.blueActive};
    }

    .ant-checkbox-disabled .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.colors.inactive} !important;
    }

    input[type="checkbox"]:checked + .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.colors.blueDark};
    }

    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        background: ${({ theme }) => theme.colors.inactive};
        border-color: ${({ theme }) => theme.colors.inactive};
    }

    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
        border-color: transparent;
    }

    .ant-tabs-tab {
        font-size: 1rem;
        padding: 0;
        padding-bottom: 16px;
    }

    .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
        align-items: unset;
    }

    .ant-form-item-explain {
        font-size: 12px;
    }

    .ant-radio-inner {
        display: none;
    }

    .ant-radio-group .ant-radio-wrapper {
        border: 1px solid ${({ theme }) => theme.colors.textPlaceholder};
        margin-right: -1px;
        padding: 4px 10px;

        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }

        &.ant-radio-wrapper-checked {
            background: ${({ theme }) => theme.colors.blueLight};
            color: ${({ theme }) => theme.colors.blueDark};
        }

    }


    .ant-checkbox-checked::after {
        display: none;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn h4 {
        color: ${({ theme }) => theme.colors.blueActive};
    }

    .ant-collapse-ghost > .ant-collapse-item {
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderGrey};;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-header:hover {
        color: ${({ theme }) => theme.colors.blueActive};
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-header:hover,
    .ant-collapse-ghost > .ant-collapse-item-active > .ant-collapse-header {
        background: ${({ theme }) => theme.colors.blueLight};
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-header {
        padding: 16px;
    }

    .ant-modal-header {
        border-bottom: none;
    }

    .Select--multi .Select-multi-value-wrapper {
        padding: 6px 6px 0;

        & .Select-placeholder {
            margin-top: 4px;
        }

        & .Select-input {
            margin-left: 5px;
        }
    }

    .ant-btn > .ant-btn-loading-icon .anticon {
        padding-right: unset;
        margin-right: 8px;
    }
`