import { defaults, send, request } from "api/config"
import { ResultModel } from "types/ApiResponse"

const PATH_NAME = "File"

export const uploadFile = async (
  formData: FormData
): Promise<ResultModel<string>> => {
  const params = defaults.post()
  delete params.headers["Content-Type"]

  return await send(defaults.endpoint(`${PATH_NAME}/UploadFileRetrieveLink`), {
    method: "POST",
    headers: params.headers,
    body: formData
  })
}

export const getClientDataStylesheet = async (): Promise<any> => {
  return await request.get(`${PATH_NAME}/ClientDataStylesheet`)
}
