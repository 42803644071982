import React from "react"

import { IconProps } from "components/Icons/Icons"

const User: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M17,13.2 C18.4,11.9 19.2,10.1 19.2,7.99999998 C19.2,3.99999997 16,0.799999953 12,0.799999953 C7.99999998,0.799999953 4.79999995,3.99999997 4.79999995,7.99999998 C4.79999995,9.99999999 5.59999997,11.9 6.99999998,13.2 C3.29999995,15 0.799999953,18.8 0.799999953,23.2 L23.2,23.2 C23.2,18.8 20.7,15 17,13.2 Z M6.39999998,7.99999998 C6.39999998,4.89999996 8.89999998,2.39999996 12,2.39999996 C15.1,2.39999996 17.6,4.89999996 17.6,7.99999998 C17.6,11.1 15.1,13.6 12,13.6 C8.89999998,13.6 6.39999998,11.1 6.39999998,7.99999998 Z M8.49999996,14.3 C9.49999996,14.9 10.7,15.2 12,15.2 C13.3,15.2 14.5,14.9 15.5,14.3 C18.6,15.5 20.9,18.3 21.5,21.6 L2.49999996,21.6 C3.09999996,18.3 5.39999997,15.5 8.49999996,14.3 Z" />
  </svg>
)
export default User
