import { Modal } from "antd"
import React from "react"

import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import Icon from "components/Icons"
import { H2, Paragraph, theme } from "style"

interface WarningModalProps {
  title: string
  subtitle?: string
  mainBtnTitle: string
  altBtnTitle?: string
  mainBtnAction: () => void
  altBtnAction?: () => void
  onCancel: () => void
  description?: string
}

export const WarningModal: React.FC<WarningModalProps> = ({
  title,
  subtitle,
  mainBtnTitle,
  altBtnTitle,
  mainBtnAction,
  altBtnAction,
  onCancel,
  description
}) => {
  return (
    <Modal
      centered
      open
      footer={false}
      width="80vw"
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "100px 0"
      }}
      onCancel={onCancel}
    >
      <Icon
        type="noFile"
        width="130px"
        height="130px"
        color={theme.colors.orange}
        style={{
          boxSizing: "content-box",
          padding: "50px"
        }}
      />
      <H2 style={{ color: theme.colors.orange }}>{title}</H2>
      {subtitle && <H2>{subtitle}</H2>}
      {description && (
        <Paragraph
          $fontSize={22}
          $textAlign="center"
          $padding="20px 0"
          color={theme.colors.blueActive}
        >
          {description}
        </Paragraph>
      )}

      <Flexbox $gap="16px">
        <Box>
          <Button onClick={mainBtnAction} type="primary">
            {mainBtnTitle}
          </Button>
        </Box>

        {altBtnTitle && altBtnAction && (
          <Box>
            <Button onClick={altBtnAction}>{altBtnTitle}</Button>
          </Box>
        )}
      </Flexbox>
    </Modal>
  )
}