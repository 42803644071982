import { createSelector } from "reselect"

import { initialState } from "./reducer"

import { AppState } from "../AppState"

const getComponentState = (s: AppState) => s.notification || initialState

export const selectIsFetching = createSelector(
  [getComponentState],
  (state) => state.isFetching
)

export const selectIsFetched = createSelector(
  [getComponentState],
  (state) => state.isFetched
)

export const selectNotifications = createSelector(
  [getComponentState],
  (state) => state.notifications
)

export const selectNotificationsMeta = createSelector(
  [getComponentState],
  (state) => state.meta
)

export const selectUnreadCount = createSelector(
  [selectNotificationsMeta],
  (meta) => meta.unreadCount
)
