import React from "react"

import { IconProps } from "components/Icons/Icons"

const FilePdf: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path
      d="M19.7,14.1 C18.1,14.1 16.1,14.4 15.6,14.4999999 C12.8,11.4999999 12.1,9.79999999 11.9999999,9.49999995 C12.2,9.09999999 12.9999999,6.79999998 13.1,3.79999997 C13.2,2.39999996 12.9,1.29999996 12.3,0.699999953 C11.7,0.0999999504 11.1,-5.00000006e-08 10.8,-5.00000006e-08 C9.59999999,-5.00000006e-08 8.99999995,0.99999995 8.99999995,2.89999996 C8.99999995,5.29999997 10.2,7.89999998 10.4,8.29999998 C8.69999999,13.2 6.99999995,16.4999999 6.69999998,16.9999999 C0.299999951,19.4999999 -5.00000006e-08,21.9 -5.00000006e-08,22.3 C-5.00000006e-08,23.4 0.799999953,23.9999999 1.99999995,23.9999999 C5.29999997,23.9999999 8.39999999,17.9999999 8.79999999,17.3 C11.3,16.3 14.7,15.7 15.4,15.4999999 C17.7,17.8 20.3,18.4 21.4,18.4 C22.3,18.4 23.9999999,18.4 23.9999999,16.6 C23.9999999,14.7 21.7,14.1 19.7,14.1 Z M22,16.2 C22,16.4 21.9,17.3 20.8,17.3 C19.8,17.3 18,16.7 16.4,15.3 C17,15.2 18.1,15.1 19.5,15.1 C21.5,15 22,15.7 22,16.2 Z M10.2,1.29999996 C10.3,1.09999995 10.5,0.999999954 10.8,0.999999954 C11,0.999999954 11.2,1.09999995 11.3,1.19999996 C12,2.19999996 11.3,5.39999997 10.8,7.09999998 C10.1,5.49999995 9.49999999,2.39999996 10.2,1.29999996 Z M14.5,14.7 C12,15.2 10.2,15.8 9.39999999,16 C10.2,14.5 11.2,11.8 11.5,10.7 C12.5,12.5 13.9,14.1 14.5,14.7 Z M5.89999995,18.4 C5.39999995,19.2 4.39999995,20.9 3.29999996,22 C2.49999996,22.7 1.99999996,23 1.59999996,23 C1.39999995,23 1.19999995,22.9 0.999999954,22.6 C0.899999954,22.5 0.899999954,22.3 0.899999954,22.1 C1.09999995,21.3 2.59999996,19.9 5.89999995,18.4 Z"
      fill={color}
    />
  </svg>
)

export default FilePdf
