export enum NewsStatusEnum {
  Draft = "Draft",
  ToPublish = "ToPublish",
  Published = "Published"
}

export enum NewsAOStatusEnum {
  New = "New",
  Sent = "Sent",
  Archived = "Archived"
}
