import { notification } from "antd"
import React from "react"

import { showErrorNotification } from "utils/errors"
import usePrefix from "utils/usePrefix"

export function useCopyToClipboard() {
  const t = usePrefix("General")

  const copyCode = React.useCallback(
    async (text: string | number) => {
      try {
        await navigator.clipboard.writeText(text.toString())
        notification.success({
          message: t("copyToClipboardTitle"),
          description: t("copyToClipboardDescription")
        })
      } catch (err) {
        showErrorNotification(err, t("copyToClipboardFailed"))
      }
    },
    [t]
  )

  return copyCode
}
