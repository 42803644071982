import { CSSProperties } from "react"
import { css } from "styled-components"

export interface ColorProps {
  $color?: CSSProperties["color"]
  $backgroundColor?: CSSProperties["backgroundColor"]
  $opacity?: CSSProperties["opacity"]

  $backgroundImage?: CSSProperties["backgroundImage"]
  $backgroundSize?: CSSProperties["backgroundSize"]
  $backgroundPosition?: CSSProperties["backgroundPosition"]
  $backgroundRepeat?: CSSProperties["backgroundRepeat"]
  $background?: CSSProperties["background"]

  $boxShadow?: CSSProperties["boxShadow"]
}

export const colorProps = css<ColorProps>`
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  opacity: ${({ $opacity }) => $opacity};

  background-image: ${({ $backgroundImage }) => $backgroundImage};
  background-size: ${({ $backgroundSize }) => $backgroundSize};
  background-position: ${({ $backgroundPosition }) => $backgroundPosition};
  background-repeat: ${({ $backgroundRepeat }) => $backgroundRepeat};
  background: ${({ $background }) => $background};

  box-shadow: ${({ $boxShadow }) => $boxShadow};
`
