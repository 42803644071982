import React from "react"

import { IconProps } from "components/Icons/Icons"

const Send: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M23.2,0.799999953 L0.799999953,10.4 L11.2,14.1 L11.2,23.2 L15.6,16.2 L23.2,20 L23.2,0.799999953 Z M19.4,4.19999997 L12.3,12.8 L5.19999997,10.3 L19.4,4.19999997 Z M12.8,17.7 L12.8,14.8 L14.2,15.5 L12.8,17.7 Z M13.8,13.5 L21.6,3.99999997 L21.6,17.4 L13.8,13.5 Z" />
  </svg>
)
export default Send
