import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { PromiseAction } from "types/RemoteAction"

export const fetchNewsletterPreviewHtmlAction =
  (newsletterId: string) =>
  (dispatch: Dispatch): PromiseAction<ResultModel<string>> =>
    dispatch({
      type: "newsletterPreviewHtml/FETCH",
      promise: Api.getNewsletterPreviewHTML(newsletterId)
    })
