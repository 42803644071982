import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"

import { fetchNotifications } from "store/notifications"

const useRefreshNotifications = () => {
  const tickRef = useRef<NodeJS.Timeout>()
  const dispatch = useDispatch()

  useEffect(() => {
    tickRef.current = setInterval(() => {
      dispatch(fetchNotifications())
    }, REFRESH_INTERVAL)

    return () => {
      clearInterval(tickRef.current)
    }
  }, [dispatch])
}

const REFRESH_INTERVAL = 60 * 1000

export default useRefreshNotifications
