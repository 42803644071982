import { ResultModel } from "types/ApiResponse"
import { FormActivity, PKD, TagGroupStructure } from "types/Template"

import { request, serialize } from "./../config"

const PATH_NAME = "ClientActivity"

export const getTagLabels = async (): Promise<
  ResultModel<TagGroupStructure[]>
> => request.get(`${PATH_NAME}/GetTags`)

export const getActivityForm = async (): Promise<ResultModel<FormActivity[]>> =>
  request.get(`${PATH_NAME}/GetActivityForms`)

export const getPKD = async (): Promise<ResultModel<PKD[]>> =>
  request.get(`${PATH_NAME}/GetPkds`)

export const getClientTagsByActivityForm = async (
  activityFormId: number
): Promise<ResultModel<number[]>> =>
  request.get(
    `${PATH_NAME}/GetClientTagsByActivityForm${serialize({ activityFormId })}`
  )
