import { Select as AntSelect, SelectProps } from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import React from "react"

const { Option } = AntSelect

type AntSelectProps<
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType
> = SelectProps<ValueType, OptionType>

type Props<
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType
> = AntSelectProps<ValueType, OptionType>

const Select = ({ ...props }: Props) => {
  return <AntSelect {...props} />
}

export { Option }

export default Select
