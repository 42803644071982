import { CSSProperties } from "react"
import { css } from "styled-components"

export interface LayoutProps {
  $width?: CSSProperties["width"]
  $height?: CSSProperties["height"]
  $minWidth?: CSSProperties["minWidth"]
  $minHeight?: CSSProperties["minHeight"]
  $maxWidth?: CSSProperties["maxWidth"]
  $maxHeight?: CSSProperties["maxHeight"]
  $display?: CSSProperties["display"]
  $verticalAlign?: CSSProperties["verticalAlign"]
  $overflow?: CSSProperties["overflow"]
}

export const layoutProps = css<LayoutProps>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  min-width: ${({ $minWidth }) => $minWidth};
  min-height: ${({ $minHeight }) => $minHeight};
  max-width: ${({ $maxWidth }) => $maxWidth};
  max-height: ${({ $maxHeight }) => $maxHeight};
  display: ${({ $display }) => $display};
  vertical-align: ${({ $verticalAlign }) => $verticalAlign};
  overflow: ${({ $overflow }) => $overflow};
`
