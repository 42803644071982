import { Radio, Tooltip } from "antd"
import * as React from "react"

import { Box } from "components/Flexbox"
import Icon from "components/Icons"
import Select from "components/Inputs/Select"
import { FieldValueUpdate } from "components/Modal/AutoNewsletterDelivery/AutoNewsletterDeliveryForm"
import {
  RadioWrapper,
  SelectWrapper
} from "components/Modal/AutoNewsletterDelivery/styles"
import { theme } from "style"
import {
  AutoNewsletterDeliveryValues,
  options
} from "types/AutoNewsletterDelivery"
import usePrefix from "utils/usePrefix"

type MonthlyConfigProps = {
  values: AutoNewsletterDeliveryValues
  onChange: FieldValueUpdate
}
export const MonthlyConfig: React.FC<MonthlyConfigProps> = ({
  values,
  onChange
}) => {
  const t = usePrefix("AutoDelivery")
  const { isDayOfMonth, weekNumber, dayOfMonth, dayOfWeek } = values

  return (
    <RadioWrapper>
      <Radio.Group
        name="isDayOfMonth"
        onChange={(e) => onChange("isDayOfMonth", e.target.value)}
        value={isDayOfMonth}
      >
        <Box
          color={theme.colors.textSecondary}
          style={{ lineHeight: "24px", fontSize: "14px" }}
        >
          <Radio name="isDayOfMonth" value={false}>
            <Box $display="inline">{t("every")}</Box>
          </Radio>
          <SelectWrapper
            style={{
              width: "80px",
              display: "inline-block",
              margin: "0 6px"
            }}
          >
            <Select
              showArrow
              showSearch={false}
              value={weekNumber}
              onChange={(value) => onChange("weekNumber", value)}
              options={options.weeksNumber.map((weekNumber) => ({
                value: weekNumber,
                label: weekNumber
              }))}
            />
          </SelectWrapper>
          <SelectWrapper
            style={{
              width: "140px",
              display: "inline-block",
              margin: "0 6px"
            }}
          >
            <Select
              showArrow
              showSearch={false}
              value={dayOfWeek}
              onChange={(value) => onChange("dayOfWeek", value)}
              options={options.daysOfWeek.map((day) => ({
                value: day,
                label: t(`${day}`)
              }))}
            />
          </SelectWrapper>
          <Box $display="inline">{t("months")}</Box>
        </Box>
        <Box
          $color={theme.colors.textSecondary}
          style={{ lineHeight: "24px", fontSize: "14px" }}
        >
          <Radio name="isDayOfMonth" value={true}>
            <Box $display="inline">{t("every")}</Box>
          </Radio>
          <SelectWrapper
            style={{
              width: "80px",
              display: "inline-block",
              margin: "0 6px"
            }}
          >
            <Select
              showArrow
              showSearch={false}
              value={dayOfMonth}
              onChange={(value) => onChange("dayOfMonth", value)}
              options={options.dayOfMonth.map((dayOfMonth) => ({
                value: dayOfMonth,
                label: dayOfMonth
              }))}
            />
          </SelectWrapper>
          <Box $display="inline">{t("dayOfMonth")}</Box>
          <Tooltip title={t("tooltipNotFoundDay")}>
            <Box $display="inline" $marginLeft="5px">
              <Icon
                type="infoRound"
                color={theme.colors.textSecondary}
                width="16px"
                height="16px"
                style={{ transform: "translateY(4px)" }}
              />
            </Box>
          </Tooltip>
        </Box>
      </Radio.Group>
    </RadioWrapper>
  )
}