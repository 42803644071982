import { PaginationRequest, ResultModel } from "types/ApiResponse"
import { News, NewsAO, NewsStatus } from "types/News"

import { request, serialize } from "../config"

const PATH_NAME = "News"

export const fetchNews = async (
  status: NewsStatus,
  pagination: PaginationRequest
): Promise<ResultModel<News[]>> =>
  request.get(`${PATH_NAME}${serialize({ status, ...pagination })}`)

export const searchPublishedNews = async (
  phrase: string,
  pagination: PaginationRequest
): Promise<ResultModel<News[]>> =>
  request.get(
    `${PATH_NAME}/PublishedSearch${serialize({ phrase, ...pagination })}`
  )

export const fetchOriginalNews = async (
  pagination: PaginationRequest
): Promise<ResultModel<NewsAO[]>> =>
  request.get(`${PATH_NAME}/OriginalNewsForAo${serialize(pagination)}`)

export const fetchNewsById = async (id: string): Promise<ResultModel<News>> =>
  request.get(`${PATH_NAME}/${id}`)

export const addNews = async (news: News): Promise<ResultModel<News>> =>
  request.post(PATH_NAME, news)

export const updateNews = async (news: News): Promise<ResultModel<News>> =>
  request.put(`${PATH_NAME}/${news.id}`, news)

export const deleteNews = async (id: string): Promise<string> =>
  request.delete(`${PATH_NAME}/${id}`)

export const archiveNews = async (id: string): Promise<ResultModel<News>> =>
  request.patch(`${PATH_NAME}/${id}/Archive`)

export const publishNews = async (
  newsIdsSorted: string[]
): Promise<ResultModel<News>> =>
  request.patch(`${PATH_NAME}/Publish`, { newsIdsSorted })

export const confirmToPublishNews = async (
  id: string
): Promise<ResultModel<News>> =>
  request.patch(`${PATH_NAME}/${id}/ConfirmToPublish`)

export const moveNewstoDraft = async (id: string): Promise<ResultModel<News>> =>
  request.patch(`${PATH_NAME}/${id}/ToDraft`)
