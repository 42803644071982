import { PaginationResponse } from "types/ApiResponse"
import { Client } from "types/Client"

export const fetchClientsRequest = () =>
  ({
    type: "clients/FETCH_CLIENTS"
  } as const)

export const fetchClientsSuccess = (
  clients: Client[],
  pagination?: PaginationResponse,
  canUpdateTotal?: boolean
) =>
  ({
    type: "clients/FETCH_CLIENTS_SUCCESS",
    clients,
    pagination,
    canUpdateTotal
  } as const)

export const fetchClientsError = (err: any) =>
  ({
    type: "clients/FETCH_CLIENTS_ERROR",
    err
  } as const)

export type ClientActions = ReturnType<
  | typeof fetchClientsRequest
  | typeof fetchClientsSuccess
  | typeof fetchClientsError
>
