import React from "react"

import { IconProps } from "components/Icons/Icons"

const ArrowDropdown: React.FC<IconProps> = ({
  color,
  width,
  height,
  ...props
}) => (
  <svg width={width} height={height} viewBox="0 0 8 7" fill={color} {...props}>
    <path
      d="M3.3704 5.92504C3.38261 5.94177 3.39574 5.95768 3.40971 5.97269C3.56613 6.14321 3.77822 6.239 3.99934 6.239C4.22047 6.239 4.43256 6.14321 4.58898 5.97269C4.60295 5.95768 4.61608 5.94177 4.62829 5.92504L7.66777 1.47574C7.71384 1.40811 7.74177 1.32784 7.74844 1.24389C7.75511 1.15994 7.74027 1.07562 7.70557 1.00034C7.67087 0.92506 7.61767 0.861786 7.55191 0.81758C7.48616 0.773374 7.41044 0.749977 7.33321 0.75H0.666793C0.589558 0.749977 0.513838 0.773374 0.448085 0.81758C0.382332 0.861786 0.329134 0.92506 0.294431 1.00034C0.259728 1.07562 0.244885 1.15994 0.251559 1.24389C0.258233 1.32784 0.286162 1.40811 0.332227 1.47574L3.3704 5.92504Z"
      fill={color}
    />
  </svg>
)
export default ArrowDropdown
