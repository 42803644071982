import React from "react"
import { useDispatch } from "react-redux"

import { Button } from "components/Button"
import Flexbox from "components/Flexbox"
import { markAllAsRead } from "store/notifications"
import { Header, theme } from "style"
import usePrefix from "utils/usePrefix"

const NotificationHeader = () => {
  const t = usePrefix("Notification")
  const dispatch = useDispatch()

  const onMarkAllAsRead = () => dispatch(markAllAsRead())

  return (
    <Flexbox $justifyContent="space-between" $alignItems="center">
      <Header style={{ marginBottom: 0 }}>
        {t("popover_notification_header")}
      </Header>
      <Button
        type="text"
        style={{ color: theme.colors.textSecondary }}
        onClick={onMarkAllAsRead}
      >
        {t("popover_mark_as_read_btn")}
      </Button>
    </Flexbox>
  )
}

export default NotificationHeader
