import { Tabs, TabsProps } from "antd"
import React, { useState } from "react"

import Flexbox, { Box } from "components/Flexbox"
import Icon from "components/Icons"
import { ImgMinature } from "components/ImgMiniature"
import { H4, theme, SectionHeader, H5 } from "style"
import { Client } from "types/Client"
import { NewsletterConfirmation, ReceiverConfirmation } from "types/Newsletter"
import { formatDate } from "utils/date"
import usePrefix from "utils/usePrefix"

import { ClientList } from "./ClientList"
import { StyledModal } from "./styles"

export type ClientReceiverType = Client &
  Partial<Pick<ReceiverConfirmation, "isNewsConfirmed" | "resendingDateUtc">>

export interface SelectClientProps {
  onSelectClient?: (id: string) => void
  selectedClients?: (string | undefined)[]
  handleSelectAll?: () => void
  isSelectedAll?: boolean
  isResendModal?: boolean
  isClientRemovable?: boolean
  onClientRemove?: (id: string) => void
}

export interface ClientListModalProps {
  isVisible: boolean
  title: React.ReactNode
  subtitle?: string
  clients?: ClientReceiverType[] | null
  header?: React.ReactNode
  footer: React.ReactNode
  onClose: () => void
  confirmation?: NewsletterConfirmation
  isResendModal?: boolean
}

export const ClientListModal: React.FC<
  ClientListModalProps & SelectClientProps
> = ({
  isVisible,
  isClientRemovable = false,
  onClientRemove,
  confirmation,
  title,
  subtitle,
  clients,
  header,
  footer,
  onClose,
  onSelectClient,
  selectedClients,
  handleSelectAll,
  isSelectedAll,
  isResendModal
}) => {
  const t = usePrefix("Confirmation")

  const [activeKey, setActiveKey] = useState("1")

  const confirmedClients = clients
    ? clients.filter((client) => client?.isNewsConfirmed)
    : []
  const unconfirmedClients = clients
    ? clients.filter((client) => !client?.isNewsConfirmed)
    : []

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <SectionHeader>
          <Icon
            type="angry"
            width="16"
            height="16"
            color={
              activeKey === "1"
                ? theme.colors.blueActive
                : theme.colors.textSecondary
            }
            style={{ marginRight: "8px" }}
          />
          {t("unconfirmed")} ({unconfirmedClients.length})
        </SectionHeader>
      ),
      children: (
        <ClientList
          clients={unconfirmedClients}
          onSelectClient={onSelectClient}
          selectedClients={selectedClients}
          handleSelectAll={handleSelectAll}
          isSelectedAll={isSelectedAll}
          isResendModal={isResendModal}
        />
      )
    },
    {
      key: "2",
      label: (
        <SectionHeader>
          <Icon
            type="smile"
            width="16"
            height="16"
            color={
              activeKey === "2"
                ? theme.colors.blueActive
                : theme.colors.textSecondary
            }
            style={{ marginRight: "8px" }}
          />
          {t("confirmed")} ({confirmedClients.length})
        </SectionHeader>
      ),
      children: (
        <ClientList clients={confirmedClients} isResendModal={isResendModal} />
      )
    }
  ]

  return (
    <StyledModal
      centered
      open={isVisible}
      footer={false}
      width="null"
      onCancel={onClose}
    >
      <Box style={{ marginBottom: confirmation ? 0 : 16 }}>{title}</Box>
      {confirmation && (
        <Box $pl="4px">
          <Box style={{ marginBottom: 16 }}>
            <H5 style={{ fontWeight: 300 }}>{subtitle}</H5>
          </Box>
          <Flexbox $mb="50px" $pt="8px" $alignItems="flex-end">
            <Box>
              <ImgMinature imageId={confirmation.news.imageId} size="large" />
            </Box>
            <Box $pr="20px">
              <H4>{confirmation.news.title}</H4>
              <Box>{formatDate(confirmation.sendDateUtc)}</Box>
            </Box>
            <Box>{confirmation.title}</Box>
          </Flexbox>
        </Box>
      )}

      {header}
      {clients &&
        (!confirmation ? (
          <ClientList
            clients={clients}
            isClientRemovable={isClientRemovable}
            onClientRemove={onClientRemove}
          />
        ) : (
          <Tabs
            items={items}
            onChange={(newActiveKey) => {
              setActiveKey(newActiveKey)
            }}
          />
        ))}
      {footer}
    </StyledModal>
  )
}
