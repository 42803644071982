import { Layout, Divider } from "antd"
import React from "react"
import { useSelector } from "react-redux"
import { styled } from "styled-components"

import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import { selectBottomBarStatus } from "store/layout"
import { MiddleContainer } from "style"
import usePrefix from "utils/usePrefix"

import logo from "../../assets/img/wk-brand.svg"

const { Footer } = Layout

export const PageFooter = () => {
  const t = usePrefix("General")

  const withBottomBar = useSelector(selectBottomBarStatus)

  return (
    <Footer
      style={{ padding: 0, marginTop: withBottomBar ? 95 : 40, zIndex: 10 }}
    >
      {!withBottomBar && (
        <>
          <AppFooter>
            <MiddleContainer>
              <Divider style={{ margin: "25px 0" }} />
              <Box $alignSelf="center" $mb="16px">
                {t("copyright")}
              </Box>
              <Box $alignSelf="center">
                <BrandLogo src={logo} />
              </Box>
            </MiddleContainer>
          </AppFooter>
          <ButtonFooter $withBottomBar={withBottomBar}>
            <MiddleContainer>
              <Flexbox $justifyContent="flex-end">
                <Button
                  href="https://www.wolterskluwer.com/pl-pl/terms-of-use"
                  type="link"
                  target="blank"
                >
                  {t("terms-of-use-btn")}
                </Button>

                <Button
                  href="https://www.wolterskluwer.com/pl-pl/privacy-cookies"
                  type="link"
                  target="blank"
                >
                  {t("privacy-policy-btn")}
                </Button>
              </Flexbox>
            </MiddleContainer>
          </ButtonFooter>
        </>
      )}
      <BottomBarPortalContainer id="bottomBarPortal" />
    </Footer>
  )
}

interface StyledFooterProps {
  $withBottomBar: boolean
}

const AppFooter = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.textSecondary};
  padding: 24px 0;
  font-size: 12px;

  .ant-divider {
    border-color: ${({ theme }) => theme.colors.borderGreyDarker};
  }
`

const ButtonFooter = styled.div<StyledFooterProps>`
  padding: 20px 0;
  background: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ $withBottomBar }) => ($withBottomBar ? 95 : 0)}px;

  .ant-btn {
    color: ${({ theme }) => theme.colors.textPlaceholder};
  }
`

const BottomBarPortalContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
`

const BrandLogo = styled.img`
  height: 35px;
`
