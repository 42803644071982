import { Dispatch } from "redux"

import { Api } from "api"
import { AccountingOffice } from "types/AccountingOffice"
import { ResultModel } from "types/ApiResponse"
import { PromiseAction } from "types/RemoteAction"

export const exampleNewsletterPatchAction =
  (detailsAO: Partial<AccountingOffice>) =>
  (dispatch: Dispatch): PromiseAction<ResultModel<string>> =>
    dispatch({
      type: "exampleNewsletterPatch/FETCH",
      promise: Api.getExampleNewsletterPatch(detailsAO)
    })
