import { styled } from "styled-components"

import { BorderProps, borderProps } from "./borderProps"
import { ColorProps, colorProps } from "./colorProps"
import { flexProps, FlexProps } from "./flexProps"
import { layoutProps, LayoutProps } from "./layoutProps"
import { positionProps, PositionProps } from "./positionProps"
import { spaceProps, SpaceProps } from "./spaceProps"

type Props = SpaceProps &
  FlexProps &
  LayoutProps &
  PositionProps &
  ColorProps &
  BorderProps

const Flexbox = styled.div<Props>`
  display: flex;

  ${flexProps}
  ${spaceProps}
    ${layoutProps}
    ${positionProps}
    ${colorProps}
    ${borderProps}
`

export const Box = styled.div<Props>`
  ${flexProps}
  ${spaceProps}
    ${layoutProps}
    ${positionProps}
    ${colorProps}
    ${borderProps}
`

export default Flexbox
