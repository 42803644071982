import { CSSProperties } from "react"
import { css } from "styled-components"

export interface FlexProps {
  $alignItems?: CSSProperties["alignItems"]
  $alignContent?: CSSProperties["alignContent"]
  $alignSelf?: CSSProperties["alignSelf"]
  $justifyContent?: CSSProperties["justifyContent"]
  $justifyItems?: CSSProperties["justifyItems"]
  $justifySelf?: CSSProperties["justifySelf"]
  $flex?: CSSProperties["flex"]
  $flexBasis?: CSSProperties["flexBasis"]
  $flexDirection?: CSSProperties["flexDirection"]
  $flexFlow?: CSSProperties["flexFlow"]
  $flexGrow?: CSSProperties["flexGrow"]
  $flexShrink?: CSSProperties["flexShrink"]
  $flexWrap?: CSSProperties["flexWrap"]
  $gap?: CSSProperties["gap"]
  $order?: CSSProperties["order"]
}

export const flexProps = css<FlexProps>`
  align-items: ${({ $alignItems }) => $alignItems};
  align-content: ${({ $alignContent }) => $alignContent};
  align-self: ${({ $alignSelf }) => $alignSelf};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  justify-items: ${({ $justifyItems }) => $justifyItems};
  justify-self: ${({ $justifySelf }) => $justifySelf};
  flex: ${({ $flex }) => $flex};
  flex-basis: ${({ $flexBasis }) => $flexBasis};
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  flex-flow: ${({ $flexFlow }) => $flexFlow};
  flex-grow: ${({ $flexGrow }) => $flexGrow};
  flex-shrink: ${({ $flexShrink }) => $flexShrink};
  flex-wrap: ${({ $flexWrap }) => $flexWrap};
  gap: ${({ $gap }) => $gap};
  order: ${({ $order }) => $order};
`
