import { Drawer } from "antd"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import SessionManager from "api/SessionManager"
import { AuthorAvatar } from "components/AuthorAvatar"
import { Button, IconButton } from "components/Button"
import { CustomerServiceSection } from "components/CustomerServiceSection"
import Flexbox, { Box } from "components/Flexbox"
import Icon from "components/Icons"
import { NotificationsModal } from "components/Modal"
import Separator from "components/Separator"
import { theme, Header } from "style"
import { Realm } from "types/Session"
import usePrefix from "utils/usePrefix"

import { UserEmail, UserName } from "./styles"

interface AccountDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const AccountDrawer: React.FC<AccountDrawerProps> = ({
  isOpen,
  onClose
}) => {
  const t = usePrefix("AccountDrawer")
  const navigate = useNavigate()

  const [isNotificationModal, setNotificationModal] = useState(false)

  const userFullName = SessionManager.getUserFullName()
  const userEmail = SessionManager.getUserEmail()
  const isAOPublisher = SessionManager.getIsAOPublisher()
  const isAORole = SessionManager.getAORole()
  const realm = SessionManager.getRealm()

  const logoutBorg = () => {
    onClose()
    SessionManager.logout()
  }

  const redirectToCreator = () => {
    navigate("/creator/contact")
    onClose()
  }

  const redirectToLeadForm = () => {
    navigate("/config/leadregistration")
    onClose()
  }

  // TODO  We give up functionality, but at the customer's request we do not remove the code so that it can be restored in the future -> Jira task - ADT-1268
  // const openTour = () => {
  //   dispatch(setTourType(TourEnum.MAIN_PAGE))
  //   onClose()
  //   navigate("/")
  // }

  const openNotificationModal = () => {
    onClose()
    setNotificationModal(true)
  }

  const onCloseModal = () => {
    setNotificationModal(false)
  }

  const style = {
    border: "none",
    boxShadow: "none",
    gap: "6px"
  }

  return (
    <>
      <Drawer width={400} placement="right" onClose={onClose} open={isOpen}>
        <Flexbox $flexDirection="column" $height="100%">
          <Header
            style={{
              color: theme.colors.textSecondary,
              letterSpacing: "2px",
              marginTop: 12,
              marginBottom: 16
            }}
          >
            {t("account_header")}
          </Header>

          <Flexbox>
            <Box $mr="16px">
              <AuthorAvatar fullName={userFullName} size={48} />
            </Box>
            <Box>
              <Flexbox $justifyContent="space-between" $alignItems="center">
                <UserName>{userFullName}</UserName>

                <Icon
                  type="checkCircleFilled"
                  width="16px"
                  height="16px"
                  color={theme.colors.blueActive}
                  style={{ marginLeft: 4 }}
                />
              </Flexbox>

              <UserEmail>{userEmail}</UserEmail>

              <Button type="default" onClick={logoutBorg}>
                {t("logout")}
              </Button>
            </Box>
          </Flexbox>

          <Separator $fullWidth />

          <Flexbox $flexDirection="column" $ml="-23px">
            {isAOPublisher && (
              <Box $mb="4px">
                <IconButton
                  icon="settings"
                  onClick={redirectToCreator}
                  style={style}
                >
                  {t("settings")}
                </IconButton>
              </Box>
            )}
            {isAORole && (
              <Box $mb="4px">
                <IconButton
                  icon="notificationOn"
                  onClick={openNotificationModal}
                  style={style}
                >
                  {t("notifications")}
                </IconButton>
              </Box>
            )}

            {isAOPublisher && (
              <Box $mb="4px">
                <IconButton
                  icon="receivers"
                  onClick={redirectToLeadForm}
                  style={style}
                >
                  {t("leadForm_config")}
                </IconButton>
              </Box>
            )}

            {isAORole && (
              // <Box $mb="4px">
              //   <IconButton icon="infoRound" onClick={openTour} style={style}>
              //     {t("tutorial")}
              //   </IconButton>
              // </Box>

              <Box $width="max-content">
                <IconButton
                  icon="question"
                  href={faqLinkMap[realm]}
                  target="_blank"
                  data-gtm="gtm-help-centre"
                  style={style}
                >
                  {t("faq")}
                </IconButton>
              </Box>
            )}
          </Flexbox>

          <Flexbox $flex={1} $alignItems="flex-end">
            <CustomerServiceSection layout="vertical" />
          </Flexbox>
        </Flexbox>
      </Drawer>

      {isNotificationModal && <NotificationsModal onClose={onCloseModal} />}
    </>
  )
}

const faqLinkMap: Record<Realm, string> = {
  Accountancy: "https://pomoc.wolterskluwer.pl/tax-alert/",
  Law: "https://pomoc.wolterskluwer.pl/legal-alert/",
  Public: "https://pomoc.wolterskluwer.pl/alert-urzedowy/"
}