import { ButtonProps } from "antd"
import React from "react"
import { styled } from "styled-components"

import { Button } from "./Button"

export const ConfirmButton: React.FC<ButtonProps & { datagtm?: string }> =
  React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return <StyledButton ref={ref} {...props} />
  })

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.greenActive};
  border-color: ${({ theme }) => theme.colors.greenActive};

  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.greenActive};
    border-color: ${({ theme }) => theme.colors.greenActive};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.greenActive};
    border-color: ${({ theme }) => theme.colors.greenActive};
    opacity: 0.8;
  }
`
