import React from "react"

interface ConditionalWrapperProps {
  condition: boolean
  children: React.ReactNode
  unwrappedStyle?: React.CSSProperties
  wrapper: (children: React.ReactNode) => React.ReactElement
}

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  children,
  unwrappedStyle,
  wrapper
}) =>
  condition ? (
    wrapper(children)
  ) : unwrappedStyle ? (
    <div style={unwrappedStyle}>{children}</div>
  ) : (
    (children as React.ReactElement)
  )
