import React from "react"

import { IconProps } from "components/Icons/Icons"

const Question: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M11.9999999,-5.00000006e-08 C5.39999997,-5.00000006e-08 -5.00000006e-08,5.39999997 -5.00000006e-08,11.9999999 C-5.00000006e-08,18.6 5.39999997,23.9999999 11.9999999,23.9999999 C18.6,23.9999999 23.9999999,18.6 23.9999999,11.9999999 C23.9999999,5.39999997 18.6,-5.00000006e-08 11.9999999,-5.00000006e-08 Z M12,22.4 C6.29999998,22.4 1.59999996,17.7 1.59999996,12 C1.59999996,6.29999998 6.29999998,1.59999996 12,1.59999996 C17.7,1.59999996 22.4,6.29999998 22.4,12 C22.4,17.7 17.7,22.4 12,22.4 Z M11.2,19.2 L12.8,19.2 L12.8,16 L11.2,16 L11.2,19.2 Z M12,4.79999997 C9.59999999,4.79999997 7.79999998,6.29999997 7.79999998,8.79999997 L9.39999999,8.79999997 C9.39999999,7.19999998 10.5,6.39999998 12,6.39999998 C13.3,6.39999998 14.4,7.09999998 14.4,7.99999998 C14.4,10.6 11.2,10.1 11.2,14.4 L12.8,14.4 C12.7,11.1 16,11.5 16,7.99999998 C16,6.19999998 14.2,4.79999997 12,4.79999997 Z" />
  </svg>
)
export default Question
