import debounce from "lodash/debounce"
import { useEffect, useRef, useState } from "react"

import SessionManager from "api/SessionManager"

const RefreshToken = () => {
  const [isUserActivity, setIsUserActivity] = useState(false)
  const [lastActivityTimestamp, setLastActivityTimestamp] = useState(Date.now())

  useSessionExpired({
    isUserActivity,
    lastActivityTimestamp,
    setIsUserActivity
  })
  useSessionExtending(setIsUserActivity, setLastActivityTimestamp)

  return null
}

const useSessionExtending = (
  setIsUserActivity: (isUserActivity: boolean) => void,
  setLastActivityTimestamp: (timestamp: number) => void
) => {
  useEffect(() => {
    const debouncedExtendSession = debounce(
      async () => {
        setIsUserActivity(true)
        setLastActivityTimestamp(Date.now())
      },
      SESSION_EXTENSION_INTERVAL,
      {
        leading: true,
        trailing: false,
        maxWait: SESSION_EXTENSION_INTERVAL
      }
    )
    document.addEventListener("click", debouncedExtendSession)
    document.addEventListener("keypress", debouncedExtendSession)

    return () => {
      document.removeEventListener("click", debouncedExtendSession)
      document.removeEventListener("keypress", debouncedExtendSession)
    }
  }, [setIsUserActivity, setLastActivityTimestamp])
}

type SessionExpiringProps = {
  isUserActivity: boolean
  lastActivityTimestamp: number
  setIsUserActivity: (isUserActivity: boolean) => void
}

const useSessionExpired = ({
  isUserActivity,
  lastActivityTimestamp,
  setIsUserActivity
}: SessionExpiringProps) => {
  const tickRef = useRef<NodeJS.Timeout>()
  const sessionEndTimestamp = SessionManager.getSessionEndTimestamp()

  useEffect(() => {
    tickRef.current = setInterval(() => {
      const lastActivityInSec = Math.round(
        (Date.now() - lastActivityTimestamp) / 1000
      )

      const refreshToken = async () => {
        try {
          const res = await SessionManager.refreshToken(lastActivityInSec)
          if (res) {
            setIsUserActivity(false)
          }
        } catch (err) {
          SessionManager.logout()
        }
      }

      if (Date.now() > sessionEndTimestamp - SAFE_TIME_BEFORE_END) {
        if (isUserActivity) {
          refreshToken()
        } else {
          SessionManager.logout()
        }
      }
    }, 1000)

    return () => {
      if (tickRef.current) {
        clearInterval(tickRef.current)
      }
    }
  }, [
    isUserActivity,
    lastActivityTimestamp,
    sessionEndTimestamp,
    setIsUserActivity
  ])
}

const SESSION_EXTENSION_INTERVAL = 1000
const SAFE_TIME_BEFORE_END = 15 * 1000

export default RefreshToken
