import { Checkbox, Tooltip } from "antd"
import React from "react"

import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import Icon from "components/Icons"
import { H4, H5, Text, theme } from "style"
import { formatDate } from "utils/date"
import usePrefix from "utils/usePrefix"

import { ClientReceiverType, SelectClientProps } from "./ClientListModal"
import { ListWrapper, RowWrapper } from "./styles"

interface ClientListProps {
  clients: ClientReceiverType[]
}

export const ClientList: React.FC<ClientListProps & SelectClientProps> = ({
  clients,
  isClientRemovable,
  onClientRemove,
  onSelectClient,
  selectedClients,
  isResendModal,
  ...rest
}) => {
  return (
    <ListWrapper>
      {onSelectClient && isResendModal && !!clients.length && (
        <SelectAllRow {...rest} />
      )}
      {clients.map((client) => (
        <ClientRow
          key={client.id}
          onSelectClient={onSelectClient}
          selectedClients={selectedClients}
          isResendModal={isResendModal}
          isClientRemovable={isClientRemovable}
          onClientRemove={onClientRemove}
          {...client}
        />
      ))}
    </ListWrapper>
  )
}

const ClientRow = ({
  companyName,
  fullName,
  email,
  phoneNumber,
  id,
  onSelectClient,
  selectedClients,
  resendingDateUtc,
  isResendModal,
  isClientRemovable,
  onClientRemove
}: ClientReceiverType &
  Pick<
    SelectClientProps,
    | "onSelectClient"
    | "selectedClients"
    | "isResendModal"
    | "isClientRemovable"
    | "onClientRemove"
  >) => {
  const t = usePrefix("Confirmation")

  return (
    <RowWrapper>
      <Flexbox $alignItems="center">
        {!!isResendModal && !!onSelectClient && id && (
          <Box $mr="16px">
            <Checkbox
              key={id}
              checked={!!id && selectedClients?.includes(id)}
              onChange={() => onSelectClient(id)}
            />
          </Box>
        )}
        <Flexbox
          $width="100%"
          $alignItems="center"
          $justifyContent="space-between"
          $gap="8px"
        >
          <Box $flex={1} $overflow="hidden">
            <H4>{companyName}</H4>
            {!!onSelectClient && !!resendingDateUtc && (
              <H5 color={theme.colors.textSecondary}>
                {t("resend_date")} {formatDate(resendingDateUtc)}
              </H5>
            )}
          </Box>
          <Box $flex={1} $overflow="hidden">
            <Text $color="textSecondary" $type="large">
              {fullName}
            </Text>
          </Box>
          <Box $flex={1} $overflow="hidden" className="contact-data">
            {email}
          </Box>
          <Box $flex={1} $overflow="hidden" className="contact-data">
            {!!phoneNumber && phoneNumber}
          </Box>
          <Flexbox $width="30px" $justifyContent="flex-end">
            {isClientRemovable && id && (
              <Tooltip title={t("delete_tooltip")}>
                <Button
                  type="link"
                  icon={
                    <Icon
                      type="delete"
                      width="16px"
                      height="16px"
                      color={theme.colors.textSecondary}
                    />
                  }
                  onClick={() => onClientRemove?.(id)}
                  style={{ justifyContent: "flex-end" }}
                />
              </Tooltip>
            )}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </RowWrapper>
  )
}

const SelectAllRow = ({
  handleSelectAll,
  isSelectedAll
}: Pick<SelectClientProps, "handleSelectAll" | "isSelectedAll">) => {
  const t = usePrefix("Confirmation")
  return (
    <RowWrapper>
      <Flexbox $alignItems="center">
        <Box>
          {!!handleSelectAll && (
            <Checkbox
              checked={isSelectedAll}
              onChange={() => handleSelectAll()}
            />
          )}
        </Box>
        <Box $ml="16px">
          <H5 color={theme.colors.textSecondary}>{t("select_all")}</H5>
        </Box>
      </Flexbox>
    </RowWrapper>
  )
}
