import { createSelector } from "reselect"

import { initialState } from "./reducer"

import { AppState } from "../AppState"

const getComponentState = (s: AppState) => s.license || initialState

export const selectIsExpiredFetching = createSelector(
  [getComponentState],
  (state) => state.isExpiredFetching
)

export const selectIsExpiredFetched = createSelector(
  [getComponentState],
  (state) => state.isExpiredFetched
)

export const selectIsTestFetching = createSelector(
  [getComponentState],
  (state) => state.isTestFetching
)

export const selectIsAccountDeleting = createSelector(
  [getComponentState],
  (state) => state.isAccountDeleting
)

export const selectIsTestFetched = createSelector(
  [getComponentState],
  (state) => state.isTestFetched
)

export const selectExpiredAccounts = createSelector(
  [getComponentState],
  (state) => state.expiredAccounts
)

export const selectTestAccounts = createSelector(
  [getComponentState],
  (state) => state.testAccounts
)

export const selectTestPagination = createSelector(
  [getComponentState],
  (state) => state.testPagination
)

export const selectExpiredPagination = createSelector(
  [getComponentState],
  (state) => state.expiredPagination
)
