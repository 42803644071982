// eslint-disable-next-line import/no-named-as-default
import produce from "immer"
import { findIndex, pullAt } from "lodash"
import { Reducer } from "redux"

import { LicenseState } from "./LicenseState"
import { LicenseAction } from "./actions"

export const initialState: LicenseState = {
  expiredAccounts: [],
  testAccounts: [],
  expiredPagination: undefined,
  testPagination: undefined,
  isExpiredFetching: false,
  isExpiredFetched: false,
  isTestFetching: false,
  isTestFetched: false,
  isAccountDeleting: false
}

export const licenseReducer: Reducer<LicenseState, LicenseAction> = (
  state = initialState,
  action
): LicenseState =>
  produce(state, (draft) => {
    switch (action.type) {
      case "license/FETCH_EXPIRED_ACCOUNTS":
        draft.isExpiredFetching = true
        break
      case "license/FETCH_EXPIRED_ACCOUNTS_SUCCESS":
        draft.expiredAccounts = action.data
        draft.expiredPagination = action.pagination
        draft.isExpiredFetching = false
        draft.isExpiredFetched = true
        break
      case "license/FETCH_EXPIRED_ACCOUNTS_ERROR":
        draft.isExpiredFetching = false
        draft.expiredAccounts = []
        break
      case "license/FETCH_TEST_ACCOUNTS":
        draft.isTestFetching = true
        break
      case "license/FETCH_TEST_ACCOUNTS_SUCCESS":
        draft.testAccounts = action.data
        draft.testPagination = action.pagination
        draft.isTestFetching = false
        draft.isTestFetched = true
        break
      case "license/FETCH_TEST_ACCOUNTS_ERROR":
        draft.isTestFetching = false
        draft.testAccounts = []
        break
      case "license/REMOVE_ACCOUNT":
        draft.isAccountDeleting = true
        break
      case "license/REMOVE_ACCOUNT_SUCCESS": {
        const expiredIndex = findIndex(
          draft.expiredAccounts,
          (el) => el.borgId === action.borgId
        )
        const testIndex = findIndex(
          draft.testAccounts,
          (el) => el.borgId === action.borgId
        )

        if (expiredIndex >= 0) {
          pullAt(draft.expiredAccounts, expiredIndex)
        }

        if (testIndex >= 0) {
          pullAt(draft.testAccounts, testIndex)
        }

        draft.isAccountDeleting = false
        break
      }
      case "license/REMOVE_ACCOUNT_ERROR":
        draft.isAccountDeleting = false
        break
    }
  })
