import { AppStateKeys } from "store/AppState"

import { Remote } from "./Remote"
import {
  idle,
  add,
  remove,
  attempt,
  fail,
  succeed,
  clear,
  moveItem,
  setValue
} from "./remoteState"

import {
  FAILURE,
  SUCCESS,
  ADD,
  REMOVE,
  RemoteAction,
  CLEAR,
  MOVE_ITEM,
  SET_VALUE
} from "../RemoteAction"

export type RemoteReducer<D> = (
  state: Remote<D> | undefined,
  action: RemoteAction
) => Remote<D>

export const createRemoteReducer =
  (name: AppStateKeys) =>
  (
    state: Remote | undefined = idle(),
    action: RemoteAction<typeof name>
  ): Remote => {
    if (action.type.startsWith(`${name}/`)) {
      switch (action.resolved) {
        case false:
          return attempt(state)
        case FAILURE:
          return fail(action.payload, state)
        case SUCCESS:
          return succeed(action.payload, action.pagination)
        case ADD:
          return add(action.payload, state)
        case REMOVE:
          return remove(action.payload, state)
        case CLEAR:
          return clear()
        case MOVE_ITEM:
          return moveItem(action.payload, state)
        case SET_VALUE:
          return setValue(action.payload, state)
        default:
          return state
      }
    }
    return state
  }
