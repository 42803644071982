import React from "react"

import { IconProps } from "components/Icons/Icons"

const MailCrossed: React.FC<IconProps> = ({
  color,
  width,
  height,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill={color}
    {...props}
  >
    <g stroke="none" strokeWidth="1">
      <g transform="translate(1.000000, 1.000000)">
        <g transform="translate(0.000000, 3.000000)">
          <path d="M-5.00000006e-08,0.19999996 L-5.00000006e-08,17.8 L23.9999999,17.8 L23.9999999,0.19999996 L-5.00000006e-08,0.19999996 Z M14.1,10.1 C12.9,11.2 12.7,11.4 12,11.4 C11.3,11.4 11.1,11.2 9.99999999,10.1 L1.59999996,1.79999997 L22.4,1.79999997 L14.1,10.1 Z M1.59999996,4.09999998 L7.29999998,9.8 L1.59999996,14.5 L1.59999996,4.09999998 Z M1.99999996,16.2 L8.49999996,10.9 L8.79999999,11.2 C9.99999996,12.3 10.6,13 12,13 C13.4,13 14.1,12.3 15.2,11.2 L15.5,10.9 L22,16.2 L1.99999996,16.2 Z M22.4,14.4 L16.7,9.7 L22.4,3.99999998 L22.4,14.4 Z" />
        </g>
        <line
          x1="0.5"
          y1="23.5"
          x2="23.5"
          y2="0.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="square"
        />
      </g>
    </g>
  </svg>
)
export default MailCrossed
