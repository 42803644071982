import { PaginationResponse } from "types/ApiResponse"
import { Submission } from "types/LeadForm"

export const fetchSubmissionsRequest = () =>
  ({
    type: "submission/FETCH_SUBMISSIONS"
  } as const)

export const fetchSubmissionsSuccess = (
  submissions: Submission[],
  pagination?: PaginationResponse,
  canUpdateTotal?: boolean
) =>
  ({
    type: "submission/FETCH_SUBMISSIONS_SUCCESS",
    submissions,
    pagination,
    canUpdateTotal
  } as const)

export const fetchSubmissionsError = (err: any) =>
  ({
    type: "submission/FETCH_SUBMISSIONS_ERROR",
    err
  } as const)

export type SubmissionActions = ReturnType<
  | typeof fetchSubmissionsRequest
  | typeof fetchSubmissionsSuccess
  | typeof fetchSubmissionsError
>
