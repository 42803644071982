import React from "react"

import { IconProps } from "components/Icons/Icons"

const Picker: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 15 15" {...props}>
    <path
      fill="none"
      stroke="#CCCCCC"
      d="M6.8,6c-3.7,3.7-5.5,5.5-5.5,5.5c-0.5,0.9-0.5,1.6,0.1,2.2s1.3,0.6,2.2,0.1l5.5-5.5
	c0.7,0.7,1.1,1.1,1.1,1.1c0.1,0.3,0.5,0.1,1-0.5s0.8-1,0.6-1.1c-0.8-0.8-1.2-1.3-1.2-1.3c2.1-2.1,3.2-3.2,3.2-3.2
	c0.4-0.6,0.3-1.1-0.2-1.7s-1.1-0.7-1.7-0.2C9.7,3.4,8.6,4.5,8.6,4.5L7.3,3.1C7.1,3,6.8,3.3,6.3,3.8s-0.7,0.9-0.6,1L6.8,6z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="#CCCCCC"
      fill={color}
      d="M6.8,5.9l2.3,2.3c0.7,0.7,1.1,1.1,1.1,1.1c0.1,0.3,0.5,0.1,1-0.5c0.6-0.6,0.8-1,0.6-1.1
	c-0.8-0.8-1.2-1.3-1.2-1.3c2.1-2.1,3.2-3.2,3.2-3.2c0.4-0.6,0.3-1.1-0.2-1.7s-1.1-0.7-1.7-0.2c-0.7,0.7-1.1,1.1-1.4,1.4
	C9.2,3.8,8.6,4.4,8.6,4.4L7.3,3.1C7.2,3,6.8,3.2,6.3,3.7s-0.7,0.9-0.6,1L6.8,5.9z"
    />
  </svg>
)
export default Picker
