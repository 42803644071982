import React from "react"

import { IconProps } from "components/Icons/Icons"

const Print: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill={color}
    {...props}
  >
    <path d="M19.2,6.39999998 L19.2,-5.00000006e-08 L4.79999997,-5.00000006e-08 L4.79999997,6.39999998 L-5.00000006e-08,6.39999998 L-5.00000006e-08,20.8 L4.79999997,20.8 L4.79999997,23.9999999 L19.2,23.9999999 L19.2,20.8 L23.9999999,20.8 L23.9999999,6.39999998 L19.2,6.39999998 Z M6.39999998,1.59999996 L17.6,1.59999996 L17.6,6.39999998 L6.39999998,6.39999998 L6.39999998,1.59999996 Z M17.6,22.4 L6.39999998,22.4 L6.39999998,14.4 L17.6,14.4 L17.6,22.4 Z M22.4,19.2 L19.2,19.2 L19.2,12.8 L4.79999997,12.8 L4.79999997,19.2 L1.59999996,19.2 L1.59999996,7.99999998 L22.4,7.99999998 L22.4,19.2 L22.4,19.2 Z M20.8,9.59999999 L17.6,9.59999999 L17.6,11.2 L20.8,11.2 L20.8,9.59999999 Z" />
  </svg>
)
export default Print
