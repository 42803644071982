import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { LeadFormValues } from "types/LeadForm"
import { PromiseAction } from "types/RemoteAction"

export const fetchLeadFormConfigurationContent =
  () =>
  (dispatch: Dispatch): PromiseAction<ResultModel<LeadFormValues>> =>
    dispatch({
      type: "configuration/FETCH",
      promise: Api.getLeadFormConfiguration()
    })
