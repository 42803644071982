import { Modal } from "antd"
import React from "react"
import { useSelector } from "react-redux"

import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import Icon from "components/Icons"
import { selectClientLimit } from "store/layout/layoutSelectors"
import { H2, theme, Paragraph } from "style"
import { ClientDataFile } from "types/Client"
import { getServerErrors, hasConcreteError } from "utils/errors"
import usePrefix from "utils/usePrefix"

import { ClientCount, Wrapper } from "./styles"

interface ImportClientsModalProps {
  isLoading: boolean
  isVisible: boolean
  errors: any
  importedClientsCount: ClientDataFile | null
  onCancel: () => void
  onConfirm: () => void
}

export const ImportClientsModal: React.FC<ImportClientsModalProps> = ({
  isLoading,
  isVisible,
  errors,
  importedClientsCount,
  onCancel,
  onConfirm
}) => {
  const tImport = usePrefix("ImportClient")

  const clientLimit = useSelector(selectClientLimit)

  const clientCount = importedClientsCount
    ? importedClientsCount.greenCount +
      importedClientsCount.yellowCount +
      importedClientsCount.redCount
    : 0

  const hasErrors =
    !!errors ||
    (importedClientsCount && importedClientsCount?.clientCountAboveLimit > 0) ||
    (importedClientsCount &&
      importedClientsCount?.clientDataFileClientLimit > 0)

  const serverErrors = getServerErrors(errors)

  const hasDuplicates = hasConcreteError(errors, "ClientsWithSameEmailAndNip")

  return (
    <Modal
      centered
      open={isVisible}
      footer={false}
      width="700px"
      closable={!!onCancel}
      onCancel={onCancel}
    >
      <Flexbox $justifyContent="center">
        <Wrapper>
          {isLoading && (
            <>
              <Flexbox $justifyContent="center">
                <Icon
                  type="loading"
                  width="50px"
                  height="50px"
                  color={theme.colors.textSecondary}
                />
              </Flexbox>
              <Flexbox $justifyContent="center">
                <Paragraph>{tImport("importLoaderLabel")}</Paragraph>
              </Flexbox>
            </>
          )}

          {hasErrors && (
            <>
              <Flexbox $justifyContent="center">
                <H2 style={{ marginBottom: 32 }}>
                  {tImport("importError")}
                  {hasDuplicates &&
                    ` - ${tImport("importErrorDuplicatesTitle")}`}
                </H2>
              </Flexbox>
              <Box $justifyContent="center">
                {serverErrors.length > 0
                  ? serverErrors.map((err, index) => (
                      <Flexbox $justifyContent="center" key={`err-${index}`}>
                        <Paragraph style={{ textAlign: "center" }}>
                          {err}
                        </Paragraph>
                      </Flexbox>
                    ))
                  : !importedClientsCount?.clientCountAboveLimit && (
                      <Flexbox $justifyContent="center">
                        <Paragraph style={{ textAlign: "center" }}>
                          {tImport("importError3")}
                        </Paragraph>
                      </Flexbox>
                    )}
                <Paragraph style={{ textAlign: "center" }}>
                  {!!importedClientsCount?.clientCountAboveLimit &&
                    tImport("importError2", {
                      count: importedClientsCount.clientCountAboveLimit,
                      limit: clientLimit
                    })}
                </Paragraph>
                <Paragraph style={{ textAlign: "center" }}>
                  {!!importedClientsCount?.clientDataFileClientLimit &&
                    tImport("importError4", {
                      limit: importedClientsCount.clientDataFileClientLimit
                    })}
                </Paragraph>
              </Box>
            </>
          )}
          {!isLoading && !hasErrors && importedClientsCount && (
            <>
              <Flexbox $justifyContent="center">
                <H2 style={{ marginBottom: 32 }}>
                  {tImport("importedCount", { count: clientCount })}
                </H2>
              </Flexbox>

              <Box>
                <Paragraph>{tImport("including")}</Paragraph>
              </Box>

              <Flexbox $justifyContent="space-between">
                <Flexbox
                  $flexDirection="column"
                  $alignItems="center"
                  $justifyContent="center"
                  style={{ minWidth: 160 }}
                >
                  <ClientCount color={theme.colors.greenActive}>
                    {importedClientsCount.greenCount}
                  </ClientCount>
                  <Box $justifyContent="center">
                    <Paragraph>{tImport("withCorrectData")}</Paragraph>
                  </Box>
                </Flexbox>
                <Flexbox
                  $flexDirection="column"
                  $alignItems="center"
                  $justifyContent="center"
                  style={{ minWidth: 160 }}
                >
                  <ClientCount color={theme.colors.orange}>
                    {importedClientsCount.yellowCount}
                  </ClientCount>
                  <Box>
                    <Paragraph>{tImport("withIncompleteData")}</Paragraph>
                  </Box>
                </Flexbox>
                <Flexbox
                  $flexDirection="column"
                  $alignItems="center"
                  $justifyContent="center"
                  style={{ minWidth: 160 }}
                >
                  <ClientCount color={theme.colors.error}>
                    {importedClientsCount.redCount}
                  </ClientCount>
                  <Box>
                    <Paragraph>{tImport("withErrorData")}</Paragraph>
                  </Box>
                </Flexbox>
              </Flexbox>
            </>
          )}
        </Wrapper>
      </Flexbox>
      <Flexbox $justifyContent={isLoading ? "center" : "space-between"}>
        <Box>
          <Button onClick={onCancel} size="large">
            {tImport("cancel")}
          </Button>
        </Box>

        {!isLoading && (
          <Box>
            <Button onClick={onConfirm} size="large" type="primary">
              {hasErrors ? tImport("tryAgain") : tImport("redirectToClients")}
            </Button>
          </Box>
        )}
      </Flexbox>
    </Modal>
  )
}