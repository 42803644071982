import React from "react"

import { IconProps } from "components/Icons/Icons"

const Neutral: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M11.9999999,-5.00000006e-08 C5.39999997,-5.00000006e-08 -5.00000006e-08,5.39999997 -5.00000006e-08,11.9999999 C-5.00000006e-08,18.6 5.39999997,23.9999999 11.9999999,23.9999999 C18.6,23.9999999 23.9999999,18.6 23.9999999,11.9999999 C23.9999999,5.39999997 18.6,-5.00000006e-08 11.9999999,-5.00000006e-08 Z M12,22.4 C6.29999998,22.4 1.59999996,17.7 1.59999996,12 C1.59999996,6.29999998 6.29999998,1.59999996 12,1.59999996 C17.7,1.59999996 22.4,6.29999998 22.4,12 C22.4,17.7 17.7,22.4 12,22.4 Z M8.79999999,11.2 C9.69999998,11.2 10.4,10.5 10.4,9.59999999 C10.4,8.69999999 9.69999998,7.99999998 8.79999999,7.99999998 C7.89999998,7.99999998 7.19999998,8.69999999 7.19999998,9.59999999 C7.19999998,10.5 7.89999998,11.2 8.79999999,11.2 Z M15.2,7.99999998 C14.3,7.99999998 13.6,8.69999999 13.6,9.59999999 C13.6,10.5 14.3,11.2 15.2,11.2 C16.1,11.2 16.8,10.5 16.8,9.59999999 C16.8,8.69999999 16.1,7.99999998 15.2,7.99999998 Z M7.19999998,17.6 L16.8,17.6 L16.8,16 L7.19999998,16 L7.19999998,17.6 Z" />
  </svg>
)
export default Neutral
