import isPlainObject from "lodash/isPlainObject"
import { Action } from "redux"

export interface PromiseAction<T = any> extends Action {
  promise: Promise<T>
}

export const isPromiseAction = (v: any): v is PromiseAction => {
  return isPlainObject(v) && v.promise instanceof Promise
}
