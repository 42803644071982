import React from "react"

import { IconProps } from "components/Icons/Icons"

const News: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M5.59999997,1.59999996 L5.59999997,4.79999997 L0.799999953,4.79999997 L0.799999953,19.2 C0.799999953,21 2.19999996,22.4 3.99999997,22.4 L20,22.4 C21.8,22.4 23.2,21 23.2,19.2 L23.2,1.59999996 L5.59999997,1.59999996 Z M5.59999997,19.2 C5.59999997,20.1 4.89999996,20.8 3.99999997,20.8 C3.09999996,20.8 2.39999996,20.1 2.39999996,19.2 L2.39999996,6.39999998 L5.59999997,6.39999998 L5.59999997,19.2 Z M21.6,19.2 C21.6,20.1 20.9,20.8 20,20.8 L6.79999998,20.8 C7.09999998,20.3 7.19999998,19.8 7.19999998,19.2 L7.19999998,3.19999996 L21.6,3.19999996 L21.6,19.2 Z M20,4.79999997 L8.79999999,4.79999997 L8.79999999,11.2 L20,11.2 L20,4.79999997 Z M18.4,9.59999999 L10.4,9.59999999 L10.4,6.39999998 L18.4,6.39999998 L18.4,9.59999999 Z M20,12.8 L8.79999999,12.8 L8.79999999,14.4 L20,14.4 L20,12.8 Z M20,16 L8.79999999,16 L8.79999999,17.6 L20,17.6 L20,16 Z" />
  </svg>
)
export default News
