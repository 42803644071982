import React from "react"

import { IconProps } from "components/Icons/Icons"

const RightArrow: React.FC<IconProps> = ({
  color,
  width,
  height,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <polygon points="0.799999953 11.2 20.2 11.2 13.3 4.29999997 14.4 3.19999996 23.2 12 14.4 20.8 13.3 19.7 20.2 12.8 0.799999953 12.8" />
  </svg>
)

export default RightArrow
