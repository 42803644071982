import { Layout, Popconfirm } from "antd"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

import { Api } from "api"
import SessionManager from "api/SessionManager"
import { AuthorAvatar } from "components/AuthorAvatar"
import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import Icon from "components/Icons"
import UserNotifications from "components/UserNotifications"
import { RoleEnum, TourEnum } from "enums"
import { clearTourData, selectTestModeStatus, setTourType } from "store/tour"
import { MiddleContainer, theme } from "style"
import { dashboardRouteRewriter } from "types/Role"
import { useExceededClientsLimit } from "utils/useExceededClientsLimitData"
import usePrefix from "utils/usePrefix"

import { AccountDrawer } from "./AccountDrawer"
import { AppNavigation } from "./AppNavigation"
import {
  AppLogo,
  HeaderButton,
  HeaderWrapper,
  LogoImg,
  TestModeBar
} from "./styles"

const { Header } = Layout

export const PageHeader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const t = usePrefix("General")

  const role = SessionManager.getUserRole()
  const isAOrole = SessionManager.getAORole()
  const userFullName = SessionManager.getUserFullName()
  const isTestMode = useSelector(selectTestModeStatus)
  const { exceededClientsLimit, clientCount, clientLimit } =
    useExceededClientsLimit()
  const [isAccountDrawerOpen, setAccountDrawerStatus] = useState(false)

  const toggleAccountDrawer = () => setAccountDrawerStatus(!isAccountDrawerOpen)

  const handleAddNewsletterClick = async () => {
    if (isTestMode) {
      try {
        await Api.moveNextStage()
        dispatch(setTourType(null))
        dispatch(setTourType(TourEnum.SELECT_TEST_NEWS_PAGE))
      } catch (err) {}
    }
  }

  const handleExitTestTour = async () => {
    try {
      await Api.disposeTestDispatch()
      dispatch(clearTourData())
      navigate("/")
    } catch (err) {}
  }

  const commonAORoutes =
    role === "AO_User" || role === "AO_Publisher"
      ? [
          {
            label: t("nav_main_page"),
            route: dashboardRouteRewriter[role],
            icon: <Icon type="home" />,
            datagtm: "gtm-main-nav-bar-main-page"
          },
          {
            label: t("nav_news"),
            route: "/news/ao",
            icon: <Icon type="news" />,
            datagtm: "gtm-main-nav-bar-news",
            datatour: "main-page-step-news-nav"
          },
          {
            label: t("nav_sent_bulletins"),
            route: "/newsletter/sent",
            icon: <Icon type="send" />,
            datagtm: "gtm-main-nav-bar-sent-bulletins",
            datatour: "main-page-step-sent-nav"
          },
          {
            label: t("nav_confirmations"),
            route: "/confirmations",
            icon: <Icon type="mailSent" />,
            datagtm: "gtm-main-nav-bar-confirmations",
            datatour: "main-page-step-confirmations-nav"
          },
          {
            label: clientLimit
              ? `${t("nav_clients")} ${clientCount}/${clientLimit}`
              : t("nav_clients"),
            route: "/client",
            icon: <Icon type="users" />,
            datagtm: "gtm-main-nav-bar-clients",
            datatour: "main-page-step-clients-nav"
          }
        ]
      : []

  const getNavigationProps = () => {
    if (!role) {
      return null
    }

    switch (role) {
      case RoleEnum.AO_User:
        return { routes: commonAORoutes }
      case RoleEnum.AO_Publisher:
        return {
          routes: commonAORoutes,
          ctaBtn: {
            label: t("create_bulletin_btn"),
            route: "/newsletter/add",
            icon: <Icon type="plus" />,
            datagtm: "gtm-main-nav-bar-create-bulletin",
            datatour: "main-page-step-bulletin-cta",
            onClick: handleAddNewsletterClick,
            disabled: exceededClientsLimit
          }
        }
      case RoleEnum.WK_Editor:
      case RoleEnum.WK_Publisher:
        return {
          routes: [
            {
              label: t("nav_main_page"),
              route: dashboardRouteRewriter[role],
              datagtm: "gtm-main-nav-bar-main-page",
              icon: <Icon type="home" />
            },
            {
              label: t("nav_to_publish"),
              route: "/news/toPublish",
              datagtm: "gtm-main-nav-bar-to-publish",
              icon: <Icon type="highlight" />
            },
            {
              label: t("nav_published"),
              route: "/news/published",
              datagtm: "gtm-main-nav-bar-published",
              icon: <Icon type="news" />
            },
            {
              label: "Licencje klientów",
              route: "/license",
              datagtm: "gtm-main-nav-bar-license",
              icon: <Icon type="users" />
            }
          ],
          ctaBtn: {
            label: t("add_news_btn"),
            route: "/news/add",
            datagtm: "gtm-main-nav-bar-add-news",
            icon: <Icon type="plus" />
          }
        }
      default:
        return null
    }
  }

  const navProps = getNavigationProps()

  return (
    <>
      {isTestMode && (
        <TestModeBar>
          <MiddleContainer>
            <Flexbox $alignItems="center" $height="100%">
              <Box $flex={22}>{t("test_mode_app_header")}</Box>
              <Box $flex={2}>
                <Popconfirm
                  title={t("test_mode_tooltip_title")}
                  onConfirm={handleExitTestTour}
                  okText={t("yes")}
                  cancelText={t("no")}
                  placement="bottom"
                  overlayStyle={{ zIndex: 99999999 }}
                >
                  <Button
                    type="text"
                    icon={<Icon type="close" color={theme.colors.white} />}
                  />
                </Popconfirm>
              </Box>
            </Flexbox>
          </MiddleContainer>
        </TestModeBar>
      )}
      <HeaderWrapper $isTestMode={isTestMode}>
        <Header>
          <MiddleContainer>
            <Flexbox $justifyContent="space-between">
              <AppLogo>
                <Link
                  to={
                    role
                      ? dashboardRouteRewriter[role]
                      : dashboardRouteRewriter.UNKNOWN
                  }
                >
                  <LogoImg src="https://update.lex.pl/taxalert/pixel.svg" />
                  {t("app_name")}
                </Link>
              </AppLogo>

              <Flexbox $alignItems="center" $position="relative">
                {isAOrole && <UserNotifications />}
                {role === RoleEnum.WK_Publisher && (
                  <HeaderButton to="/notifications">
                    <Icon
                      type="notificationOn"
                      color={theme.colors.white}
                      width="14px"
                      height="14px"
                    />
                    {t("notifications")}
                  </HeaderButton>
                )}

                <AuthorAvatar
                  fullName={userFullName}
                  style={{
                    background: theme.colors.white,
                    color: theme.colors.blueActive,
                    marginLeft: "18px"
                  }}
                  withBurger
                  onClick={toggleAccountDrawer}
                />
              </Flexbox>
            </Flexbox>
          </MiddleContainer>
        </Header>
      </HeaderWrapper>

      {navProps && <AppNavigation {...navProps} />}

      <AccountDrawer
        isOpen={isAccountDrawerOpen}
        onClose={() => setAccountDrawerStatus(false)}
      />
    </>
  )
}