import { useSelector } from "react-redux"

import { selectAutoNewsletterDelivery } from "store/autoNewsletterDelivery"
import { AutoNewsletterDeliveryValues } from "types/AutoNewsletterDelivery"

export const useInitialValues = (): AutoNewsletterDeliveryValues => {
  const autoNewsletterDeliveryData = useSelector(selectAutoNewsletterDelivery)

  return {
    autoNewsletterDeliveryActive:
      autoNewsletterDeliveryData?.autoNewsletterDeliveryActive || false,
    cycle: autoNewsletterDeliveryData?.cycle ?? "Monthly",
    time: autoNewsletterDeliveryData?.time ?? "8:30:00",
    isDayOfMonth: autoNewsletterDeliveryData?.isDayOfMonth || false,
    weekNumber: autoNewsletterDeliveryData?.weekNumber ?? 1,
    dayOfWeek: autoNewsletterDeliveryData?.dayOfWeek ?? "Monday",
    dayOfMonth: autoNewsletterDeliveryData?.dayOfMonth ?? 1,
    ignoreNews: autoNewsletterDeliveryData?.ignoreNews || false,
    amountDaysToIgnore: autoNewsletterDeliveryData?.amountDaysToIgnore ?? 1,
    daysToNextDelivery: autoNewsletterDeliveryData?.daysToNextDelivery ?? 1
  }
}