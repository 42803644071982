import { Dispatch } from "redux"

import { Api } from "api"
import { AccountingOffice } from "types/AccountingOffice"
import { ResultModel } from "types/ApiResponse"
import { PromiseAction } from "types/RemoteAction"

export const fetchAccountingOfficeAction =
  () =>
  (dispatch: Dispatch): PromiseAction<ResultModel<AccountingOffice>> =>
    dispatch({
      type: "accountingOffice/FETCH",
      promise: Api.fetchAccouningOffice()
    })
