import { createSelector } from "reselect"

import { AppState } from "../../AppState"

export const selectOriginalNewsAOPending = (s: AppState) =>
  s.newsAO.originalNewsAO.pending

export const selectOriginalNewsAOFetchError = (s: AppState) =>
  s.newsAO.originalNewsAO.error

export const selectOriginalNewsAOPagination = (s: AppState) =>
  s.newsAO.originalNewsAO.pagination

export const selectOriginalNewsAOListData = (s: AppState) =>
  s.newsAO.originalNewsAO.data

export const selectOriginalNewsAOList = createSelector(
  selectOriginalNewsAOListData,
  (originalNewsList) => originalNewsList || []
)

export const selectNewsPossibleToSent = createSelector(
  selectOriginalNewsAOList,
  (originalList) => originalList.filter((news) => news.hasClients)
)
