import { Dispatch } from "redux"

import { Api } from "api"
import { PaginationRequest } from "types/ApiResponse"
import { Client, ClientFilters } from "types/Client"
import { showErrorNotification } from "utils/errors"

import {
  fetchClientsError,
  fetchClientsRequest,
  fetchClientsSuccess
} from "./clientActions"

export const fetchClients =
  (pagination: PaginationRequest, filters: ClientFilters) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchClientsRequest())
    try {
      const res = await Api.fetchClients(pagination, filters)
      const canUpdateTotal = filters.clientStatuses.length === 0
      dispatch(fetchClientsSuccess(res.result, res.pagination, canUpdateTotal))
    } catch (err) {
      dispatch(fetchClientsError(err))
      showErrorNotification(
        err,
        "Wystąpił błąd podczas pobierania danych o odbiorcach. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
      )
    }
  }

export const searchClients =
  (phrase: string, pagination: PaginationRequest, filters: ClientFilters) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchClientsRequest())
    try {
      const res = await Api.searchClient(phrase, pagination, filters)
      dispatch(fetchClientsSuccess(res.result, res.pagination))
    } catch (err) {
      dispatch(fetchClientsError(err))
    }
  }

export const saveClient = (
  client: Client,
  isForce?: boolean,
  isForceEmail?: boolean
) => {
  if (client.id) {
    return Api.updateClient(client, isForce, isForceEmail)
  }
  return Api.addClient(client, isForce, isForceEmail)
}
