import React from "react"

import { IconProps } from "components/Icons/Icons"

const Move: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <polygon points="19.2 7.19999998 18.1 8.29999998 21 11.2 12.8 11.2 12.8 3.09999996 15.7 5.99999997 16.8 4.89999997 12 -5.00000006e-08 7.19999998 4.79999997 8.29999998 5.89999997 11.2 2.99999996 11.2 11.1 3.09999996 11.1 5.99999997 8.19999998 4.79999997 7.19999998 -5.00000006e-08 12 4.79999997 16.8 5.89999997 15.7 2.99999996 12.8 11.1 12.8 11.1 21 8.19999998 18.1 7.09999998 19.2 12 24.0000001 16.8 19.2 15.7 18.1 12.8 21 12.8 12.8 21 12.8 18.1 15.7 19.2 16.8 24.0000001 12" />
  </svg>
)
export default Move
