import { combineReducers } from "redux"

import { TemplateState } from "./TemplateReducerState"
import { exampleNewsletterReducer } from "./exampleNewsletter"
import { exampleNewsletterPatchReducer } from "./exampleNewsletterPatch"
import { templatePreviewHtmlReducer } from "./templatePreviewHtml"

export const templateReducer = combineReducers<TemplateState>({
  templatePreviewHtml: templatePreviewHtmlReducer,
  exampleNewsletter: exampleNewsletterReducer,
  exampleNewsletterPatch: exampleNewsletterPatchReducer
})
