import React from "react"

import { IconProps } from "components/Icons/Icons"

const MailSent: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M13.8,0.599999952 C12.7,-0.200000051 11.2,-0.200000051 10.1,0.599999952 L-5.00000006e-08,8.09999998 L-5.00000006e-08,23.9999999 L23.9999999,23.9999999 L23.9999999,8.09999998 L13.8,0.599999952 Z M7.39999998,15.7 L1.59999996,20.6 L1.59999996,10.4 L7.39999998,15.7 Z M11.2,14.5 C11.7,14.1 12.3,14.1 12.8,14.5 L22,22.4 L1.99999996,22.4 L11.2,14.5 Z M16.5,15.6 L22.4,10.3 L22.4,20.6 L16.5,15.6 Z M11.1,1.89999996 C11.6,1.49999996 12.4,1.49999996 12.9,1.89999996 L21.9,8.59999996 L15.3,14.6 L13.8,13.3 C12.7,12.4 11.2,12.4 10.2,13.3 L8.59999999,14.7 L1.99999996,8.59999996 L11.1,1.89999996 Z" />
  </svg>
)
export default MailSent
