import { styled } from "styled-components"

export const SectionDescription = styled.div`
  white-space: pre-line;
  margin-bottom: 12px;
`

export const Wrapper = styled.div`
  .ant-btn {
    padding: 4px 23px 0 0;
  }
`
