import { styled } from "styled-components"

export const UserName = styled.p`
  font-size: 16px;
  margin-bottom: 0;
`

export const UserEmail = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 12px;
`
