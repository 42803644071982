// eslint-disable-next-line import/no-named-as-default
import produce from "immer"
import { Reducer } from "redux"

import { SubmissionState } from "store/submission/SubmissionState"
import { SubmissionActions } from "store/submission/submissionActions"

export const initialState: SubmissionState = {
  data: [],
  pagination: undefined,
  isFetching: false,
  isFetched: false,
  totalSubmissions: 0
}
export const submissionReducer: Reducer<SubmissionState, SubmissionActions> = (
  state = initialState,
  action
): SubmissionState =>
  produce(state, (draft) => {
    switch (action.type) {
      case "submission/FETCH_SUBMISSIONS":
        draft.isFetching = true
        break
      case "submission/FETCH_SUBMISSIONS_SUCCESS":
        draft.data = action.submissions
        draft.pagination = action.pagination
        draft.isFetching = false
        draft.isFetched = true
        if (action.canUpdateTotal) {
          draft.totalSubmissions = action.pagination?.total || 0
        }
        break
      case "submission/FETCH_SUBMISSIONS_ERROR":
        draft.isFetching = false
        break
    }
  })
