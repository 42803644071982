import { createSelector } from "reselect"

import { AppState } from "store/AppState"

export const selectSubmissionListPending = (s: AppState) =>
  s.submission.isFetching

export const selectSubmissionPagination = (s: AppState) =>
  s.submission.pagination

export const selectSubmissionListData = (s: AppState) => s.submission.data

export const selectSubmissionList = createSelector(
  selectSubmissionListData,
  (data) => data || []
)
