import { Dispatch } from "redux"

import { AppState } from "store/AppState"
import { selectAllSelected, setSelectedNews } from "store/newsUI"

export const moveNewNewsAO =
  (movedIndex: number, destIndex: number) =>
  (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch({
      type: "newNewsAO/MOVE_ITEM",
      resolved: "MOVE_ITEM",
      payload: {
        movedIndex,
        destIndex
      }
    })
    const selected = selectAllSelected(getState())
    dispatch(setSelectedNews(selected))
  }
