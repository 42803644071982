import { Dispatch } from "redux"

import { Api } from "api"
import { setClientLimit } from "store/layout/layoutActions"

export const fetchClientLimit = () => async (dispatch: Dispatch) => {
  try {
    const res = await Api.getClientLimit()
    dispatch(setClientLimit(res.result))
  } catch (err) {
    // showErrorNotification(err, "Nie udało się pobrać limitu odbiorców")
  }
}
