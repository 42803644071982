import flatten from "lodash/flatten"
import isEmpty from "lodash/isEmpty"
import isNil from "lodash/isNil"
import isPlainObject from "lodash/isPlainObject"
import trim from "lodash/trim"

import { apiUrl } from "utils/config"
import { showErrorNotification } from "utils/errors"

import SessionManager from "./SessionManager"

type RequestHeaderType = {
  Authorization?: string
  "Content-Type"?: string
}

const getHeaders = (): RequestHeaderType => {
  const token = SessionManager.getSessionTokens()?.token

  return {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` })
  }
}

export const defaults = {
  endpoint: (resource: string) => `${apiUrl}/${resource}`,
  get: () => ({
    method: "GET",
    headers: getHeaders()
  }),
  post: () => ({
    method: "POST",
    headers: getHeaders()
  }),
  put: () => ({
    method: "PUT",
    headers: getHeaders()
  }),
  patch: () => ({
    method: "PATCH",
    headers: getHeaders()
  }),
  delete: () => ({
    method: "DELETE",
    headers: getHeaders()
  }),
  getPdfFile: () => ({
    method: "GET",
    headers: {
      ...getHeaders(),
      Accept: APPLICATION_PDF,
      ["Content-Type"]: APPLICATION_PDF
    }
  })
}

/**
 * Configure requests to API with proper headers, body format etc.
 */
export const request = {
  get: (resource: string, params?: RequestInit) =>
    send(defaults.endpoint(resource), {
      ...defaults.get(),
      ...params
    }),
  post: (resource: string, payload: any, params?: RequestInit) =>
    send(defaults.endpoint(resource), {
      ...defaults.post(),
      body: JSON.stringify(payload),
      ...params
    }),
  put: (resource: string, payload: any, params?: RequestInit) =>
    send(defaults.endpoint(resource), {
      ...defaults.put(),
      body: JSON.stringify(payload),
      ...params
    }),
  patch: (resource: string, payload?: any, params?: RequestInit) =>
    send(defaults.endpoint(resource), {
      ...defaults.patch(),
      body: JSON.stringify(payload),
      ...params
    }),
  delete: (resource: string, payload?: any, params?: RequestInit) =>
    send(defaults.endpoint(resource), {
      ...defaults.delete(),
      body: JSON.stringify(payload),
      ...params
    })
}

/**
 * Send request to API
 * Make sure all error cases are handled
 */
export const send = async (
  input: RequestInfo,
  init?: RequestInit,
  retry?: boolean
): Promise<any> => {
  try {
    const res = await fetch(input, init)

    if (!res.ok) {
      // We received response from the server, but there was some error
      // Some HTTP errors don't come with a JSON payload
      // Make sure we don't attempt to parse them as JSON

      switch (res.status) {
        case 401:
          if (!retry) {
            try {
              const resToken = await SessionManager.refreshToken(0)
              const newHeaders = {
                ...init,
                headers: {
                  ...init?.headers,
                  Authorization: `Bearer ${resToken?.token}`
                }
              }

              return send(input, newHeaders, true)
            } catch (err) {
              SessionManager.logout()
            }
          } else {
            SessionManager.redirectToLogin()
          }
          break
        case 403:
          const data = await res?.json?.().catch(() => "")

          if (data?.errors) {
            showErrorNotification(
              data,
              "Nie masz wymaganych uprawnień do tej akcji!"
            )
          } else {
            return Promise.reject({
              errors: ["NoPrivilige"]
            })
          }
          break
        case 404:
        case 405:

        default:
          throw await res.json()
      }
    }

    return resolveResponse(res)
  } catch (err: any) {
    // Abort Error
    if (err.name === "AbortError") {
      return Promise.reject({
        message: "AbortError"
      })
    }
    // Network error
    if (err instanceof Error) {
      return Promise.reject({
        name: err.name,
        message: err.message
      })
    }
    // Server error
    return Promise.reject(err)
  }
}

const resolveResponse = (res: Response) => {
  const contentType = res.headers.get("Content-Type")
  // Make sure we're not trying to parse empty responses as JSON
  if (res.status === 204) {
    return res.text()
  }

  if (
    contentType?.includes(APPLICATION_PDF) ||
    contentType?.includes(
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
  ) {
    return res
  }

  if (contentType?.includes("text/plain")) {
    return res.text()
  }

  return res.json()
}

export const serialize = (queryParams?: { [key: string]: any }): string => {
  const queryString = Object.entries(queryParams || {}).reduce(
    (curr, [key, value]) => {
      if (isEmptyValue(value)) {
        return curr
      }
      if (isPlainObject(value)) {
        if (Object.keys(value).length <= 1) {
          value = flatten(Object.entries(value)).join(":")
        }
        if (isPlainObject(value)) {
          const flattenValues = flatten(Object.entries(value))
          value = flattenValues
            .map((item, index) =>
              index % 2
                ? flattenValues.length - 1 === index
                  ? item
                  : `${item},`
                : `${item}:`
            )
            .join("")
        }
      }

      return `${curr}&${key}=${encodeURI(value)}`
    },
    ""
  )

  return queryString && `?${trim(queryString, "&")}`
}

const APPLICATION_PDF = "application/pdf"

export const isEmptyValue = (v: any): boolean => {
  if (typeof v === "string") {
    return trim(v) === ""
  } else if (typeof v === "object" && isEmpty(v)) {
    return true
  }
  return isNil(v)
}
