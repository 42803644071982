import { createSelector } from "reselect"

import { AppState } from "store/AppState"

export const selectLeadFormLinksPending = (s: AppState) =>
  s.leadForm.links.pending

export const selectLeadFormLinksData = (s: AppState) => s.leadForm.links.data

export const selectLeadFormLinks = createSelector(
  selectLeadFormLinksData,
  (data) => data
)
