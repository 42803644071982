import { BackendModule } from "i18next"

import { Api } from "api"
import { Language, resources } from "translations"

class TranslationFetcher implements BackendModule {
  static type = "backend"

  // Silences type error cause by wrong typing of BackendModule
  // which defines the |type| property as non-static.
  type = "backend" as const

  constructor() {
    this.init()
  }

  init() {
    // Validate backendOptions
  }

  async read(language: Language, namespace: any, callback: any) {
    try {
      const response = await Api.getTranslations()

      callback(null, JSON.parse(response as string))
    } catch (error) {
      console.error(
        `Error loading translations, language: ${language}, namespace: ${namespace}`,
        error
      )
      callback(null, resources.pl.translation)
    }
  }

  create(languages: Language[], ns: any, key: string) {
    console.error(`Missing ${languages.join()} translation key: ${ns}:${key}`)
  }
}

export default TranslationFetcher
