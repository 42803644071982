import i18n from "i18next"
import intervalPlural from "i18next-intervalplural-postprocessor"
import { initReactI18next } from "react-i18next"

import TranslationFetcher from "./TranslationFetcher"
import common from "./pl/common.json"

const defaultLanguage: Language = "pl"

export type Language = "pl" | "en"

export const resources = {
  pl: {
    translation: {
      ...common
    }
  }
}

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(TranslationFetcher)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    lng: defaultLanguage,
    ns: ["translation"],
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
