export const loadLocalState = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) as string) || undefined
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export const saveLocalState = (key: string, value: any) => {
  if (!key) {
    return
  }
  localStorage.setItem(key, JSON.stringify(value))
}

export const removeLocalState = (key: string) => {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key)
  }
}
