import { styled } from "styled-components"

interface DnDWrapperProps {
  $isDragging: boolean
}

export const DnDWrapper = styled.div<DnDWrapperProps>`
  opacity: ${({ $isDragging }) => ($isDragging ? 0 : 1)};
  cursor: grab;
`
