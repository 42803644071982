import { CSSProperties } from "react"
import { css } from "styled-components"

export interface PositionProps {
  $position?: CSSProperties["position"]
  $zIndex?: CSSProperties["zIndex"]
  $top?: CSSProperties["top"]
  $right?: CSSProperties["right"]
  $bottom?: CSSProperties["bottom"]
  $left?: CSSProperties["left"]
}

export const positionProps = css<PositionProps>`
  position: ${({ $position }) => $position};
  z-index: ${({ $zIndex }) => $zIndex};
  top: ${({ $top }) => $top};
  right: ${({ $right }) => $right};
  bottom: ${({ $bottom }) => $bottom};
  left: ${({ $left }) => $left};
`
