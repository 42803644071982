import { Dispatch } from "redux"

import { Api } from "api"
import { AppState } from "store/AppState"
import { showErrorNotification } from "utils/errors"

import { selectIsFetchingById } from "./thumbnailSelectors"

export const fetchThumbnail =
  (imageId?: string | null) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    if (!imageId) return

    const isFetching = selectIsFetchingById(getState(), imageId)

    if (isFetching) {
      return
    }

    try {
      dispatch(fetchThumbnailRequest(imageId))
      const base64Image = await Api.getThumbnail(imageId)

      dispatch(fetchThumbnailSuccess(imageId, base64Image))
    } catch (err) {
      dispatch(fetchThumbnailError(imageId))
      showErrorNotification(err, "Nie można pobrać obrazka!")
    }
  }

const fetchThumbnailRequest = (imageId: string) =>
  ({
    type: "thumbnail/FETCH_THUMBNAIL_REQUEST",
    imageId
  } as const)

const fetchThumbnailSuccess = (imageId: string, base64Image: string) =>
  ({
    type: "thumbnail/FETCH_THUMBNAIL_SUCCESS",
    imageId,
    base64Image
  } as const)

const fetchThumbnailError = (imageId: string) =>
  ({
    type: "thumbnail/FETCH_THUMBNAIL_ERROR",
    imageId
  } as const)

export const clearThumbnailState = () =>
  ({
    type: "thumbnail/CLEAR_THUMBNAIL_STATE"
  } as const)

export type ThumbnailAction = ReturnType<
  | typeof fetchThumbnailRequest
  | typeof fetchThumbnailSuccess
  | typeof fetchThumbnailError
  | typeof clearThumbnailState
>
