import debounce from "lodash/fp/debounce"
import { useEffect, useRef, useState } from "react"

const breakpoints = {
  mobile: 0,
  tablet: 768,
  desktop: 1360
}

type BreakpointName = keyof typeof breakpoints

/**
 * Generate media query for the specified breakpoints.
 *
 * @param breakpointList List of one or more breakpoints separated by a space
 */
export function useBreakpoints(breakpointList: BreakpointName) {
  const rules = parseBreakpointList(breakpointList)
    .map(getBreakpointMediaQueryRules)
    .join()

  return useMediaQuery(rules)
}

const useMediaQuery = (query: string) => {
  const mediaQueryList = useRef(window.matchMedia(query))

  const [matches, setMatches] = useState(mediaQueryList.current.matches)

  useEffect(() => {
    const onChange = debounce(200, (event: MediaQueryListEvent) =>
      setMatches(event.matches)
    )
    const currentList = mediaQueryList.current

    if ("addEventListener" in currentList) {
      currentList.addEventListener("change", onChange)

      return () => {
        currentList.removeEventListener("change", onChange)
        onChange.flush()
      }
    }

    // Safari < 14 doesn't support "addEventListener" on MediaQueryList objects
    // Instead it uses deprecated "addListener" method
    const nonStandardList = currentList as any

    if ("addListener" in nonStandardList) {
      nonStandardList.addListener(onChange)

      return () => {
        nonStandardList.removeListener(onChange)
        onChange.flush()
      }
    }

    return () => onChange.flush()
  })

  return matches
}

function parseBreakpointList(breakpointList: string) {
  const breakpointNames = breakpointList.split(" ").map((str) => str.trim())
  assertCorrectBreakpointNames(breakpointNames)

  return breakpointNames
}

function assertCorrectBreakpointNames(
  names: string[]
): asserts names is BreakpointName[] {
  names.forEach((name) => {
    if (!(name in breakpoints)) {
      throw new Error(`Invalid breakpoint name: ${name}.`)
    }
  })
}

function getBreakpointMediaQueryRules(name: BreakpointName) {
  const [start, end] = getBreakpointRange(name)

  let rule = `(min-width: ${start}px)`

  if (end) {
    rule += ` and (max-width: ${end - 1}px)`
  }

  return rule
}

const breakpointValues = Object.values(breakpoints)

function getBreakpointRange(name: BreakpointName) {
  const value = breakpoints[name]

  const index = breakpointValues.indexOf(value)
  const nextValue = breakpointValues[index + 1]

  return [value, nextValue ?? nextValue - 1]
}
