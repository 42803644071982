import React from "react"

import { IconProps } from "components/Icons/Icons"

const Tablet: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill={color}
    {...props}
  >
    <path d="M24,0 L8,0 C5.78666667,0 4,1.78666667 4,4 L4,28 C4,30.2133333 5.78666667,32 8,32 L24,32 C26.2133333,32 28,30.2133333 28,28 L28,4 C28,1.78666667 26.2133333,0 24,0 Z M18.6666667,29.3333333 L13.3333333,29.3333333 L13.3333333,28 L18.6666667,28 L18.6666667,29.3333333 Z M25.6666667,25.3333333 L6.33333333,25.3333333 L6.33333333,4 L25.6666667,4 L25.6666667,25.3333333 Z" />
  </svg>
)
export default Tablet
