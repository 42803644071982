import React from "react"

import { IconProps } from "components/Icons/Icons"

const Archive: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M4.49999997,18.1 L5.59999997,19.2 L10.4,14.4 L5.59999997,9.59999999 L4.49999997,10.7 L7.39999998,13.6 L0.799999953,13.6 L0.799999953,15.2 L7.39999998,15.2 L4.49999997,18.1 Z M12,3.19999996 L10.4,1.59999996 L0.799999953,1.59999996 L0.799999953,12 L2.39999996,12 L2.39999996,7.99999998 L21.6,7.99999998 L21.6,20.8 L2.39999996,20.8 L2.39999996,16.8 L0.799999953,16.8 L0.799999953,22.4 L23.2,22.4 L23.2,3.19999996 L12,3.19999996 Z M21.6,6.39999998 L2.39999996,6.39999998 L2.39999996,3.19999996 L9.69999999,3.19999996 L11.3,4.79999997 L21.6,4.79999997 L21.6,6.39999998 Z" />
  </svg>
)
export default Archive
