import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Tour, { ReactourStep } from "reactour"

import SessionManager from "api/SessionManager"
import { TourEnum } from "enums"
import {
  clearTourData,
  openTour,
  selectTestModeStatus,
  selectTourOpenStatus,
  selectTourType
} from "store/tour"
import { checkElement } from "utils/checkElement"
import usePrefix, { TransFunc } from "utils/usePrefix"

import {
  addTestClientSteps,
  mainPageSteps,
  selectTestNewsPageSteps,
  sendTestNewsPageSteps,
  testMainPageSteps
} from "./steps"

const tourStepsMap: Record<
  NonNullable<TourEnum>,
  (t: TransFunc) => ReactourStep[]
> = {
  ADD_TEST_CLIENT: addTestClientSteps,
  MAIN_PAGE: mainPageSteps,
  TEST_MAIN_PAGE: testMainPageSteps,
  SELECT_TEST_NEWS_PAGE: selectTestNewsPageSteps,
  SEND_TEST_NEWS_PAGE: sendTestNewsPageSteps
}

const tourRouteMap: Record<NonNullable<TourEnum>, string> = {
  ADD_TEST_CLIENT: "/creator/client",
  MAIN_PAGE: "/",
  TEST_MAIN_PAGE: "/",
  SELECT_TEST_NEWS_PAGE: "/newsletter/add",
  SEND_TEST_NEWS_PAGE: "/newsletter/add"
}

const tourArr: TourEnum[] = [
  TourEnum.ADD_TEST_CLIENT,
  TourEnum.MAIN_PAGE,
  TourEnum.TEST_MAIN_PAGE,
  TourEnum.SELECT_TEST_NEWS_PAGE,
  TourEnum.SEND_TEST_NEWS_PAGE
]

export const AppTour = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const t = usePrefix("TestTour")

  const isTestMode = useSelector(selectTestModeStatus)
  const isOpen = useSelector(selectTourOpenStatus)
  const tourType = useSelector(selectTourType)
  const isAOUser = SessionManager.getAORole()

  const tourSteps =
    tourType && tourArr.includes(tourType) ? tourStepsMap[tourType](t) : []

  useEffect(() => {
    if (tourType && isAOUser) {
      window.scrollTo(0, 0)
      navigate(tourRouteMap[tourType])
      tourSteps[0]?.selector &&
        checkElement(tourSteps[0].selector).then(() => {
          dispatch(openTour())
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourType])

  const disableBody = (target: HTMLDivElement) => disableBodyScroll(target)

  const enableBody = (target: HTMLDivElement) => enableBodyScroll(target)

  const handleCloseTour = () => {
    dispatch(clearTourData())
  }

  return (
    <Tour
      badgeContent={(curr, total) => `${curr}/${total}`}
      disableKeyboardNavigation={["esc"]}
      disableFocusLock
      isOpen={isOpen}
      startAt={0}
      steps={tourSteps}
      inViewThreshold={100}
      closeWithMask={false}
      showButtons={false}
      showCloseButton={!isTestMode}
      showNavigation={false}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      onRequestClose={handleCloseTour}
    />
  )
}