import { Tooltip } from "antd"
import { Checkbox } from "formik-antd"
import * as React from "react"

import Flexbox, { Box } from "components/Flexbox"
import Icon from "components/Icons"
import Select from "components/Inputs/Select"
import { FieldValueUpdate } from "components/Modal/AutoNewsletterDelivery/AutoNewsletterDeliveryForm"
import { SelectWrapper } from "components/Modal/AutoNewsletterDelivery/styles"
import { theme } from "style"
import {
  AutoNewsletterDeliveryValues,
  options
} from "types/AutoNewsletterDelivery"
import usePrefix from "utils/usePrefix"

type IgnoreNewsConfigProps = {
  values: AutoNewsletterDeliveryValues
  onChange: FieldValueUpdate
}

export const IgnoreNewsConfig = ({
  values,
  onChange
}: IgnoreNewsConfigProps) => {
  const t = usePrefix("AutoDelivery")

  const { ignoreNews, amountDaysToIgnore } = values

  return (
    <Flexbox $backgroundColor={theme.colors.bgGrey} $padding="16px">
      <Box $margin="auto" $paddingRight="16px">
        <Checkbox
          name="ignoreNews"
          checked={ignoreNews}
          onChange={(e) => onChange("ignoreNews", e.target.checked)}
        />
      </Box>
      <Box
        color={theme.colors.textSecondary}
        style={{ lineHeight: "24px", fontSize: "14px" }}
      >
        <Box $display="inline">{t("ignoreNewsConfig")}</Box>
        <SelectWrapper
          style={{ width: "80px", display: "inline-block", margin: "0 6px" }}
        >
          <Select
            showArrow
            showSearch={false}
            onChange={(value) => onChange("amountDaysToIgnore", value)}
            value={amountDaysToIgnore}
            options={options.daysToIgnore.map((toIgnore) => ({
              value: toIgnore,
              label: toIgnore
            }))}
          />
        </SelectWrapper>
        <Box $display="inline">
          {t("ignoreNewsContConfig", {
            postProcess: "interval",
            count: amountDaysToIgnore
          })}
        </Box>
        <Tooltip title={t("tooltipSkip2Days")}>
          <Box $display="inline" $marginLeft="5px">
            <Icon
              type="infoRound"
              color={theme.colors.textSecondary}
              width="16px"
              height="16px"
              style={{ transform: "translateY(4px)" }}
            />
          </Box>
        </Tooltip>
      </Box>
    </Flexbox>
  )
}