import { Menu } from "antd"
import { MenuProps } from "antd/lib/menu"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, matchPath, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import { ConfirmButton } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import { selectNavBarStatus } from "store/layout"
import { toggleNavBar } from "store/layout/layoutActions"
import { device, MiddleContainer } from "style"
import { useMedia } from "utils/hooks"

interface AppNavigationProps {
  routes: {
    label: string
    route: string
    icon: JSX.Element
    datagtm: string
    datatour?: string
  }[]
  ctaBtn?: {
    label: string
    route: string
    icon: JSX.Element
    datagtm: string
    datatour?: string
    onClick?: (e: any) => void
    disabled?: boolean
  }
}

const withoutNavRoutes = [
  "/account",
  "/creator",
  "/news/add",
  "/news/edit",
  "/newsletter/add",
  "/notifications"
]

export const AppNavigation = ({ routes, ctaBtn }: AppNavigationProps) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const withNavBar = useSelector(selectNavBarStatus)
  const [selectedKeys, setMenuKeys] = useState<string[]>([])

  const currentDevice = useMedia(
    [device.tablet, device.mobileL, device.mobileS],
    ["desktop", "tablet", "mobile"],
    "desktop"
  )

  useEffect(() => {
    !withoutNavRoutes.some((route) => !!matchPath({ path: pathname }, route))
      ? dispatch(toggleNavBar(true))
      : dispatch(toggleNavBar(false))
  }, [dispatch, pathname])

  const currentSelected =
    !!selectedKeys.length &&
    !!matchPath({ path: location.pathname }, selectedKeys[0])

  useEffect(() => {
    if (!currentSelected) {
      let navRouteFound = false
      routes.forEach(({ route }) => {
        if (matchPath({ path: pathname }, route)) {
          setMenuKeys([route])
          navRouteFound = true
        }
      })
      !navRouteFound && setMenuKeys([])
    }
  }, [currentSelected, pathname, routes])

  if (!withNavBar) {
    return null
  }
  const items: MenuProps["items"] = [
    ...routes.map(({ route, icon, label, datagtm, datatour }) => {
      return {
        key: route,
        label:
          currentDevice === "desktop" ? <Link to={route}>{label}</Link> : null,
        icon:
          currentDevice === "desktop" ? icon : <Link to={route}>{icon}</Link>,
        datagtm,
        datatour
      }
    })
  ]

  const renderCtaBtn = ctaBtn
    ? {
        key: ctaBtn.route,
        icon: <Link to={ctaBtn.route}>{ctaBtn.icon}</Link>,
        datagtm: ctaBtn.datagtm,
        datatour: ctaBtn.datatour,
        disabled: ctaBtn.disabled
      }
    : null

  return (
    <StyledNavigation>
      <MiddleContainer data-tour="main-page-step-nav">
        <Flexbox
          $alignItems="center"
          $justifyContent="space-between"
          $height="100%"
        >
          <Box $height="100%" $width="100%">
            <StyledMenu
              mode="horizontal"
              selectedKeys={selectedKeys}
              onClick={(params) => setMenuKeys([params.key])}
              items={[
                ...items,
                currentDevice !== "desktop" && renderCtaBtn
                  ? renderCtaBtn
                  : null
              ]}
            />
          </Box>
          {currentDevice === "desktop" && ctaBtn && (
            <ConfirmButton
              datagtm={ctaBtn.datagtm}
              size="large"
              data-tour={ctaBtn.datatour}
              disabled={ctaBtn.disabled}
            >
              <Link to={ctaBtn.route} onClick={ctaBtn.onClick}>
                {ctaBtn.label}
              </Link>
            </ConfirmButton>
          )}
        </Flexbox>
      </MiddleContainer>
    </StyledNavigation>
  )
}

const StyledNavigation = styled.nav`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGreyDarker};
  height: 80px;
`

const StyledMenu = styled(Menu)`
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: center;
  height: 100%;
  justify-content: space-between;
  background: unset;
  border: none;

  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopM},
    ${device.laptopL} {
    justify-content: flex-start;
  }

  & .ant-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;

    min-width: unset;
    height: 100%;
    margin: 0;
    line-height: 2rem;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0;
    padding-bottom: 8px;

    & span {
      margin-left: 0;
    }

    & svg {
      fill: ${({ theme }) => theme.colors.textSecondary};
    }

    @media ${device.tablet} {
      font-size: 0.8rem;
      min-width: 110px;
    }
    @media ${device.laptop} {
      min-width: 150px;
    }
    @media ${device.laptopM} {
      font-size: 1.2rem;
      min-width: 170px;
    }
    @media ${device.laptopL} {
      min-width: 190px;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      transition: all 0.2s ease;
    }
  }

  &.ant-menu-item .anticon {
    display: block;
    margin: 0;
    padding-bottom: 5px;
  }

  &.ant-menu-item .anticon + span {
    line-height: 1rem;
  }

  &.ant-menu-item-selected .anticon + span,
  &.ant-menu-item-active .anticon + span,
  &.ant-menu-item:hover .anticon + span {
    font-weight: 400;
  }

  & .ant-menu-item-selected,
  & .ant-menu-item-active,
  & .ant-menu-item:hover {
    border: none;

    & svg {
      fill: ${({ theme }) => theme.colors.blueActive};
    }

    &:after {
      transform: scaleX(1);
      border-top: 2px solid ${({ theme }) => theme.colors.blueActive};
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
`