import { Reducer } from "redux"

import { NewsUIState } from "./NewsUIState"
import { NewsUIAction } from "./newsUIActions"

export const initialState: NewsUIState = {
  selected: [],
  newsConfirmationId: null
}

export const newsUIReducer: Reducer<NewsUIState, NewsUIAction> = (
  state = initialState,
  action
): NewsUIState => {
  switch (action.type) {
    case "newsUI/SET_SELECTED_NEWS":
      return {
        ...state,
        selected: [...action.selectedNews]
      }
    case "newsUI/SET_NEWS_CONFIRMATION":
      return {
        ...state,
        newsConfirmationId: action.newsId,
        selected: action.newsId ? [action.newsId] : state.selected
      }

    case "newsUI/CLEAR_NEWS_UI_DATA":
      return initialState
    default:
      return state
  }
}
