import { styled } from "styled-components"

import Flexbox from "components/Flexbox"

export const FlexboxWithTooltip = styled(Flexbox)`
  font-weight: 500;

  &:hover {
    opacity: 0.8;
  }
`
