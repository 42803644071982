import React from "react"

import Angry from "assets/icons/Angry"
import Archive from "assets/icons/Archive"
import ArrowDropdown from "assets/icons/ArrowDropdown"
import AutoSent from "assets/icons/AutoSent"
import BookPage from "assets/icons/BookPage"
import Call from "assets/icons/Call"
import Chat from "assets/icons/Chat"
import Check from "assets/icons/Check"
import CheckCircle from "assets/icons/CheckCircle"
import CheckCircleFilled from "assets/icons/CheckCircleFilled"
import Clipboard from "assets/icons/Clipboard"
import Close from "assets/icons/Close"
import Delete from "assets/icons/Delete"
import DownArrow from "assets/icons/DownArrow"
import DownOutlined from "assets/icons/DownOutlined"
import Dropper from "assets/icons/Dropper"
import Edit from "assets/icons/Edit"
import EmptyBox from "assets/icons/EmptyBox"
import Excel from "assets/icons/Excel"
import Eye from "assets/icons/Eye"
import Facebook from "assets/icons/Facebook"
import FilePdf from "assets/icons/FilePdf"
import Files from "assets/icons/Files"
import Filter from "assets/icons/Filter"
import Fire from "assets/icons/Fire"
import Globe from "assets/icons/Globe"
import GoBack from "assets/icons/GoBack"
import GoTo from "assets/icons/GoTo"
import Highlight from "assets/icons/Highlight"
import History from "assets/icons/History"
import Home from "assets/icons/Home"
import InfoRound from "assets/icons/InfoRound"
import Laptop from "assets/icons/Laptop"
import LeftArrow from "assets/icons/LeftArrow"
import Linkedin from "assets/icons/Linkedin"
import Loading from "assets/icons/Loading"
import Logout from "assets/icons/Logout"
import Mail from "assets/icons/Mail"
import MailCrossed from "assets/icons/MailCrossed"
import MailSent from "assets/icons/MailSent"
import Menu from "assets/icons/Menu"
import Moon from "assets/icons/Moon"
import Move from "assets/icons/Move"
import Neutral from "assets/icons/Neutral"
import News from "assets/icons/News"
import NoFile from "assets/icons/NoFile"
import NotificationOff from "assets/icons/NotificationOff"
import NotificationOn from "assets/icons/NotificationOn"
import Phone from "assets/icons/Phone"
import Picker from "assets/icons/Picker"
import Plus from "assets/icons/Plus"
import Print from "assets/icons/Print"
import Question from "assets/icons/Question"
import Receivers from "assets/icons/Receivers"
import Refresh from "assets/icons/Refresh"
import Return from "assets/icons/Return"
import RightArrow from "assets/icons/RightArrow"
import Send from "assets/icons/Send"
import Settings from "assets/icons/Settings"
import Skype from "assets/icons/Skype"
import Smile from "assets/icons/Smile"
import Stop from "assets/icons/Stop"
import Tablet from "assets/icons/Tablet"
import Tag from "assets/icons/Tag"
import Twitter from "assets/icons/Twitter"
import UpOutlined from "assets/icons/UpOutlined"
import UploadCloud from "assets/icons/UploadCloud"
import User from "assets/icons/User"
import Users from "assets/icons/Users"
import Warning from "assets/icons/Warning"
import { theme } from "style"

import { IconProps } from "./Icons"

export type IconName =
  | "angry"
  | "archive"
  | "arrowDropdown"
  | "autoSent"
  | "bookPage"
  | "call"
  | "chat"
  | "check"
  | "checkCircle"
  | "checkCircleFilled"
  | "close"
  | "clipboard"
  | "delete"
  | "desktop"
  | "downArrow"
  | "downOutlined"
  | "dropper"
  | "edit"
  | "emptyBox"
  | "excel"
  | "eye"
  | "facebook"
  | "filePdf"
  | "files"
  | "filter"
  | "fire"
  | "globe"
  | "goBack"
  | "goTo"
  | "highlight"
  | "history"
  | "home"
  | "infoRound"
  | "leftArrow"
  | "linkedin"
  | "loading"
  | "logout"
  | "mail"
  | "mailCrossed"
  | "mailSent"
  | "menu"
  | "mobile"
  | "moon"
  | "move"
  | "neutral"
  | "news"
  | "noFile"
  | "notificationOff"
  | "notificationOn"
  | "picker"
  | "plus"
  | "print"
  | "question"
  | "receivers"
  | "refresh"
  | "return"
  | "rightArrow"
  | "send"
  | "settings"
  | "skype"
  | "smile"
  | "stop"
  | "tablet"
  | "tag"
  | "twitter"
  | "upOutlined"
  | "uploadCloud"
  | "user"
  | "users"
  | "warning"

interface Props {
  type: IconName
  onClick?: (event: React.MouseEvent<SVGElement>) => void
  disabled?: boolean
}

const ICON_SIZE = "22"

const Icon: React.FC<Props & Partial<IconProps>> = ({
  type,
  color = theme.colors.black,
  width = ICON_SIZE,
  height = ICON_SIZE,
  disabled = false,
  ...props
}) => {
  const params = { width, height, color, disabled }

  return {
    angry: <Angry {...params} {...props} />,
    arrowDropdown: <ArrowDropdown {...params} {...props} />,
    archive: <Archive {...params} {...props} />,
    autoSent: <AutoSent {...params} {...props} />,
    bookPage: <BookPage {...params} {...props} />,
    call: <Call {...params} {...props} />,
    chat: <Chat {...params} {...props} />,
    check: <Check {...params} {...props} />,
    checkCircle: <CheckCircle {...params} {...props} />,
    checkCircleFilled: <CheckCircleFilled {...params} {...props} />,
    close: <Close {...params} {...props} />,
    clipboard: <Clipboard {...params} {...props} />,
    delete: <Delete {...params} {...props} />,
    downArrow: <DownArrow {...params} {...props} />,
    downOutlined: <DownOutlined {...params} {...props} />,
    dropper: <Dropper {...params} {...props} />,
    edit: <Edit {...params} {...props} />,
    emptyBox: <EmptyBox {...params} {...props} />,
    excel: <Excel {...params} {...props} />,
    eye: <Eye {...params} {...props} />,
    facebook: <Facebook {...params} {...props} />,
    filePdf: <FilePdf {...params} {...props} />,
    files: <Files {...params} {...props} />,
    filter: <Filter {...params} {...props} />,
    fire: <Fire {...params} {...props} />,
    globe: <Globe {...params} {...props} />,
    goBack: <GoBack {...params} {...props} />,
    goTo: <GoTo {...params} {...props} />,
    highlight: <Highlight {...params} {...props} />,
    history: <History {...params} {...props} />,
    home: <Home {...params} {...props} />,
    infoRound: <InfoRound {...params} {...props} />,
    desktop: <Laptop {...params} {...props} />,
    leftArrow: <LeftArrow {...params} {...props} />,
    linkedin: <Linkedin {...params} {...props} />,
    loading: <Loading {...params} {...props} />,
    logout: <Logout {...params} {...props} />,
    mail: <Mail {...params} {...props} />,
    mailCrossed: <MailCrossed {...params} {...props} />,
    mailSent: <MailSent {...params} {...props} />,
    menu: <Menu {...params} {...props} />,
    moon: <Moon {...params} {...props} />,
    move: <Move {...params} {...props} />,
    neutral: <Neutral {...params} {...props} />,
    news: <News {...params} {...props} />,
    noFile: <NoFile {...params} {...props} />,
    notificationOff: <NotificationOff {...params} {...props} />,
    notificationOn: <NotificationOn {...params} {...props} />,
    mobile: <Phone {...params} {...props} />,
    picker: <Picker {...params} {...props} />,
    plus: <Plus {...params} {...props} />,
    print: <Print {...params} {...props} />,
    question: <Question {...params} {...props} />,
    receivers: <Receivers {...params} {...props} />,
    refresh: <Refresh {...params} {...props} />,
    return: <Return {...params} {...props} />,
    rightArrow: <RightArrow {...params} {...props} />,
    send: <Send {...params} {...props} />,
    settings: <Settings {...params} {...props} />,
    skype: <Skype {...params} {...props} />,
    smile: <Smile {...params} {...props} />,
    stop: <Stop {...params} {...props} />,
    tablet: <Tablet {...params} {...props} />,
    tag: <Tag {...params} {...props} />,
    twitter: <Twitter {...params} {...props} />,
    upOutlined: <UpOutlined {...params} {...props} />,
    uploadCloud: <UploadCloud {...params} {...props} />,
    user: <User {...params} {...props} />,
    users: <Users {...params} {...props} />,
    warning: <Warning {...params} {...props} />
  }[type]
}

export default Icon