import { PaginationResponse } from "types/ApiResponse/ResultModel"

import { Remote } from "./Remote"

export const idle = (): Remote => ({ pending: false, isFetched: false })

export const add = (payload?: any, state?: Remote): Remote => {
  return {
    pending: false,
    isFetched: true,
    data: [...state?.data, payload]
  }
}

export const remove = (payload?: any, state?: Remote): Remote => {
  return {
    pending: false,
    isFetched: true,
    data: state?.data?.filter((el: any) => el.id !== payload)
  }
}

export const attempt = (state?: Remote): Remote => ({
  pending: true,
  isFetched: state?.isFetched,
  ...(state?.data && { data: state.data }),
  ...(state?.pagination && { pagination: state.pagination })
})

export const fail = (error: any, state?: Remote): Remote => ({
  pending: error?.message === "AbortError",
  error,
  ...(state &&
    state.data && {
      data: error?.message !== "AbortError" ? state.data : undefined
    })
})

export const succeed = (
  data: any,
  pagination?: PaginationResponse
): Remote => ({ pending: false, isFetched: true, data, pagination })

export const clear = (): Remote => ({ pending: false, isFetched: false })

export const moveItem = (
  payload: { movedIndex: number; destIndex: number },
  state: Remote
): Remote => {
  const newSortedArr = [...state.data]
  newSortedArr.splice(
    payload.movedIndex,
    0,
    newSortedArr.splice(payload.destIndex, 1)[0]
  )

  return {
    ...state,
    pending: false,
    data: newSortedArr
  }
}

export const setValue = (
  payload: { field: string; value: any },
  state: Remote
) => {
  return {
    ...state,
    data: {
      ...state.data,
      [payload.field]: payload.value
    }
  }
}
