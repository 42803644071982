import { Tooltip } from "antd"
import { FormikProps } from "formik"
import { Input } from "formik-antd"
import React from "react"

import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import { PasswordPromptFormValues } from "components/Modal/PasswordPrompt/form"
import { Text, theme } from "style"
import usePrefix from "utils/usePrefix"
import { useRenderTooltip } from "utils/useRenderTooltip"

import { FlexboxWithTooltip } from "./styles"

import { StyledModal } from "../styles"

export interface PasswordPromptFormProps {
  isVisible: boolean
  onClose: () => void
  formikProps: FormikProps<PasswordPromptFormValues>
}

export const PasswordPromptForm: React.FC<PasswordPromptFormProps> = ({
  isVisible,
  onClose,
  formikProps
}) => {
  const tBulletin = usePrefix("Bulletin")
  const tCreator = usePrefix("Creator")

  const passModalTooltip = tBulletin("passModalTooltip")
  const passModalTooltipCont = tBulletin("passModalTooltipCont")
  const tooltip = useRenderTooltip([passModalTooltip, passModalTooltipCont])

  return (
    <StyledModal
      centered
      open={isVisible}
      footer={false}
      width="660px"
      onCancel={onClose}
    >
      <Flexbox $flexDirection="column">
        <Box $mb="24px">
          <Text $type="xxxLarge" $variant="bolder" $color="black">
            {tBulletin("passModalTitle")}
          </Text>
        </Box>
        <Box $mb="40px">
          <Text $type="xLarge" $variant="bold" $color="textSecondary">
            {tBulletin("passModalDescription")}
          </Text>
        </Box>

        <Box $mb="16px">
          <Text $variant="bold" $type="large" $color="textSecondary">
            {tCreator("emailLogin")}
          </Text>
          <Input name="fromAddress" type="text" disabled />
        </Box>

        <Box $mb="5px">
          <Text $variant="bold" $type="large" $color="textSecondary">
            {tBulletin("passModalLabel")}
          </Text>
          <Input name="smtpPassword" type="password" required />
        </Box>

        <Tooltip
          title={tooltip}
          overlayStyle={{
            maxWidth: "350px",
            fontSize: "12px"
          }}
          placement="topRight"
        >
          <FlexboxWithTooltip
            $flexDirection="row-reverse"
            color={theme.colors.blueDark}
          >
            {tBulletin("passModalTip")}
          </FlexboxWithTooltip>
        </Tooltip>

        <Flexbox $mt="60px" $justifyContent="space-between">
          <Button
            onClick={onClose}
            size="large"
            type="default"
            style={{ width: "130px" }}
          >
            {tBulletin("cancel")}
          </Button>

          <Button
            style={{ width: "200px" }}
            type="primary"
            size="large"
            htmlType="submit"
            disabled={!formikProps.isValid}
            onClick={formikProps.submitForm}
          >
            {tBulletin("send")}
          </Button>
        </Flexbox>
      </Flexbox>
    </StyledModal>
  )
}
