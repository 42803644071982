// eslint-disable-next-line import/no-named-as-default
import produce from "immer"
import { Reducer } from "redux"

import { AutoNewsletterDeliveryState } from "store/autoNewsletterDelivery/AutoNewsletterDeliveryState"
import { AutoNewsletterDeliveryActions } from "store/autoNewsletterDelivery/autoNewsletterDeliveryActions"

export const initialState: AutoNewsletterDeliveryState = {
  data: undefined,
  isFetching: false,
  isFetched: false
}
export const autoNewsletterDeliveryReducer: Reducer<
  AutoNewsletterDeliveryState,
  AutoNewsletterDeliveryActions
> = (state = initialState, action): AutoNewsletterDeliveryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case "autoNewsletterDelivery/FETCH_AUTO_NEWSLETTER_DELIVERY":
        draft.isFetching = true
        break
      case "autoNewsletterDelivery/FETCH_AUTO_NEWSLETTER_DELIVERY_SUCCESS":
        draft.data = action.data
        draft.isFetching = false
        draft.isFetched = true
        break
      case "autoNewsletterDelivery/FETCH_AUTO_NEWSLETTER_DELIVERY_ERROR":
        draft.isFetching = false
        break
    }
  })
