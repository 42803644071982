import { Modal } from "antd"
import React from "react"

import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import Icon from "components/Icons"
import { H2, theme } from "style"

interface SuccessModalProps {
  title: string
  subtitle?: string
  mainBtnTitle: string
  altBtnTitle?: string
  mainBtnAction: () => void
  altBtnAction?: () => void
  onCancel?: () => void
  dataGtm?: string
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  title,
  subtitle,
  mainBtnTitle,
  altBtnTitle,
  mainBtnAction,
  altBtnAction,
  onCancel,
  dataGtm
}) => {
  return (
    <Modal
      centered
      open
      footer={false}
      width="80vw"
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "100px 0"
      }}
      closable={!!onCancel}
      onCancel={onCancel}
    >
      <H2>{title}</H2>
      {subtitle && <H2>{subtitle}</H2>}
      <Icon
        type="checkCircle"
        width="130px"
        height="130px"
        color={theme.colors.greenActive}
        style={{
          boxSizing: "content-box",
          padding: "50px"
        }}
      />
      <Box $gap="16px">
        <Flexbox $justifyContent="center">
          <Button onClick={mainBtnAction} type="primary" data-gtm={dataGtm}>
            {mainBtnTitle}
          </Button>
        </Flexbox>
        {altBtnTitle && altBtnAction && (
          <Flexbox $justifyContent="center">
            <Button onClick={altBtnAction}>{altBtnTitle}</Button>
          </Flexbox>
        )}
      </Box>
    </Modal>
  )
}
