import { request, serialize } from "api/config"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import {
  NewsletterWithStatsDto,
  NewsletterConfirmation,
  LastNewsConfirmation,
  ReceiverIdsDto,
  NewslettersPreviewDto
} from "types/Newsletter"

const PATH_NAME = "Newsletter"

let abortController = new window.AbortController()

export const fetchNewsletterList = async (
  groupId: string,
  clientId?: string
): Promise<ResultModel<NewsletterWithStatsDto[]>> => {
  abortController.abort()
  abortController = new window.AbortController()
  return request.get(
    clientId
      ? `${PATH_NAME}/${groupId}/${clientId}`
      : `${PATH_NAME}/${groupId}`,
    {
      signal: abortController.signal
    }
  )
}

export const getNewsletterPreviewHTML = async (
  newsletterId: string
): Promise<ResultModel<string>> =>
  request.get(`${PATH_NAME}/HtmlContent/${newsletterId}`)

export const getNewsletterConfirmations = async (
  pagination: PaginationRequest
): Promise<ResultModel<NewsletterConfirmation[]>> =>
  request.get(`${PATH_NAME}/NewsConfirmations${serialize(pagination)}`)

export const resendNewsletter = async (
  newsletterId: string,
  receiverIds: NewslettersPreviewDto["receiverIds"],
  password?: NewslettersPreviewDto["password"]
): Promise<ResultModel<ReceiverIdsDto>> =>
  request.patch(`${PATH_NAME}/ResendNewsletter/${newsletterId}`, {
    receiverIds,
    password
  })

export const fetchLastNewsConfirmation = async (): Promise<
  ResultModel<LastNewsConfirmation>
> => request.get(`${PATH_NAME}/LastNewsConfirmation`)

export const getNewsletterPDF = async (
  newsletterId: string
): Promise<Response> => request.get(`${PATH_NAME}/Pdf/${newsletterId}`)
