import { AnyAction } from "redux"

import { PaginationResponse } from "types/ApiResponse/ResultModel"

export const SUCCESS = "SUCCESS"

export interface Success<T = any, D = any> extends AnyAction {
  resolved: typeof SUCCESS
  type: T
  payload: D
  pagination?: PaginationResponse
}
