import { createSelector } from "reselect"

import { AppState } from "../AppState"

export const selectAccountingOfficePending = (s: AppState) =>
  s.accountingOffice.pending
export const selectAccountingOfficeFetchError = (s: AppState) =>
  s.accountingOffice.error

export const selectAccountingOfficeData = (s: AppState) =>
  s.accountingOffice.data

export const selectAccountingOffice = createSelector(
  selectAccountingOfficeData,
  (data) => data
)

export const selectAccountingOfficeConfigurationStage = (s: AppState) =>
  s.accountingOffice.data?.accountingOfficeConfigurationStage

export const selectUseOwnSMTPServer = (s: AppState) =>
  !!s.accountingOffice.data?.useOwnSmtpServer
