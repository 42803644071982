import { AppState } from "store/AppState"

export const selectSentNewsAOFetchError = (s: AppState) =>
  s.newsAO.sentNewsAO.error

export const selectSentIsLoading = (s: AppState) => s.newsAO.sentNewsAO.pending

export const selectSentNewsAOPagination = (s: AppState) =>
  s.newsAO.sentNewsAO.pagination

export const selectSentNewsAOList = (s: AppState) =>
  s.newsAO.sentNewsAO.data || []

export const selectIsLoading = (s: AppState) => s.newsAO.sentNewsAO.pending
