import { ButtonProps } from "antd/lib/button"
import React from "react"
import { styled } from "styled-components"

import Icon, { IconName } from "components/Icons"
import { theme } from "style"

import { Button } from "./Button"

export const IconButton = ({
  icon,
  children,
  color,
  ...rest
}: ButtonProps & { icon: IconName }) => {
  return (
    <StyledButton
      icon={
        <Icon
          type={icon}
          width="16px"
          height="16px"
          color={color || theme.colors.blueActive}
        />
      }
      color={color}
      {...rest}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.borderGrey};
  color: ${({ theme, color }) => (color ? color : theme.colors.textSecondary)};

  &:hover,
  &:active {
    color: ${({ theme, color }) =>
      color ? color : theme.colors.textSecondary};
    border-color: ${({ theme }) => theme.colors.blueActive};
    opacity: 0.8;
  }
`
