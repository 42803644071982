import React from "react"

import { IconProps } from "components/Icons/Icons"

const Return: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={color}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3846 0.615385H14.1538V2.92308C12.6923 1.15385 10.4615 0 8 0C3.61538 0 0 3.61538 0 8C0 12.3846 3.61538 16 8 16C12.3846 16 16 12.3846 16 8H14.7692C14.7692 11.7692 11.7692 14.7692 8 14.7692C4.23077 14.7692 1.23077 11.7692 1.23077 8C1.23077 4.23077 4.23077 1.23077 8 1.23077C10.3846 1.23077 12.4615 2.46154 13.6923 4.30769H10.4615V5.53846H15.3846V0.615385Z"
      fill={color}
    />
  </svg>
)
export default Return
