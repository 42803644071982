import { createSelector } from "reselect"

import { AppState } from "../AppState"

export const selectAutoNewsletterDeliveryStatePending = (s: AppState) =>
  s.autoNewsletterDelivery.isFetching

export const selectAutoNewsletterDeliveryData = (s: AppState) =>
  s.autoNewsletterDelivery.data

export const selectAutoNewsletterDelivery = createSelector(
  selectAutoNewsletterDeliveryData,
  (data) => data
)
