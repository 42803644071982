import React from "react"

import { IconProps } from "components/Icons/Icons"

const NotificationOff: React.FC<IconProps> = ({
  color,
  width,
  height,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M19.2,1.59999996 L16.2,4.59999997 C15.5,3.99999997 14.6,3.59999997 13.6,3.39999996 L13.6,2.39999996 C13.6,1.49999996 12.9,0.799999953 12,0.799999953 C11.1,0.799999953 10.4,1.49999996 10.4,2.39999996 L10.4,3.39999996 C6.69999998,4.19999997 4.79999995,7.89999998 4.79999995,10.4 L4.79999995,16 L4.59999997,16.3 L0.799999953,20.1 L1.89999996,21.2 L20.3,2.69999996 L19.2,1.59999996 Z M6.39999998,10.4 C6.39999998,7.29999997 8.89999998,4.79999997 12,4.79999997 C13.2,4.79999997 14.2,5.19999997 15.1,5.79999997 L6.39999998,14.5 L6.39999998,10.4 Z M21.6,19.2 L6.19999998,19.2 L7.79999998,17.6 L17.6,17.6 L17.6,10.4 C17.6,9.59999998 17.4,8.89999999 17.2,8.19999998 L18.3,7.09999998 C18.9,8.19999998 19.2,9.39999999 19.2,10.4 L19.2,16 L21.6,19.2 Z M9.59999999,20.8 L14.4,20.8 C14.4,22.1 13.3,23.2 12,23.2 C10.7,23.2 9.59999999,22.1 9.59999999,20.8 Z" />
  </svg>
)
export default NotificationOff
