import { Modal, Table, Switch } from "antd"
import { ColumnsType } from "antd/lib/table"
import React, { useCallback, useEffect, useState } from "react"

import { Api } from "api"
import SessionManager from "api/SessionManager"
import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import { H2 } from "style"
import {
  NotificationSettings,
  NotificationSettingsTable
} from "types/Notification"
import { showErrorNotification } from "utils/errors"
import usePrefix from "utils/usePrefix"

interface NotificationsModalProps {
  onClose: () => void
}

export const NotificationsModal: React.FC<NotificationsModalProps> = ({
  onClose
}) => {
  const t = usePrefix("NotificationSettings")
  const isAoPublisher = SessionManager.getIsAOPublisher()
  const [data, setData] = useState<NotificationSettingsTable[]>([])

  const getNotifications = useCallback(async () => {
    try {
      const { result } = await Api.getNotificationSettings()
      const data = isAoPublisher
        ? [
            {
              id: 1,
              email: result.newNewsEmailAllowed,
              inapp: result.newNewsAppAllowed
            },
            {
              id: 2,
              email: result.clientUnsubEmailAllowed,
              inapp: result.clientUnsubAppAllowed
            },
            {
              id: 3,
              email: result.clientEmailHardBouncedEmailAllowed,
              inapp: result.clientEmailHardBouncedAppAllowed
            },
            {
              id: 4,
              email: result.applicationNewFeaturesEmailAllowed,
              inapp: result.applicationNewFeaturesAppAllowed
            }
          ]
        : [
            {
              id: 4,
              email: result.applicationNewFeaturesEmailAllowed,
              inapp: result.applicationNewFeaturesAppAllowed
            }
          ]
      setData(data)
    } catch (err) {
      showErrorNotification(err, "Błąd pobierania ustawień")
    }
  }, [isAoPublisher])

  useEffect(() => {
    getNotifications()
  }, [getNotifications])

  const rowHeaders = isAoPublisher
    ? [
        t("new_news"),
        t("off_subscription_by_client"),
        t("incorect_email_or_send"),
        t("new_app_version")
      ]
    : [t("new_app_version")]

  const setEmail = (index: number, isChecked: boolean) => {
    const newData = [...data]

    setData(
      newData.map((el, ind) =>
        index === ind ? { ...el, email: isChecked } : el
      )
    )
  }

  const setInapp = (index: number, isChecked: boolean) => {
    const newData = [...data]

    setData(
      newData.map((el, ind) =>
        index === ind ? { ...el, inapp: isChecked } : el
      )
    )
  }

  const handleSave = async () => {
    try {
      const payload: NotificationSettings = isAoPublisher
        ? {
            newNewsEmailAllowed: data[0].email,
            newNewsAppAllowed: data[0].inapp,
            clientUnsubEmailAllowed: data[1].email,
            clientUnsubAppAllowed: data[1].inapp,
            clientEmailHardBouncedEmailAllowed: data[2].email,
            clientEmailHardBouncedAppAllowed: data[2].inapp,
            applicationNewFeaturesEmailAllowed: data[3].email,
            applicationNewFeaturesAppAllowed: data[3].inapp
          }
        : {
            applicationNewFeaturesEmailAllowed: data[0].email,
            applicationNewFeaturesAppAllowed: data[0].inapp
          }

      await Api.saveNotificationSettings(payload)
    } catch (err) {
      showErrorNotification(err, "Wystąpił błąd")
    }
    onClose()
  }

  const columns: ColumnsType<any> = [
    {
      key: "rowHeaders",
      title: t("type_notification_label"),

      render: (val, record, index) => rowHeaders[index]
    },
    {
      key: "email",
      title: t("email_label"),

      render: (val, record, index) => (
        <Switch
          checked={record.email}
          onChange={(isChecked) => setEmail(index, isChecked)}
        />
      )
    },
    {
      key: "inapp",
      title: t("in_app_label"),

      render: (val, record, index) => (
        <Switch
          checked={record.inapp}
          onChange={(isChecked) => setInapp(index, isChecked)}
        />
      )
    }
  ]

  return (
    <Modal
      centered
      open
      footer={false}
      width="600px"
      bodyStyle={{
        display: "flex",
        flexDirection: "column"
      }}
      title={<H2>{t("header")}</H2>}
      onCancel={onClose}
      closable
    >
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        pagination={false}
      />

      <Flexbox $justifyContent="space-between" style={{ marginTop: 32 }}>
        <Box>
          <Button onClick={onClose} size="large">
            {t("cancel")}
          </Button>
        </Box>

        <Box>
          <Button onClick={handleSave} type="primary" size="large">
            {t("save")}
          </Button>
        </Box>
      </Flexbox>
    </Modal>
  )
}
