import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { selectClientCount, selectClientPending } from "store/client"
import { selectClientLimit } from "store/layout"
import * as LocalStorage from "store/storage/localStorage"

export const useExceededClientsLimit = () => {
  const clientCount = useSelector(selectClientCount)
  const clientLimit = useSelector(selectClientLimit)
  const isLoading = useSelector(selectClientPending)
  const [exceededClientsLimit, setExceededClientsLimit] = useState<
    boolean | undefined
  >(undefined)

  const loadIgnoreLimit = !!LocalStorage.loadLocalState(
    LocalStorage.IGNORE_EXCEEDED_LIMIT
  )
  const setIgnoreLimit = (value: boolean) =>
    LocalStorage.saveLocalState(LocalStorage.IGNORE_EXCEEDED_LIMIT, value)
  const removeIgnoreLimit = () =>
    LocalStorage.removeLocalState(LocalStorage.IGNORE_EXCEEDED_LIMIT)

  useEffect(() => {
    if (!isLoading && clientLimit > 0) {
      setExceededClientsLimit(clientCount > clientLimit)
    } else {
      setExceededClientsLimit(undefined)
    }
  }, [clientCount, clientLimit, isLoading])

  useEffect(() => {
    if (!isLoading && clientCount < clientLimit && loadIgnoreLimit) {
      removeIgnoreLimit()
    }
  }, [clientCount, clientLimit, isLoading, loadIgnoreLimit])

  return {
    clientCount,
    clientLimit,
    exceededClientsLimit,
    loadIgnoreLimit,
    setIgnoreLimit,
    removeIgnoreLimit
  }
}
