import React from "react"

import { IconProps } from "components/Icons/Icons"

const Edit: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M18.4,1.59999996 C17.5,1.59999996 16.4,1.99999996 15.6,2.79999996 L2.39999996,15.9 L1.59999996,22.4 L8.09999996,21.6 L21.2,8.39999999 C22,7.59999996 22.4,6.39999998 22.4,5.59999996 C22.4,3.39999996 20.6,1.59999996 18.4,1.59999996 Z M4.89999997,15.7 L15.7,4.89999997 L19.1,8.29999998 L8.29999998,19.1 L4.89999997,15.7 Z M3.89999996,17 L6.99999998,20.1 L3.39999996,20.5 L3.89999996,17 Z M20.2,7.09999996 L16.8,3.69999997 C17.3,3.29999996 17.9,3.09999996 18.3,3.09999996 C19.6,3.09999996 20.7,4.19999997 20.7,5.49999997 C20.8,5.99999998 20.6,6.59999996 20.2,7.09999996 Z" />
  </svg>
)
export default Edit
