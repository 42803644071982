import * as accountingOfficeRequests from "./accountingOffice/accountingOfficeApi"
import * as autoNewsletterDeliveryRequests from "./autoNewsletterDelivery/autoNewsletterDeliveryApi"
import * as clientRequests from "./client/clientApi"
import * as clientActivityRequests from "./clientActivity/clientActivityApi"
import * as customTagRequests from "./customTag/customTagApi"
import * as fileRequests from "./file/fileApi"
import * as historyAORequests from "./historyAO/historyAOApi"
import * as imageRequests from "./image/imageApi"
import * as leadFormConfigRequest from "./leadForm/leadFormConfigApi"
import * as newsRequests from "./news/newsApi"
import * as newsletterRequests from "./newsletter/newsletterApi"
import * as newsletterGroupRequests from "./newsletterGroup/newsletterGroupApi"
import * as notificationRequests from "./notification/notificationApi"
import * as technicalRequests from "./technical/technicalApi"
import * as templateRequests from "./template/templateApi"
import * as testDispatchRequests from "./testDispatch/testDispatchApi"
import * as usersRequests from "./users/usersApi"

export const Api = {
  ...accountingOfficeRequests,
  ...autoNewsletterDeliveryRequests,
  ...clientActivityRequests,
  ...clientRequests,
  ...customTagRequests,
  ...fileRequests,
  ...historyAORequests,
  ...imageRequests,
  ...newsRequests,
  ...newsletterGroupRequests,
  ...newsletterRequests,
  ...notificationRequests,
  ...leadFormConfigRequest,
  ...technicalRequests,
  ...templateRequests,
  ...testDispatchRequests,
  ...usersRequests,
  ...technicalRequests
}
