import { createSelector } from "reselect"

import { AppState } from "store/AppState"

export const selectTestNewsAOPending = (s: AppState) =>
  s.newsAO.testNewsAO.pending

export const selectTestNewsAOFetchError = (s: AppState) =>
  s.newsAO.testNewsAO.error

export const selectTestNewsAOListData = (s: AppState) =>
  s.newsAO.testNewsAO.data

export const selectTestNewsAOList = createSelector(
  selectTestNewsAOListData,
  (testNewsList) => testNewsList || []
)
