import { Formik } from "formik"
import React from "react"

import {
  PasswordPromptForm,
  PasswordPromptFormProps
} from "components/Modal/PasswordPrompt/PasswordPromptForm"
import {
  PasswordPromptFormValues,
  useInitialValues,
  useValidation
} from "components/Modal/PasswordPrompt/form"

export interface PasswordPromptProps
  extends Omit<PasswordPromptFormProps, "formikProps"> {
  onSend: (pass: string) => void
}

export const PasswordPrompt = ({
  isVisible,
  onClose,
  onSend
}: PasswordPromptProps) => {
  const initialValues = useInitialValues()
  const validationSchema = useValidation()

  const onSubmit = async (values: PasswordPromptFormValues) => {
    onSend(values.smtpPassword)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
      validateOnBlur={false}
    >
      {(formikProps) => (
        <PasswordPromptForm
          formikProps={formikProps}
          isVisible={isVisible}
          onClose={onClose}
        />
      )}
    </Formik>
  )
}
