import { AnyAction } from "redux"

export const SET_VALUE = "SET_VALUE"

export interface SetValue extends AnyAction {
  resolved: typeof SET_VALUE
  payload: {
    field: string
    value: any
  }
}
