import { Dispatch } from "redux"

import { Api } from "api"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import { NewsletterGroupResponseDto } from "types/Newsletter"
import { PromiseAction } from "types/RemoteAction"

export const fetchNewsletterGroups =
  (pagination: PaginationRequest, clientId?: string) =>
  (
    dispatch: Dispatch
  ): PromiseAction<ResultModel<NewsletterGroupResponseDto[]>> =>
    dispatch({
      type: "newsletterGroups/FETCH",
      promise: Api.fetchNewsletterGroups(pagination, clientId)
    })

export const clearNewsletterGroups = () => ({
  type: "newsletterGroups/CLEAR",
  resolved: "CLEAR"
})
