import { AppState } from "../../AppState"

export const selectArchivedNewsAOFetchError = (s: AppState) =>
  s.newsAO.archivedNewsAO.error

export const selectArchivedNewsAOPagination = (s: AppState) =>
  s.newsAO.archivedNewsAO.pagination

export const selectArchivedNewsAOList = (s: AppState) =>
  s.newsAO.archivedNewsAO.data || []

export const selectArchivedNewsIsLoading = (s: AppState) =>
  s.newsAO.archivedNewsAO.pending
