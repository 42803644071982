import React from "react"

import { IconProps } from "components/Icons/Icons"

const Delete: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M16.8,3.99999997 C16.8,2.19999996 15.4,0.799999953 13.6,0.799999953 L10.4,0.799999953 C8.59999996,0.799999953 7.19999998,2.19999996 7.19999998,3.99999997 L1.59999996,3.99999997 L1.59999996,5.59999997 L3.19999996,5.59999997 L3.19999996,23.2 L20.8,23.2 L20.8,5.59999997 L22.4,5.59999997 L22.4,3.99999997 L16.8,3.99999997 Z M10.4,2.39999996 L13.6,2.39999996 C14.5,2.39999996 15.2,3.09999996 15.2,3.99999997 L8.79999999,3.99999997 C8.79999999,3.09999996 9.49999999,2.39999996 10.4,2.39999996 Z M19.2,21.6 L4.79999997,21.6 L4.79999997,5.59999997 L19.2,5.59999997 L19.2,21.6 Z M12.8,8.79999999 L11.2,8.79999999 L11.2,18.4 L12.8,18.4 L12.8,8.79999999 Z M16,8.79999999 L14.4,8.79999999 L14.4,18.4 L16,18.4 L16,8.79999999 Z M9.59999999,8.79999999 L7.99999998,8.79999999 L7.99999998,18.4 L9.59999999,18.4 L9.59999999,8.79999999 Z" />
  </svg>
)
export default Delete
