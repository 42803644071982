import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { LeadFormLinks } from "types/LeadForm"
import { PromiseAction } from "types/RemoteAction"

export const fetchLeadFormLinks =
  () =>
  (dispatch: Dispatch): PromiseAction<ResultModel<LeadFormLinks>> =>
    dispatch({
      type: "links/FETCH",
      promise: Api.getLeadFormLinks()
    })
