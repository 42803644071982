import { NavLink } from "react-router-dom"
import { styled } from "styled-components"

import { responsiveContainerStyle } from "style"

interface StyledHeaderProps {
  $isTestMode: boolean
}

export const HeaderWrapper = styled.div<StyledHeaderProps>`
  position: sticky;
  top: ${({ $isTestMode }) => ($isTestMode ? 40 : 0)}px;
  z-index: 20;

  .ant-layout-header {
    height: 50px;
    line-height: 50px;
    padding: 0;
  }
`

export const AppLogo = styled.div`
  font-size: 1.5rem;

  a {
    color: ${(props) => props.theme.colors.white};
  }
`

export const LogoImg = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 10px;
`

export const HeaderButton = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 29px 0 18px;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueDark};
    color: ${({ theme }) => theme.colors.white};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.blueDark};
  }
`

export const TestModeBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 100000;
  width: 100%;
  height: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 500;
  background: ${({ theme }) => theme.colors.error};
`

export const CookieWrapper = styled.div`
  position: fixed;
  width: 100%;
  padding: 25px 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: ${({ theme }) => theme.colors.textSecondary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 300;

  & > div {
    margin: auto;
    ${responsiveContainerStyle};
  }

  a {
    color: ${({ theme }) => theme.colors.textPlaceholder};
    font-weight: 500;
  }

  .ant-btn {
    border-color: ${({ theme }) => theme.colors.textPlaceholder};
  }
`
