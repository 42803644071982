import { SelectOption, TagStructure } from "types/Template"

export const isTagStructureObject = (tag: any): tag is TagStructure =>
  !!tag.name

export const getTagName = (tag: TagStructure | SelectOption) => {
  if (!tag) {
    return null
  }
  return isTagStructureObject(tag) ? tag.name : tag.label
}
