const rafAsync = () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 100)
  })
}

export const checkElement = async (selector: string): Promise<boolean> => {
  if (document.querySelector(selector) === null) {
    return rafAsync().then(() => checkElement(selector))
  } else {
    return Promise.resolve(true)
  }
}
