import React from "react"

import { IconProps } from "components/Icons/Icons"

const Menu: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path
      d="M1.59999996,3.19999996 L1.59999996,4.79999997 L22.4,4.79999997 L22.4,3.19999996 L1.59999996,3.19999996 Z M1.59999996,12.8 L22.4,12.8 L22.4,11.2 L1.59999996,11.2 L1.59999996,12.8 Z M1.59999996,20.8 L22.4,20.8 L22.4,19.2 L1.59999996,19.2 L1.59999996,20.8 Z"
      fill={color}
    />
  </svg>
)
export default Menu
