import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { PromiseAction } from "types/RemoteAction"
import { FormActivity } from "types/Template"

export const fetchFormActivity =
  () =>
  (dispatch: Dispatch): PromiseAction<ResultModel<FormActivity[]>> =>
    dispatch({
      type: "formActivity/FETCH",
      promise: Api.getActivityForm()
    })
