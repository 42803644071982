import React from "react"
import { useSelector } from "react-redux"

import { Tag } from "components/Tag"
import { AppState } from "store/AppState"
import { selectTagLabel } from "store/sharedSelectors/tagSelectors"

interface TagListProps {
  tags: number[]
  customTags: string[]
  activityFormCode?: number | null
  activityFormCodes?: number[]
  pkdCode?: string | null
  pkdCodes?: string[]
  pkds?: string[]
  logicalAlternativeTags?: number[]
  logicalAlternativeCustomTags?: string[]
  logicalAlternativePkds?: string[]
}

export const TagList = ({
  tags,
  customTags,
  activityFormCode,
  activityFormCodes,
  pkdCode,
  pkdCodes,
  pkds,
  logicalAlternativeTags,
  logicalAlternativeCustomTags,
  logicalAlternativePkds
}: TagListProps) => {
  const activityForms =
    activityFormCodes || (activityFormCode ? [activityFormCode] : [])

  const pkdList = pkds || pkdCodes || (pkdCode ? [pkdCode] : [])

  return (
    <>
      {tags?.map((value) => (
        <TagElement key={`tag-${value}`} id={value} type="tag" />
      ))}
      {logicalAlternativeTags?.map((value) => (
        <TagElement key={`tag-${value}`} id={value} type="tag" isOptional />
      ))}

      {customTags?.map((value) => (
        <TagElement key={`customTags-${value}`} id={value} type="customTag" />
      ))}
      {logicalAlternativeCustomTags?.map((value) => (
        <TagElement
          key={`customTags-${value}`}
          id={value}
          type="customTag"
          isOptional
        />
      ))}

      {activityForms?.map((value) => (
        <TagElement
          key={`activity-form-${value}`}
          id={value}
          type="activityForm"
        />
      ))}

      {pkdList?.map((value) => (
        <TagElement key={`pkd-${value}`} id={value} type="pkdCode" />
      ))}
      {logicalAlternativePkds?.map((value) => (
        <TagElement key={`pkd-${value}`} id={value} type="pkdCode" isOptional />
      ))}
    </>
  )
}

interface TagElementProps {
  id: number | string
  type: "tag" | "activityForm" | "pkdCode" | "customTag"
  isOptional?: boolean
}

const TagElement: React.FC<TagElementProps> = ({
  id,
  type,
  isOptional = false
}) => {
  const label = useSelector((s: AppState) => selectTagLabel(s, id, type))

  if (!label) {
    return null
  }

  return (
    <Tag formType="news" checked={!isOptional} isIndeterminate={isOptional}>
      {type === "pkdCode" ? `${id} - ${label}` : label}
    </Tag>
  )
}