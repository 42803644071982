import * as LocalStorage from "store/storage/localStorage"

export const apiUrl = `${
  process.env.REACT_APP_API_URL || `${window.location.origin}/api`
}`

export const getTinyApiKey = () => {
  const session = LocalStorage.loadLocalState(LocalStorage.SESSION)

  if (!session?.technicalData) {
    return null
  }
  return session.technicalData.tinyMceApiKey
}

export const getLoginUrl = () => {
  const loginUrl = LocalStorage.loadLocalState(LocalStorage.BORG_LOGIN_URL)
  if (!loginUrl) {
    return "https://borg.wolterskluwer.pl/"
  }
  return `${loginUrl}${window.location.origin}`
}

export const getLogoutUrl = () => {
  const session = LocalStorage.loadLocalState(LocalStorage.SESSION)
  const borgLogOnUrl = getLoginUrl()

  if (!session?.technicalData || !borgLogOnUrl) {
    return null
  }

  return `${session.technicalData.borgLogOutUrl}&referer=${encodeURIComponent(
    borgLogOnUrl
  )}&originalReferrer=${encodeURIComponent(document.referrer)}`
}
