import { Tooltip } from "antd"
import { FormItem } from "formik-antd"
import React from "react"

import { ConditionalWrapper } from "components/ConditionalWrapper"
import Icon from "components/Icons"
import { theme } from "style"
import { TagGroupStructure } from "types/Template"

import { SingleGroup } from "./SingleGroup"
import useTagChangeHandler from "./useTagChangeHandler"

interface TagGroupProps {
  tags: TagGroupStructure
  formType: "news" | "client"
  isTagValidationOff?: boolean
  selected?: number[]
  selectedAlternative?: number[]
  onChange: (tags: number[], logicalAlternativeTags?: number[]) => void
}

export const TagGroup: React.FC<TagGroupProps> = ({
  tags,
  formType,
  isTagValidationOff,
  selected = [],
  selectedAlternative = [],
  onChange
}) => {
  const [handleChangeTag] = useTagChangeHandler({
    formType,
    selected,
    selectedAlternative,
    isTagValidationOff,
    onChange
  })

  const groups = groupByArray(tags?.children, "group", "order")

  return (
    <ConditionalWrapper
      condition={!!tags?.header}
      unwrappedStyle={{ marginBottom: 24 }}
      wrapper={(children) => (
        <FormItem
          name="activityForms"
          label={
            <>
              {tags?.header}
              {tags?.toolTip && (
                <Tooltip title={tags.toolTip}>
                  <span>
                    <Icon
                      type="infoRound"
                      width="14px"
                      height="14px"
                      color={theme.colors.textSecondary}
                      style={{ marginLeft: 4, transform: "translateY(2px)" }}
                    />
                  </span>
                </Tooltip>
              )}
            </>
          }
        >
          {children}
        </FormItem>
      )}
    >
      {groups.map((group) => (
        <SingleGroup
          key={group[0].group}
          group={group}
          selected={selected}
          selectedAlternative={selectedAlternative}
          formType={formType}
          handleChangeTag={handleChangeTag}
          isTagValidationOff={isTagValidationOff}
        />
      ))}
    </ConditionalWrapper>
  )
}

const groupByArray = function <TArray extends Record<string, any>>(
  arr: TArray[],
  key: string,
  sortKey?: string
): TArray[][] {
  const groups = arr.reduce(
    (rv, x) => {
      if (rv[x[key]]) {
        rv[x[key]].push(x)
      } else {
        rv[x[key]] = [x]
      }

      return rv
    },
    {} as Record<string, any>
  )

  if (!sortKey) {
    return Object.values(groups)
  }

  return Object.values(groups).sort((a, b) => {
    if (a[0][sortKey] === b[0][sortKey]) {
      return a[0]?.name > b[0]?.name ? 1 : -1
    }
    return a[0][sortKey] > b[0][sortKey] ? 1 : -1
  })
}
