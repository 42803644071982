import React, { useEffect, useState } from "react"
import { styled } from "styled-components"

import { Skeleton } from "components/Skeleton/Skeleton"
import { SectionBox } from "style"
import { useClientRect } from "utils/hooks"

interface LoadingOpenWrapperProps {
  isExpanded: boolean
  isLoading: boolean
  children: React.ReactNode
}

export const LoadingOpenWrapper: React.FC<LoadingOpenWrapperProps> = ({
  isExpanded,
  isLoading,
  children
}) => {
  const [rect, ref] = useClientRect()

  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (isExpanded) {
      setHeight(isLoading ? 100 : rect?.height || 0)
    } else {
      setHeight(0)
    }
  }, [isExpanded, isLoading, rect])

  return (
    <Wrapper $height={height} $isExpanded={isExpanded}>
      {isLoading ? (
        <SectionBox>
          <Skeleton
            title={{ width: "60%" }}
            avatar={{ shape: "square" }}
            paragraph={{ rows: 0 }}
          />
        </SectionBox>
      ) : (
        <div ref={ref}>{children}</div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $height: number; $isExpanded: boolean }>`
  height: ${({ $height }) => $height}px;
  transition: 0.1s height ease-in-out;
  overflow: hidden;
  ${({ $isExpanded }) => !$isExpanded && "display: none;"}
`
