import { AnyAction } from "redux"

export const MOVE_ITEM = "MOVE_ITEM"

export interface MoveItem extends AnyAction {
  resolved: typeof MOVE_ITEM
  payload: {
    movedIndex: number
    destIndex: number
  }
}
