import React from "react"

import { Button } from "components/Button"
import Flexbox, { Box } from "components/Flexbox"
import { ClientListModal } from "components/Modal"
import { TagList } from "components/Tag"
import { H2, H4 } from "style"
import {
  NewsletterForPreviewDtoResponse,
  NewsletterWithStatsDto
} from "types/Newsletter"
import usePrefix from "utils/usePrefix"

interface NewsletterClientModalProps {
  isVisible: boolean
  title: string
  newsletterList: (NewsletterWithStatsDto | NewsletterForPreviewDtoResponse)[]
  isClientRemovable?: boolean
  clientIds?: string[]
  onClientRemove?: (id: string) => void
  onClose: () => void
}

export const NewsletterClientModal: React.FC<NewsletterClientModalProps> = ({
  isVisible,
  isClientRemovable,
  title,
  newsletterList,
  clientIds,
  onClientRemove,
  onClose
}) => {
  const tGeneral = usePrefix("General")

  const tags = Array.from(
    new Set(newsletterList.flatMap((newsletter) => newsletter.tags))
  )

  const customTags = Array.from(
    new Set(newsletterList.flatMap((newsletter) => newsletter.customTags))
  ).filter(Boolean) as string[]

  const activityFormCodes = newsletterList
    .map((newsletter) => newsletter.activityFormCode)
    .filter(Boolean) as number[]

  const pkdCodes = newsletterList
    .flatMap((newsletter) => newsletter.pkdCodes)
    .filter(Boolean) as string[]

  const clients = newsletterList
    .flatMap((newsletter) => newsletter.receivers)
    .filter((client) => {
      if (clientIds && clientIds.length > 0) {
        return clientIds.includes(client.id as string)
      }
      return !!client.id
    })

  return (
    <ClientListModal
      isVisible={isVisible}
      isClientRemovable={isClientRemovable}
      title={
        <Box>
          <H2>{`${tGeneral("clients")} (${clients.length})`}</H2>
          <H4>{title}</H4>
        </Box>
      }
      clients={clients}
      header={
        <Box>
          <TagList
            tags={tags}
            customTags={customTags}
            activityFormCodes={activityFormCodes}
            pkdCodes={pkdCodes}
          />
        </Box>
      }
      footer={
        <Flexbox $justifyContent="end">
          <Button type="primary" size="large" onClick={onClose}>
            {tGeneral("close")}
          </Button>
        </Flexbox>
      }
      onClientRemove={onClientRemove}
      onClose={onClose}
    />
  )
}
