import React from "react"

import { Tag } from "components/Tag"
import { TagStructure } from "types/Template"

import { Group } from "./styles"

interface SingleGroupProps {
  formType: "news" | "client"
  selected: number[]
  selectedAlternative: number[]
  group: TagStructure[]
  isTagValidationOff?: boolean
  handleChangeTag: (
    tag: TagStructure,
    group: TagStructure[],
    isChecked: boolean,
    isIndeterminate?: boolean
  ) => void
}

export const SingleGroup: React.FC<SingleGroupProps> = ({
  formType,
  selected,
  selectedAlternative,
  group,
  isTagValidationOff,
  handleChangeTag
}) => {
  const isGroupRequiredValid =
    formType !== "client" ||
    group.length === 1 ||
    group.some((tag) => selected.includes(tag.code))

  return (
    <Group $isGroupRequired={!isGroupRequiredValid && !isTagValidationOff}>
      {group.map((tag) => (
        <Tag
          key={tag.code}
          checked={selected?.includes(tag.code)}
          isIndeterminate={selectedAlternative?.includes(tag.code)}
          onChange={(isChecked, isIndeterminate) =>
            handleChangeTag(tag, group, isChecked, isIndeterminate)
          }
          formType={formType}
          tip={tag?.tip}
        >
          {tag.name}
        </Tag>
      ))}
    </Group>
  )
}
