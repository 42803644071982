import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { PromiseAction } from "types/RemoteAction"
import { TemplatePage } from "types/Template"

export const exampleNewsletterAction =
  (templateType: TemplatePage) =>
  (dispatch: Dispatch): PromiseAction<ResultModel<string>> =>
    dispatch({
      type: "exampleNewsletter/FETCH",
      promise: Api.getExampleNewsletter({ templateType })
    })
