import React from "react"

import { IconProps } from "components/Icons/Icons"

const NotificationOn: React.FC<IconProps> = ({
  color,
  width,
  height,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M9.59999999,20.8 L14.4,20.8 C14.4,22.1 13.3,23.2 12,23.2 C10.7,23.2 9.59999999,22.1 9.59999999,20.8 Z M21.6,19.2 L2.39999996,19.2 L4.79999997,16 L4.79999997,10.4 C4.79999997,7.89999998 6.69999998,4.19999997 10.4,3.39999996 L10.4,2.39999996 C10.4,1.49999996 11.1,0.799999953 12,0.799999953 C12.9,0.799999953 13.6,1.49999996 13.6,2.39999996 L13.6,3.39999996 C17.3,4.19999997 19.2,7.89999998 19.2,10.4 L19.2,16 L21.6,19.2 Z M17.6,10.4 C17.6,7.29999997 15.1,4.79999997 12,4.79999997 C8.89999998,4.79999997 6.39999998,7.29999997 6.39999998,10.4 L6.39999998,17.6 L17.6,17.6 L17.6,10.4 Z" />
  </svg>
)
export default NotificationOn
