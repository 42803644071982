export enum AccountConfigContactStepEnum {
  CONTACT_FORM,
  LOGO_AND_COLOR_FORM
}

export enum AccountConfigClientStepEnum {
  MANUAL_ADD_CLIENT,
  CLIENT_LIST,
  FINISH_CONFIGURATION
}
