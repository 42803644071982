import React from "react"

import { IconProps } from "components/Icons/Icons"

const Skype: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill={color}
    {...props}
  >
    <path d="M9.03 13.53C6.37 13.53 5.18 12.22 5.18 11.24C5.18 10.74 5.55 10.38 6.06 10.38C7.2 10.38 6.91 12 9.03 12C10.12 12 10.73 11.43 10.73 10.82C10.73 10.46 10.55 10.06 9.83 9.88L7.46 9.29C5.55 8.81 5.2 7.78 5.2 6.81C5.2 4.79 7.1 4.03 8.88 4.03C10.5 4.03 12.46 4.94 12.46 6.15C12.46 6.67 12 6.97 11.5 6.97C10.5 6.97 10.7 5.62 8.74 5.62C7.77 5.62 7.23 6.06 7.23 6.69C7.23 7.32 8 7.5 8.66 7.68L10.42 8.07C12.34 8.5 12.83 9.62 12.83 10.67C12.83 12.31 11.57 13.53 9.03 13.53ZM15 3C17.07 5.04 17.85 7.89 17.36 10.55C17.77 11.27 18 12.11 18 13C18 14.3261 17.4732 15.5979 16.5355 16.5355C15.5979 17.4732 14.3261 18 13 18C12.11 18 11.27 17.77 10.55 17.36C7.89 17.85 5.04 17.07 3 15C0.93 12.96 0.15 10.11 0.64 7.45C0.23 6.73 0 5.89 0 5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526784 3.67392 0 5 0C5.89 0 6.73 0.23 7.45 0.64C10.11 0.15 12.96 0.93 15 3ZM5 2C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5C2 5.79 2.3 6.5 2.8 7.04C2.1 9.28 2.63 11.82 4.4 13.6C6.18 15.37 8.72 15.9 10.96 15.2C11.5 15.7 12.21 16 13 16C13.7956 16 14.5587 15.6839 15.1213 15.1213C15.6839 14.5587 16 13.7956 16 13C16 12.21 15.7 11.5 15.2 10.96C15.9 8.72 15.37 6.18 13.6 4.4C11.82 2.63 9.28 2.1 7.04 2.8C6.5 2.3 5.79 2 5 2Z" />
  </svg>
)
export default Skype
