import React from "react"
import { styled } from "styled-components"

import SessionManager from "api/SessionManager"
import { Button } from "components/Button"
import { RoleEnum } from "enums"
import { Role } from "types/Role"

const __DEVRoleBtn = () => {
  const role = SessionManager.getUserRole()

  const handleSetNextRole = () => {
    if (role) {
      const roles: Role[] = [...Object.values(RoleEnum).map((role) => role)]
      const nextRoleIndex = roles.indexOf(role)
      SessionManager.setNextRole(roles[(nextRoleIndex + 1) % roles.length])
    }
  }

  return (
    <Btn type="primary" color="blue" onClick={handleSetNextRole}>
      {role}
    </Btn>
  )
}

const Btn = styled(Button)`
  position: fixed;
  bottom: 5px;
  right: 5px;
`

export default __DEVRoleBtn
