import { Reducer } from "redux"

import { TourEnum, aoStageToTourMapper } from "enums"

import { TourState } from "./TourState"
import { TourAction } from "./tourActions"

export const initialTourState: TourState = {
  tourType: null,
  isTestMode: false,
  isOpen: false
}

export const tourReducer: Reducer<TourState, TourAction> = (
  state = initialTourState,
  action
): TourState => {
  switch (action.type) {
    case "accountingOffice/FETCH":
      if (action.resolved === "SUCCESS") {
        const tourType =
          aoStageToTourMapper[action.payload.accountingOfficeConfigurationStage]
        return {
          ...state,
          tourType,
          isTestMode: !!tourType
        }
      }
      return state
    case "tour/START_TEST_SEND_TOUR":
      return {
        ...state,
        tourType: TourEnum.ADD_TEST_CLIENT,
        isTestMode: true,
        isOpen: true
      }
    case "tour/OPEN_TOUR":
      return {
        ...state,
        isOpen: true
      }
    case "tour/SET_TOUR_TYPE":
      return {
        ...state,
        tourType: action.tourType,
        isOpen: false
      }
    case "tour/CLEAR_TOUR_DATA":
      return initialTourState
    default:
      return state
  }
}
