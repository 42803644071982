import React, { useState, useEffect } from "react"

import { Button, IconButton } from "components/Button"
import * as LocalStorage from "store/storage/localStorage"
import { theme } from "style"
import usePrefix from "utils/usePrefix"

import { CookieWrapper } from "./styles"

export const CookiePolicy: React.FC = () => {
  const t = usePrefix("General")
  const [isDisclaimerVisible, setDisclaimerVisibility] = useState(false)

  useEffect(() => {
    const hideDisclaimer = LocalStorage.loadLocalState(
      LocalStorage.HIDE_DISCLAIMER
    )
    setDisclaimerVisibility(!hideDisclaimer)
  }, [])

  const handleClose = () => {
    setDisclaimerVisibility(false)
    LocalStorage.saveLocalState(LocalStorage.HIDE_DISCLAIMER, true)
  }

  if (!isDisclaimerVisible) {
    return null
  }

  return (
    <CookieWrapper>
      <div>
        <span>{t("cookies-1")}</span>
        <a
          href="https://www.wolterskluwer.com/pl-pl/privacy-cookies"
          target="blank"
        >
          {t("cookies-link-policy")}
        </a>
        <span>{t("cookies-2")}</span>
        <a
          href="https://www.wolterskluwer.com/pl-pl/privacy-cookies"
          target="blank"
        >
          {t("cookies-link-help")}
        </a>
        <IconButton
          icon="close"
          color={theme.colors.white}
          onClick={handleClose}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            background: "transparent"
          }}
        />

        <Button
          size="large"
          style={{ marginTop: 24, display: "block" }}
          onClick={handleClose}
        >
          {t("cookies-btn-close")}
        </Button>
      </div>
    </CookieWrapper>
  )
}
