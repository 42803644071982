import { createSelector } from "reselect"

import { AppState } from "store/AppState"
import { selectNormalizedAllCustomTags } from "store/customTag"
import { selectNormalizedAllActivityForms } from "store/formActivity"
import { selectNormalizedAllLabels } from "store/label"
import { selectNormalizedAllPKD } from "store/pkd"
import { getTagName } from "utils/tags"

export const selectTagLabel = createSelector(
  selectNormalizedAllLabels,
  selectNormalizedAllCustomTags,
  selectNormalizedAllActivityForms,
  selectNormalizedAllPKD,
  (s: AppState, id: number | string) => id,
  (
    s: AppState,
    id: number | string,
    type: "tag" | "activityForm" | "pkdCode" | "customTag"
  ) => type,
  (tags, customTags, activityForms, PKDs, id, type) => {
    const listObj = {
      tag: tags,
      customTag: customTags,
      activityForm: activityForms,
      pkdCode: PKDs
    }[type]

    return getTagName(listObj[id]) || ""
  }
)
