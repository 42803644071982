import { combineReducers } from "redux"

import { LeadFormState } from "./LeadFormState"
import { configurationReducer } from "./configuration"
import { linksReducer } from "./links"

export const leadFormReducer = combineReducers<LeadFormState>({
  links: linksReducer,
  configuration: configurationReducer
})
