import { AppState } from "../AppState"

export const selectAllSelected = (s: AppState) => s.newsUI.selected

export const selectNewsConfirmationId = (s: AppState) =>
  s.newsUI.newsConfirmationId

export const selectIsCurrentSelected = (s: AppState, id?: string) =>
  s.newsUI.selected.some((newsId) => newsId === id)

export const selectIsMoreThanOneSelected = (s: AppState) =>
  s.newsUI.selected.length > 1
export const selectIsExactOneSelected = (s: AppState) =>
  s.newsUI.selected.length === 1
