export const device = {
  mobileS: "(min-width: 320px)",
  mobileM: "(min-width: 375px)",
  mobileL: "(min-width: 425px)",
  tablet: "(min-width: 768px)",
  laptop: "(min-width: 1024px)",
  laptopM: "(min-width: 1200px)",
  laptopL: "(min-width: 1440px)",
  desktop: "(min-width: 2560px)"
} as const
