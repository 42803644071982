import { styled } from "styled-components"

export const LoadingFullScreenWrapper = styled.div<{ $fullHeight?: boolean }>`
  font-size: 40px;
  height: ${({ $fullHeight }) =>
    $fullHeight ? "100%" : "calc(100vh - 224px)"};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $fullHeight }) =>
    $fullHeight &&
    `
    position: fixed;
    top: 50px;
    left: 50%;
  `}
`
