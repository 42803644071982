import { Reducer } from "redux"

import { LayoutState } from "./LayoutState"
import { LayoutAction } from "./layoutActions"

export const initialState: LayoutState = {
  withBottomBar: false,
  withNavBar: true,
  clientLimit: 0,
  isFiltersVisible: false
}

export const layoutReducer: Reducer<LayoutState, LayoutAction> = (
  state = initialState,
  action
): LayoutState => {
  switch (action.type) {
    case "layout/TOGGLE_BOTTOM_BAR":
      return {
        ...state,
        withBottomBar: action.withBottomBar
      }
    case "layout/TOGGLE_NAV_BAR":
      return {
        ...state,
        withNavBar: action.withNavBar
      }
    case "layout/SET_CLIENT_LIMIT":
      return {
        ...state,
        clientLimit: action.limit
      }
    case "layout/SET_FILTERS_VISIBLE":
      return {
        ...state,
        isFiltersVisible: action.isVisible
      }

    default:
      return state
  }
}
