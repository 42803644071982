import React from "react"

import { IconProps } from "components/Icons/Icons"

const Logout: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M13.6,19.2 L20.8,12 L13.6,4.79999997 L12.5,5.89999997 L17.8,11.2 L6.39999998,11.2 L6.39999998,12.8 L17.8,12.8 L12.5,18.1 L13.6,19.2 Z M4.79999997,3.19999996 L17.6,3.19999996 L17.6,1.59999996 L3.19999996,1.59999996 L3.19999996,22.4 L17.6,22.4 L17.6,20.8 L4.79999997,20.8 L4.79999997,3.19999996 Z" />
  </svg>
)
export default Logout
