import { styled } from "styled-components"

import { Box } from "components/Flexbox"
import { device, theme } from "style"

interface TagWrapperProps {
  $closable?: boolean
  $isIndeterminate: boolean
}

export const PkdAndActivityFormWrapper = styled(Box)`
  & .ant-select-single {
    border: 1px solid ${theme.colors.textPlaceholder};
    border-radius: 4px;
  }

  & .ant-select-selector {
    border-radius: 4px !important;
  }

  & .ant-select-selection-item {
    margin: auto;
  }
`

export const TagWrapper = styled.div<TagWrapperProps>`
  display: inline-flex;
  position: relative;

  .ant-tag {
    background: unset;
    border: 1px solid ${({ theme }) => theme.colors.textAdditional};
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 12px;
    padding: 4px 10px;
    margin-bottom: 8px;
    margin-right: 8px;
    border-radius: 4px;

    max-width: 90px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${({ $closable }) =>
      $closable &&
      `
			padding-right:20px;
		`} @media ${device.tablet} {
      max-width: 110px;
    }
    @media ${device.laptop} {
      max-width: 140px;
    }
  }

  .ant-tag:hover {
    opacity: 0.7;
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  ${({ theme, $isIndeterminate }) =>
    $isIndeterminate &&
    `
    .ant-tag {
      border: 1px solid ${theme.colors.greenActive};
      color: ${theme.colors.greenActive};
      &:hover {
        opacity: 0.7;
        color: ${theme.colors.greenActive};
      }
    }
  `}
  .ant-tag.ant-tag-checkable-checked {
    border: 1px solid
      ${({ theme, $isIndeterminate }) =>
        $isIndeterminate ? theme.colors.greenActive : theme.colors.blueActive};
    color: ${({ theme, $isIndeterminate }) =>
      $isIndeterminate ? theme.colors.greenActive : theme.colors.blueActive};

    &:hover {
      opacity: 0.7;
      color: ${({ theme }) => theme.colors.blueActive};
    }
  }
`

interface GroupProps {
  $isGroupRequired: boolean
}

export const Group = styled.div<GroupProps>`
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;

  .ant-tag {
    margin-right: -2px;
    margin-bottom: -2px;
    border-radius: 0;
    ${({ $isGroupRequired, theme }) =>
      $isGroupRequired &&
      `
			border-color: ${theme.colors.error};
		`}
  }

  .tag-wrapper:first-child .ant-tag {
    border-radius: 4px 0 0 4px;
  }

  .tag-wrapper:last-child .ant-tag {
    border-radius: 0 4px 4px 0;
  }

  .tag-wrapper:only-child .ant-tag {
    border-radius: 4px;
  }

  .ant-tag-checkable-checked {
    position: relative;
  }
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error};
  margin-bottom: 8px;
  font-size: 12px;
  position: absolute;
`

export const SelectWrapper = styled.div`
  width: 100%;

  .ant-select {
    width: 100%;
  }

  .ant-select-selector {
    padding: 6px 6px 0;
  }

  .ant-select-selection-search {
    margin-bottom: 7px;
  }
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  right: 11px;
  top: 6px;
  cursor: pointer;
`

export const CheckboxWrapper = styled.div`
  margin-bottom: 24px;
  font-size: 13px;

  .ant-checkbox + span {
    color: ${({ theme }) => theme.colors.textSecondary};
    margin: auto;

    & svg {
      transform: translateY(2px);
    }
  }
`

export const CustomTagWrapper = styled.div<{ $tagName: string }>`
  .add-new-tag-input {
    width: 100%;
    border: none;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.blueActive};
    }
  }

  .save-and-add-container {
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    visibility: ${(props) => (props.$tagName.length ? "visible" : "hidden")};
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.$tagName.length ? 1 : 0)};

    & button {
      color: ${({ theme }) => theme.colors.blueActive};
      padding: 0 8px;

      &:hover {
        color: ${({ theme }) => theme.colors.blueActive};
      }
    }

    & svg:hover {
      cursor: pointer;
    }
  }
`