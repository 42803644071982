import { AppState } from "store/AppState"

export const selectPublishedPending = (s: AppState) => s.news.published.pending

export const selectPublishedFetchError = (s: AppState) => s.news.published.error

export const selectPublishedPagination = (s: AppState) =>
  s.news.published.pagination

export const selectPublishedList = (s: AppState) => s.news.published.data || []
