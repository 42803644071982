import { styled, css } from "styled-components"

import { device } from "style"

export const responsiveContainerStyle = css`
  @media ${device.tablet} {
    width: 768px;
  }
  @media ${device.laptop} {
    width: 960px;
  }
  @media ${device.laptopM} {
    width: 1100px;
  }
  @media ${device.laptopL} {
    width: 1300px;
  }
`

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  padding: 0 20px;
  ${responsiveContainerStyle}
`
