import { ResultModel } from "types/ApiResponse"
import { Notification } from "types/Notification"

import { request } from "../config"

const PATH_NAME = "Notifications"

export const sendNotification = async (
  notification: Notification
): Promise<ResultModel<Notification>> => request.post(PATH_NAME, notification)

export const markAllAsRead = async (): Promise<ResultModel<Notification>> =>
  request.patch(`${PATH_NAME}/MarkAllAsRead`)

export const markAsRead = async (
  id: string
): Promise<ResultModel<Notification>> =>
  request.patch(`${PATH_NAME}/MarkAsRead/${id}`)

export const removeNotification = async (
  id: string
): Promise<ResultModel<Notification>> => request.delete(`${PATH_NAME}/${id}`)
