import React from "react"

import { IconProps } from "components/Icons/Icons"

const Dropper: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 27"
    fill="#ffffff"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8 11.9583L7.31626 17.4405C7.31626 17.4405 6.57088 18.7709 7.42428 19.6243C8.27768 20.4777 9.59939 19.7236 9.59939 19.7236L15.0823 14.2407L16.1342 15.2925C16.1342 15.2925 16.3227 15.6817 17.1826 14.8217C18.0425 13.9618 17.7537 13.673 17.7537 13.673C17.7537 13.673 16.5831 12.4201 16.5831 12.4201L19.7684 9.23484C19.7684 9.23484 20.3937 8.3715 19.5354 7.51319C18.6771 6.65489 17.8027 7.26921 17.8027 7.26921L14.6175 10.4545L13.3104 9.1474C13.3104 9.1474 13.0686 8.98785 12.2794 9.77703C11.4902 10.5662 11.6498 10.8081 11.6498 10.8081L12.8 11.9583Z"
      stroke={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8255 11.9311L15.1078 14.2134L16.1597 15.2653C16.1597 15.2653 16.3482 15.6544 17.2081 14.7945C18.068 13.9346 17.7792 13.6458 17.7792 13.6458C17.7792 13.6458 16.6086 12.3929 16.6086 12.3929L19.7939 9.20762C19.7939 9.20762 20.4192 8.34427 19.5609 7.48597C18.7026 6.62767 17.8283 7.24199 17.8283 7.24199C17.8283 7.24199 16.8422 8.22809 16.4467 8.62358C15.2442 9.82601 14.643 10.4272 14.643 10.4272L13.336 9.12017C13.336 9.12017 13.0941 8.96063 12.3049 9.74981C11.5157 10.539 11.6753 10.7809 11.6753 10.7809L12.8255 11.9311Z"
      fill={color}
      stroke={color}
    />
  </svg>
)
export default Dropper
