import { AccountingOffice } from "types/AccountingOffice"
import { ResultModel } from "types/ApiResponse"
import { NewsletterPreviewHtmlRequest } from "types/Newsletter"
import {
  ExampleNewsletter,
  NewsletterTemplateOptions,
  NewsPreview,
  TemplatePage
} from "types/Template"

import { request, serialize } from "./../config"

const PATH_NAME = "Template"

export const getNewsletterTemplatesOptions = async (): Promise<
  ResultModel<NewsletterTemplateOptions[]>
> => request.get(`${PATH_NAME}/GetNewsletterTemplatesOptions`)

export const getExampleNewsletter = async (query: {
  templateType: TemplatePage
}): Promise<ResultModel<ExampleNewsletter>> =>
  request.get(`${PATH_NAME}/GetExampleNewsletter${serialize(query)}`)

export const getExampleNewsletterPatch = async (
  payload: Partial<AccountingOffice>
): Promise<ResultModel<ExampleNewsletter>> =>
  request.patch(`${PATH_NAME}/GetExampleNewsletter`, payload)

export const getTemplatePreviewHTML = async (
  payload: NewsletterPreviewHtmlRequest
): Promise<ResultModel<string>> =>
  request.patch(`${PATH_NAME}/CreateNewsletterPreviewHtml`, payload)

export const getNewsPreviewHTML = async (
  payload: NewsPreview
): Promise<ResultModel<string>> =>
  request.patch(`${PATH_NAME}/NewsPreview`, payload)
