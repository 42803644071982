import { TagEnum } from "enums"
import { TagStructure } from "types/Template"

interface useTagChangeHandlerProps {
  formType: "news" | "client"
  isTagValidationOff?: boolean
  selected: number[]
  selectedAlternative: number[]
  onChange: (tags: number[], logicalAlternativeTags?: number[]) => void
}

export default ({
  formType,
  isTagValidationOff,
  selected,
  selectedAlternative,
  onChange
}: useTagChangeHandlerProps) => {
  const onChangeTag = (
    tag: TagStructure,
    group: TagStructure[],
    isChecked: boolean,
    isIndeterminate?: boolean
  ) => {
    if (
      formType === "client" &&
      tag.code === TagEnum.General &&
      selected.includes(TagEnum.General)
    ) {
      return
    }
    if (formType === "news" && tag.code === TagEnum.General) {
      onChange(isChecked ? [TagEnum.General] : [], [])
      return
    }

    const moveToAlternative: number[] = []

    let tags = selected
    let alternativeTags = selectedAlternative

    if (!isChecked) {
      tags = selected.filter((tagId) => tagId !== tag.code)
    } else {
      const hasGroupTags = group.some((groupTag) => {
        if (
          tags.includes(groupTag.code) ||
          alternativeTags.includes(groupTag.code)
        ) {
          moveToAlternative.push(groupTag.code)
          if (formType === "client" && isTagValidationOff) {
            return false
          }

          tags = tags.filter((tagId) => tagId !== groupTag.code)
          return true
        }
      })
      if (formType === "client" && !isTagValidationOff) {
        tags.push(tag.code)
      } else {
        !hasGroupTags ? tags.push(tag.code) : moveToAlternative.push(tag.code)
      }
    }

    if (!isIndeterminate) {
      alternativeTags = selectedAlternative.filter(
        (tagId) => tagId !== tag.code
      )
    } else {
      alternativeTags.push(tag.code)
    }

    if (formType === "news" && tags.length > 1) {
      tags = tags.filter((selectedTag) => selectedTag !== TagEnum.General)
    }

    onChange(
      tags,
      formType === "news"
        ? Array.from(new Set([...alternativeTags, ...moveToAlternative]))
        : []
    )
  }

  return [onChangeTag]
}
