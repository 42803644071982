import React from "react"

import { IconProps } from "components/Icons/Icons"

const Phone: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill={color}
    {...props}
  >
    <path d="M19.5714286,1 L10.4285714,1 C8.53142857,1 7,2.58363636 7,4.54545455 L7,23.4545455 C7,25.4163636 8.53142857,27 10.4285714,27 L19.5714286,27 C21.4685714,27 23,25.4163636 23,23.4545455 L23,4.54545455 C23,2.58363636 21.4685714,1 19.5714286,1 Z M20.7142857,21.0909091 L9.28571429,21.0909091 L9.28571429,4.54545455 L20.7142857,4.54545455 L20.7142857,21.0909091 Z M17.2857143,24.6363636 L12.7142857,24.6363636 L12.7142857,23.4545455 L17.2857143,23.4545455 L17.2857143,24.6363636 Z" />
  </svg>
)
export default Phone
