import { createSelector } from "reselect"

import { AppState } from "../../AppState"

export const selectNewNewsAOPending = (s: AppState) =>
  s.newsAO.newNewsAO.pending

export const selectNewNewsAOFetchError = (s: AppState) =>
  s.newsAO.newNewsAO.error

export const selectNewNewsAOPagination = (s: AppState) =>
  s.newsAO.newNewsAO.pagination

export const selectNewNewsAOListData = (s: AppState) => s.newsAO.newNewsAO.data

export const selectNewNewsAOList = createSelector(
  selectNewNewsAOListData,
  (newNewsList) => newNewsList || []
)

export const selectNewsPossibleToSent = createSelector(
  selectNewNewsAOList,
  (newsList) => newsList.filter((news) => news.hasClients)
)

export const selectIsLoading = (s: AppState) => s.newsAO.newNewsAO.pending
