import { request, defaults, send } from "api/config"
import placeholder from "assets/img/placeholder.png"
import translations from "translations"
import { ResultModel } from "types/ApiResponse"
import {
  BlobInfo,
  ImageUploadSuccessHandler,
  ImageUploadErrorHandler
} from "types/Images"
import { hasServerError } from "utils/errors"

const PATH_NAME = "Image"

export const uploadImage = async (
  image: FormData,
  signal?: any
): Promise<ResultModel<{ id: string } & File>> => {
  const params = defaults.post()
  delete params.headers["Content-Type"]

  return send(defaults.endpoint(`${PATH_NAME}/UploadImage`), {
    method: "POST",
    headers: params.headers,
    body: image,
    signal
  })
}

export const uploadImageRetrieveLink = async (
  blobInfo: BlobInfo,
  success: ImageUploadSuccessHandler,
  failure: ImageUploadErrorHandler
): Promise<void> => {
  const params = defaults.post()
  delete params.headers["Content-Type"]

  const formData = new FormData()
  formData.append("formFile", blobInfo.blob(), blobInfo.filename())

  try {
    const result: ResultModel<{ location?: string }> = await send(
      defaults.endpoint(`${PATH_NAME}/UploadImageRetrieveLink`),
      {
        method: "POST",
        headers: params.headers,
        body: formData
      }
    )
    if (result?.result.location) {
      success(result?.result.location)
    }
  } catch (err) {
    let errMessage = "Nieznany błąd"
    if (hasServerError(err)) {
      errMessage = translations.t(err.errors[0])
    }
    failure(`Coś poszło nie tak: ${errMessage}`)
  }
}

export const getThumbnail = async (imageId: string): Promise<string> =>
  request
    .get(`${PATH_NAME}/${imageId}/Thumbnail`)
    .then((res) => {
      if (!res.result.data) {
        throw new Error()
      }
      return `data:image/jpeg;base64,${res.result.data}`
    })
    .catch(() => placeholder)
