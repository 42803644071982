import { DebouncedFunc } from "lodash"
import throttle from "lodash/throttle"
import { useCallback } from "react"

export function useThrottle<T extends (...args: any) => void>(
  callback: T,
  delay: number,
  dependencies: any[] = []
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFn = useCallback(
    throttle((...args: any) => callback(...args), delay, {
      trailing: false
    }),
    [delay, ...dependencies]
  )

  return throttledFn as T & DebouncedFunc<T>
}
