import { combineReducers, Reducer } from "redux"

import { AppState } from "./AppState"
import { accountingOfficeReducer } from "./accountingOffice"
import { autoNewsletterDeliveryReducer } from "./autoNewsletterDelivery"
import { clientReducer } from "./client"
import { confirmationReducer } from "./confirmations"
import { customTagReducer } from "./customTag"
import { formActivityReducer } from "./formActivity"
import { historyAOReducer } from "./historyAO"
import { labelReducer } from "./label"
import { layoutReducer } from "./layout"
import { leadFormReducer } from "./leadForm"
import { licenseReducer } from "./license"
import { multiStepReducer } from "./multiStep"
import { newsReducer } from "./news"
import { newsAOReducer } from "./newsAO"
import { newsUIReducer } from "./newsUI"
import { newsletterReducer } from "./newsletter"
import { notificationReducer } from "./notifications"
import { pkdReducer } from "./pkd"
import { submissionReducer } from "./submission"
import { templateReducer } from "./template"
import { thumbnailReducer } from "./thumbnail"
import { tourReducer } from "./tour"

const createRootReducer = (): Reducer<AppState> =>
  combineReducers({
    accountingOffice: accountingOfficeReducer,
    autoNewsletterDelivery: autoNewsletterDeliveryReducer,
    client: clientReducer,
    confirmation: confirmationReducer,
    customTag: customTagReducer,
    formActivity: formActivityReducer,
    historyAO: historyAOReducer,
    label: labelReducer,
    layout: layoutReducer,
    leadForm: leadFormReducer,
    license: licenseReducer,
    multiStep: multiStepReducer,
    news: newsReducer,
    newsAO: newsAOReducer,
    newsUI: newsUIReducer,
    newsletter: newsletterReducer,
    notification: notificationReducer,
    pkd: pkdReducer,
    submission: submissionReducer,
    template: templateReducer,
    thumbnail: thumbnailReducer,
    tour: tourReducer
  })

export default createRootReducer
