import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { NewsAO } from "types/News"
import { PromiseAction } from "types/RemoteAction"

export const fetchTestNewsAOAction =
  () =>
  (dispatch: Dispatch): PromiseAction<ResultModel<NewsAO[]>> =>
    dispatch({
      type: "testNewsAO/FETCH",
      promise: Api.fetchNewsAO({ pageIndex: 1, pageSize: 10 }, "Test")
    })
