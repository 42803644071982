import React, { lazy, Suspense, useEffect } from "react"
import { useSelector } from "react-redux"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"

import SessionManager from "api/SessionManager"
import { Loading } from "components/Loading"
import PrivateRoute from "components/PrivateRoute"
import { RoleEnum } from "enums"
import { selectCreatorModeStatus } from "store/sharedSelectors/creatorSelector"
import { dashboardRouteRewriter } from "types/Role"

const ClientPages = lazy(() => import("./ClientPages"))
const CreatorConfigurationPages = lazy(
  () => import("./CreatorConfigurationPages")
)
const MainPages = lazy(() => import("./MainPages"))
const NewsPages = lazy(() => import("./NewsPages"))
const NewsletterPages = lazy(() => import("./NewsletterPages"))
const NotFoundPages = lazy(() => import("./NotFoundPages"))
const ConfirmationPages = lazy(() => import("./ConfirmationPages"))
const NotificationPages = lazy(() => import("./NotificationPages"))
const LicensePages = lazy(() => import("./LicensePages"))
const LeadFormPages = lazy(() => import("./LeadFormPages"))

export const AppPages = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const isCreatorMode = useSelector(selectCreatorModeStatus)
  const role = SessionManager.getSession()?.userRole

  useEffect(() => {
    if (role && !isCreatorMode && location.pathname === "/") {
      navigate(dashboardRouteRewriter[role])
    }
  }, [isCreatorMode, location.pathname, navigate, role])

  useEffect(() => {
    const isCreatorRouteActive = ["/contact"].some((route) =>
      location.pathname.includes(route)
    )
    !isCreatorRouteActive && isCreatorMode && navigate("/creator/contact")
  }, [isCreatorMode, location.pathname, navigate])

  if (isCreatorMode) {
    return (
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route element={<PrivateRoute roles={[RoleEnum.AO_Publisher]} />}>
            <Route path="creator/*" element={<CreatorConfigurationPages />} />
          </Route>
          <Route path="*" element={<NotFoundPages />} />
        </Routes>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<PrivateRoute roles={[RoleEnum.AO_Publisher]} />}>
          <Route path="creator/*" element={<CreatorConfigurationPages />} />
          <Route path="config/*" element={<LeadFormPages />} />
        </Route>

        <Route
          element={
            <PrivateRoute roles={[RoleEnum.AO_Publisher, RoleEnum.AO_User]} />
          }
        >
          <Route path="/*" index element={<MainPages />} />
          <Route path="dashboard/*" element={<MainPages />} />
          <Route path="client/*" element={<ClientPages />} />
          <Route path="newsletter/*" element={<NewsletterPages />} />
          <Route path="confirmations/*" element={<ConfirmationPages />} />
        </Route>

        <Route element={<PrivateRoute roles={[RoleEnum.WK_Publisher]} />}>
          <Route path="notifications/*" element={<NotificationPages />} />
        </Route>

        <Route
          element={
            <PrivateRoute roles={[RoleEnum.WK_Publisher, RoleEnum.WK_Editor]} />
          }
        >
          <Route path="license/*" element={<LicensePages />} />
        </Route>

        <Route path="news/*" element={<NewsPages />} />

        <Route path="*" element={<NotFoundPages />} />
      </Routes>
    </Suspense>
  )
}