import { Badge, Popover } from "antd"
import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import Icon from "components/Icons"
import { fetchNotifications, selectUnreadCount } from "store/notifications"
import { theme } from "style"

import NotificationContent from "./NotificationContent"
import NotificationHeader from "./NotificationHeader"
import { Wrapper } from "./styles"
import useRefreshNotifications from "./useRefreshNotifications"

const UserNotifications = () => {
  const wrapperRef = useRef<any>()
  const dispatch = useDispatch()
  const unreadedCount = useSelector(selectUnreadCount)

  useRefreshNotifications()

  const onOpen = (isOpen: boolean) => {
    if (isOpen) {
      dispatch(fetchNotifications())
    }
  }

  return (
    <Wrapper ref={wrapperRef} data-gtm="gtm-notifications-list">
      <Popover
        content={<NotificationContent />}
        title={<NotificationHeader />}
        trigger="click"
        arrowPointAtCenter
        overlayStyle={{ padding: 0 }}
        getPopupContainer={() => wrapperRef.current}
        placement="bottom"
        onOpenChange={onOpen}
      >
        <Badge
          count={unreadedCount}
          style={{
            fontSize: 12,
            padding: 0,
            boxShadow: "none",
            height: 15,
            lineHeight: "15px",
            marginTop: 7,
            cursor: "pointer"
          }}
        >
          <Icon
            type="notificationOn"
            color={theme.colors.white}
            width="16px"
            height="16px"
            style={{ marginTop: 7 }}
          />
        </Badge>
      </Popover>
    </Wrapper>
  )
}

export default UserNotifications
