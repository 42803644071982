export const theme = {
  colors: {
    black: "#000",
    white: "#fff",

    blueDark: "#00619C",
    blueActive: "#007AC3",
    blueLight: "#E8F0FF",

    greenActive: "#85BC20",

    orange: "#EA8F00",

    error: "#D60413",
    bgRed: "#FFE5E5",

    bgGrey: "#F6F7F8",
    borderGrey: "#F6F7F8",
    borderGreyDarker: "#CCCCCC",
    textPlaceholder: "#CCCCCC",

    inactive: "#F2F2F2",

    textPrimary: "#232323",
    textSecondary: "#474747",
    textAdditional: "#ADADAD"
  },
  paddings: {
    small: "8px",
    normal: "16px",
    medium: "24px",
    large: "32px"
  },
  fontSize: {
    h1: "2rem",
    h2: "1.5rem",
    h3: "1.17rem",
    h4: "1rem",
    h5: "0.83rem",
    h6: "0.67rem"
  }
} as const
