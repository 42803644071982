import React from "react"

import { IconProps } from "components/Icons/Icons"

const Home: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M23.9999999,11.9999999 L19.2,7.19999998 L19.2,1.59999996 L15.2,1.59999996 L15.2,3.19999996 L11.9999999,-5.00000006e-08 L-5.00000006e-08,11.9999999 L1.09999995,13.1 L2.39999996,11.8 L2.39999996,23.9999999 L10.4,23.9999999 L10.4,15.9999999 L13.6,15.9999999 L13.6,23.9999999 L21.6,23.9999999 L21.6,11.9 L22.9,13.2 L23.9999999,11.9999999 Z M16.8,3.19999996 L17.6,3.19999996 L17.6,5.59999997 L16.8,4.79999997 L16.8,3.19999996 Z M20,22.4 L15.2,22.4 L15.2,14.4 L8.79999999,14.4 L8.79999999,22.4 L3.99999997,22.4 L3.99999997,10.3 L12,2.29999996 L20,10.3 L20,22.4 Z" />
  </svg>
)
export default Home
