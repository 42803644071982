import React from "react"

import { IconProps } from "components/Icons/Icons"

const Chat: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M20,0.799999953 L3.99999997,0.799999953 C2.19999996,0.799999953 0.799999953,2.19999996 0.799999953,3.99999997 L0.799999953,15.2 C0.799999953,17 2.19999996,18.4 3.99999997,18.4 L5.59999997,18.4 L5.59999997,23.2 L13.6,18.4 L20,18.4 C21.8,18.4 23.2,17 23.2,15.2 L23.2,3.99999997 C23.2,2.19999996 21.8,0.799999953 20,0.799999953 Z M21.6,15.2 C21.6,16.1 20.9,16.8 20,16.8 L13.2,16.8 L7.19999998,20.4 L7.19999998,16.8 L3.99999997,16.8 C3.09999996,16.8 2.39999996,16.1 2.39999996,15.2 L2.39999996,3.99999997 C2.39999996,3.09999996 3.09999996,2.39999996 3.99999997,2.39999996 L20,2.39999996 C20.9,2.39999996 21.6,3.09999996 21.6,3.99999997 L21.6,15.2 Z" />
  </svg>
)
export default Chat
