import { Dispatch } from "redux"

import { Api } from "api"
import { ResultModel } from "types/ApiResponse"
import { NewsletterWithStatsDto } from "types/Newsletter"
import { PromiseAction } from "types/RemoteAction"

export const fetchNewsletterList =
  (groupId: string, clientId?: string) =>
  (dispatch: Dispatch): PromiseAction<ResultModel<NewsletterWithStatsDto[]>> =>
    dispatch({
      type: "bulletins/FETCH",
      promise: Api.fetchNewsletterList(groupId, clientId)
    })
