import { AppState } from "../AppState"

export const selectCurrentStep = (s: AppState) => s.multiStep.currentStep

export const selectPrevStep = (s: AppState) =>
  s.multiStep.prevSteps.slice(-1).pop()

export const selectStepNames = (s: AppState) => s.multiStep.stepNames

export const selectFormState = (s: AppState) => s.multiStep.formState

export const selectEditModeStatus = (s: AppState) => s.multiStep.editMode

export const selectMultiStepAditionalProps = (s: AppState) =>
  s.multiStep.additionalProps

export const selectPendingInitState = (s: AppState) => s.multiStep.pendingInit
