import { Dispatch } from "redux"

import { Api } from "api"
import {
  fetchAutoNewsletterDeliveryError,
  fetchAutoNewsletterDeliveryRequest,
  fetchAutoNewsletterDeliverySuccess
} from "store/autoNewsletterDelivery/autoNewsletterDeliveryActions"
import { AutoNewsletterDeliveryType } from "types/AutoNewsletterDelivery"
import { showErrorNotification } from "utils/errors"

export const fetchAutoNewsletterDelivery = () => async (dispatch: Dispatch) => {
  dispatch(fetchAutoNewsletterDeliveryRequest())
  try {
    const res = await Api.getAutoNewsletterDelivery()
    dispatch(fetchAutoNewsletterDeliverySuccess(res.result))
  } catch (err) {
    dispatch(fetchAutoNewsletterDeliveryError(err))
    showErrorNotification(
      err,
      "Wystąpił błąd podczas pobierania danych o automatycznej wysyłce. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
    )
  }
}

export const updateAutoNewsletterDelivery =
  (payload: AutoNewsletterDeliveryType) => async (dispatch: Dispatch) => {
    dispatch(fetchAutoNewsletterDeliveryRequest())
    try {
      const res = await Api.updateAutoNewsletterDelivery(payload)
      dispatch(fetchAutoNewsletterDeliverySuccess(res.result))
    } catch (err) {
      dispatch(fetchAutoNewsletterDeliveryError(err))
    }
  }
