import { Reducer } from "redux"

import { ThumbnailState } from "./ThumbnailState"
import { ThumbnailAction } from "./thumbnailActions"

export const initialState: ThumbnailState = {
  fetching: []
}

export const thumbnailReducer: Reducer<ThumbnailState, ThumbnailAction> = (
  state = initialState,
  action
): ThumbnailState => {
  switch (action.type) {
    case "thumbnail/FETCH_THUMBNAIL_REQUEST":
      return {
        ...state,
        fetching: [...state.fetching, action.imageId]
      }
    case "thumbnail/FETCH_THUMBNAIL_SUCCESS":
      return {
        ...state,
        [action.imageId]: action.base64Image,
        fetching: state.fetching.filter((el) => el !== action.imageId)
      }
    case "thumbnail/FETCH_THUMBNAIL_ERROR":
      return {
        ...state,
        fetching: state.fetching.filter((el) => el !== action.imageId)
      }

    case "thumbnail/CLEAR_THUMBNAIL_STATE":
      return initialState
    default:
      return state
  }
}
