import sanitizeHtml from "sanitize-html"

export const cleanHTML = (content: string): string =>
  sanitizeHtml(content, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      "a",
      "h1",
      "h2",
      "img",
      "span",
      "head",
      "body",
      "meta",
      "title",
      "link",
      "style"
    ]),
    allowedAttributes: {
      "*": ["href", "style", "width", "height", "class"],
      img: ["src", "alt"],
      table: ["bgcolor", "border", "cellpadding", "cellspacing"],
      td: ["colspan"],
      meta: ["name", "content", "http-equiv"],
      link: ["rel"],
      a: ["target"]
    },
    allowedSchemes: ["data", "http", "https"]
  })
