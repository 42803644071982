import { AppState } from "store/AppState"

export const selectAllNewsAOFetchError = (s: AppState) =>
  s.newsAO.allNewsAO.error

export const selectAllNewsAOPagination = (s: AppState) =>
  s.newsAO.allNewsAO.pagination

export const selectAllNewsAOList = (s: AppState) =>
  s.newsAO.allNewsAO.data || []

export const selectAllNewsIsLoading = (s: AppState) =>
  s.newsAO.allNewsAO.pending
