import { Divider, Input, Tooltip } from "antd"
import { FormItem } from "formik-antd"
import React, { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import SessionManager from "api/SessionManager"
import { Button } from "components/Button"
import Flexbox from "components/Flexbox"
import Icon from "components/Icons"
import Select, { Option } from "components/Inputs/Select"
import { addCustomTag, selectCustomTagList } from "store/customTag"
import { theme } from "style"
import usePrefix from "utils/usePrefix"

import { Tag } from "./Tag"
import { CustomTagWrapper, SelectWrapper } from "./styles"

interface CustomTagSelectProps {
  selectedTags: string[]
  selectedAlternative: string[]
  formType: "client" | "news"
  onChange: (customTags: string[], alternativeCustomTags?: string[]) => void
}

export declare type TagRendererProps = {
  label: any
  value: any
  disabled: boolean
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  closable: boolean
}

export const CustomTagSelect: React.FC<CustomTagSelectProps> = ({
  selectedTags,
  selectedAlternative,
  formType,
  onChange
}) => {
  const userRole = SessionManager.getUserRole()
  const dispatch = useDispatch()
  const t = usePrefix("Tags")
  const customTags = useSelector(selectCustomTagList)

  const [tagName, setTagName] = useState("")
  const [addedTagName, setAddedTagName] = useState("")

  useEffect(() => {
    const fittedTagValue = customTags.find(
      (tag) => tag.label === addedTagName
    )?.value

    if (fittedTagValue) {
      const updatedTags = Array.from(new Set([...selectedTags, fittedTagValue]))
      onChange(updatedTags, selectedAlternative)
      setAddedTagName("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTagName, customTags])

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setTagName(e.target.value)
    },
    []
  )

  const onTagChange = useCallback(
    (values: string[]) => {
      const tags: string[] = []
      const alternativeTags: string[] = []
      values.forEach((tag) => {
        if (selectedTags.includes(tag)) {
          tags.push(tag)
        } else if (selectedAlternative.includes(tag)) {
          alternativeTags.push(tag)
        } else {
          tags.push(tag)
        }
      })
      onChange(tags, alternativeTags)
    },
    [onChange, selectedAlternative, selectedTags]
  )

  const onSearch = useCallback(
    (value: string) => {
      return customTags.filter((tag) => tag.label.includes(value))
    },
    [customTags]
  )

  const onAddCustomTag = useCallback(async () => {
    dispatch(addCustomTag(tagName))
    setAddedTagName(tagName)
    setTagName("")
  }, [dispatch, tagName])

  const handleChangeOptional =
    (tagValue: string) => (isChecked: boolean, isIndeterminate: boolean) => {
      if (isIndeterminate) {
        onChange(
          selectedTags.filter((tag) => tag !== tagValue),
          [...selectedAlternative, tagValue]
        )
      } else if (formType === "news") {
        onChange(
          [...selectedTags, tagValue],
          selectedAlternative.filter((tag) => tag !== tagValue)
        )
      }
    }

  const CustomTagRenderer = (menu: React.ReactElement) => {
    const t = usePrefix("Tags")

    return (
      <CustomTagWrapper $tagName={tagName}>
        {menu}
        <Divider style={{ margin: "4px 0" }} />
        <div style={{ display: "flex", flexWrap: "nowrap", padding: "0 8px" }}>
          <Input
            placeholder={`${t("add_new_tag")}`}
            value={tagName}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={onNameChange}
            className="add-new-tag-input"
          />
          <Flexbox className="save-and-add-container">
            <Tooltip title={t("select_clear_tooltip")}>
              <Icon
                type="close"
                onClick={() => setTagName("")}
                color={theme.colors.textPlaceholder}
                width="16px"
                height="16px"
                style={{ marginRight: 8 }}
              />
            </Tooltip>
            <Button type="link" onClick={onAddCustomTag} disabled={!tagName}>
              {t("save_and_add")}
            </Button>
          </Flexbox>
        </div>
      </CustomTagWrapper>
    )
  }

  const TagRenderer = ({ value }: TagRendererProps) => {
    const label = customTags.find((tag) => tag.value === value)?.label
    const isChecked = selectedTags.some((tag) => tag === value)
    const isIndeterminate =
      formType === "news" && selectedAlternative.some((tag) => tag === value)

    if (!label) {
      return <></>
    }

    return (
      <Tag
        checked={isChecked}
        isIndeterminate={isIndeterminate}
        closable
        formType={formType}
        onChange={handleChangeOptional(value)}
        onClose={() => {
          onChange(
            selectedTags.filter((tag) => tag !== value),
            selectedAlternative.filter((tag) => tag !== value)
          )
        }}
      >
        {label}
      </Tag>
    )
  }

  if (userRole === "AO_User") {
    return null
  }

  return (
    <FormItem
      name="customTag"
      label={
        <>
          {t("custom_tags_label")}
          <Tooltip title={t("custom_tags_tooltip")}>
            <span>
              <Icon
                type="infoRound"
                width="14px"
                height="14px"
                color={theme.colors.textSecondary}
                style={{ marginLeft: 4, transform: "translateY(2px)" }}
              />
            </span>
          </Tooltip>
        </>
      }
    >
      <SelectWrapper>
        <Select
          mode="multiple"
          placeholder={t("add")}
          showArrow
          allowClear
          dropdownRender={CustomTagRenderer}
          onDropdownVisibleChange={() => setTagName("")}
          tagRender={TagRenderer}
          onSearch={onSearch}
          placement="topLeft"
          filterOption={(input, option) => {
            const label = option?.label as string
            return label.toLowerCase().includes(input)
          }}
          onChange={onTagChange}
          value={[...selectedTags, ...selectedAlternative]}
        >
          {customTags.map((tag) => (
            <Option key={tag.value} value={tag.value} label={tag.label}>
              {tag.label}
            </Option>
          ))}
        </Select>
      </SelectWrapper>
    </FormItem>
  )
}