import React from "react"

import { IconProps } from "components/Icons/Icons"

const Users: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M12.096,15.936 C13.152,14.976 13.824,13.536 13.824,12 C13.824,9.02399999 11.424,6.62399998 8.44799999,6.62399998 C5.47199997,6.62399998 3.07199996,9.02399999 3.07199996,12 C3.07199996,13.536 3.74399997,14.976 4.79999997,15.936 C1.91999996,17.28 -5.00000006e-08,20.16 -5.00000006e-08,23.52 L16.896,23.52 C16.896,20.16 14.976,17.28 12.096,15.936 Z M4.60799997,12 C4.60799997,9.88799999 6.33599998,8.15999998 8.44799999,8.15999998 C10.56,8.15999998 12.288,9.88799999 12.288,12 C12.288,14.112 10.56,15.84 8.44799999,15.84 C6.33599998,15.84 4.60799997,14.112 4.60799997,12 Z M1.72799996,21.984 C2.30399996,19.584 4.03199997,17.664 6.33599998,16.896 C7.00799998,17.184 7.67999998,17.376 8.44799999,17.376 C9.21599999,17.376 9.88799999,17.184 10.56,16.896 C12.864,17.664 14.688,19.584 15.168,21.984 L1.72799996,21.984 Z M14.592,2.01599996 C16.704,2.01599996 18.432,3.74399997 18.432,5.85599997 C18.432,7.87199998 16.896,9.50399999 14.976,9.69599999 C15.168,10.176 15.264,10.656 15.36,11.232 C15.84,11.136 16.32,11.04 16.8,10.848 C19.104,11.616 20.928,13.536 21.408,15.936 L14.88,15.936 C15.456,16.416 15.936,16.896 16.416,17.472 L23.136,17.472 C23.136,14.112 21.216,11.232 18.336,9.88799999 C19.392,8.92799999 20.064,7.48799998 20.064,5.95199997 C20.064,2.97599996 17.664,0.575999952 14.688,0.575999952 C11.904,0.479999952 9.59999999,2.49599996 9.31199999,5.18399997 C9.79199999,5.27999997 10.368,5.37599997 10.848,5.56799997 C10.944,3.55199996 12.576,2.01599996 14.592,2.01599996 Z" />
  </svg>
)
export default Users
