import React from "react"
import { Helmet } from "react-helmet"

type Props = {
  title: string
  favicon: any
}

const Meta = ({ title, favicon }: Props) => {
  return (
    <Helmet>
      <title>{title}</title>
      <link
        id="favicon"
        rel="icon"
        type="image/svg"
        href={favicon}
        sizes="16x16"
      />
    </Helmet>
  )
}

export default Meta
