import { request, serialize } from "api/config"
import { ResultModel } from "types/ApiResponse"
import { Client } from "types/Client"
import {
  NewsletterGroupResponseDto,
  NewsletterGroupWithSendDateResponseDto
} from "types/Newsletter"

const PATH_NAME = "TestDispatch"

export const addTestClient = async (
  client: Client,
  isForce?: boolean
): Promise<ResultModel<NewsletterGroupResponseDto[]>> =>
  request.post(
    `${PATH_NAME}/Client${serialize({ ignoreTagsValidation: isForce })}`,
    client
  )

export const moveNextStage = async (): Promise<
  ResultModel<NewsletterGroupWithSendDateResponseDto>
> => request.patch(`${PATH_NAME}/MoveNext`)

export const disposeTestDispatch = async (): Promise<
  ResultModel<NewsletterGroupWithSendDateResponseDto>
> => request.patch(`${PATH_NAME}/Dispose`)
