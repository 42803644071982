import { combineReducers } from "redux"

import { NewsletterState } from "./NewsletterReducerState"
import { bulletinsReducer } from "./bulletins"
import { newsletterGroupsReducer } from "./newsletterGroups"
import { newsletterPreviewHtmlReducer } from "./newsletterPreviewHtml"

export const newsletterReducer = combineReducers<NewsletterState>({
  newsletterGroups: newsletterGroupsReducer,
  bulletins: bulletinsReducer,
  newsletterPreviewHtml: newsletterPreviewHtmlReducer
})
