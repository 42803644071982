import { Select, Tooltip } from "antd"
import { FormItem } from "formik-antd"
import type { CustomTagProps } from "rc-select/lib/BaseSelect"
import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { styled } from "styled-components"

import { Tag } from "components/Tag/Tag"
import { AppState } from "store/AppState"
import { selectPKDList } from "store/pkd"
import { Text, theme } from "style"
import usePrefix from "utils/usePrefix"

interface PkdSelectProps {
  pkds: string[]
  logicalAlternativePkds: string[]
  formType: "client" | "news"
  isDisabled: boolean
  setValues: (pkds: string[], logicalAlternativePkds: string[]) => void
}

export const PkdSelect = ({
  pkds,
  logicalAlternativePkds,
  formType,
  isDisabled,
  setValues
}: PkdSelectProps) => {
  const t = usePrefix("Tags")
  const allPKDs = useSelector((s: AppState) => selectPKDList(s, formType))
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  useEffect(() => {
    setSelectedValues([...pkds, ...logicalAlternativePkds])
  }, [logicalAlternativePkds, pkds])

  const handleSelectChange = useCallback(
    (_value: unknown, options: any) => {
      const optionValues: string[] = options.map((opt: any) => opt.value)

      const selectedPkds: string[] = []
      const selectedAlternativeTags: string[] = []
      optionValues.forEach((pkd) => {
        if (pkds.includes(pkd)) {
          selectedPkds.push(pkd)
        } else if (logicalAlternativePkds.includes(pkd)) {
          selectedAlternativeTags.push(pkd)
        } else {
          selectedPkds.push(pkd)
        }
      })

      setSelectedValues([...selectedPkds, ...selectedAlternativeTags])
      setValues(selectedPkds, selectedAlternativeTags)
    },
    [logicalAlternativePkds, pkds, setValues]
  )

  const handleChangeOptional = useCallback(
    (pkd: string) => async (isChecked: boolean, isIndeterminate: boolean) => {
      if (isIndeterminate) {
        setValues(
          pkds.filter((selected) => selected !== pkd),
          [...logicalAlternativePkds, pkd]
        )
      } else if (formType === "news") {
        setValues(
          [...pkds, pkd],
          logicalAlternativePkds.filter((selected) => selected !== pkd)
        )
      }
    },
    [formType, logicalAlternativePkds, pkds, setValues]
  )

  const tagRender = useCallback(
    (props: CustomTagProps) => {
      const { label, value, onClose } = props
      const isChecked = pkds.some((tag) => tag === value)
      const isIndeterminate =
        formType === "news" &&
        logicalAlternativePkds.some((tag) => tag === value)

      return (
        <Tag
          checked={isChecked}
          isIndeterminate={isIndeterminate}
          closable
          formType={formType}
          tooltip={typeof label === "string" ? label : ""}
          onChange={handleChangeOptional(value)}
          onClose={onClose}
        >
          {value}
        </Tag>
      )
    },
    [formType, handleChangeOptional, logicalAlternativePkds, pkds]
  )

  return (
    <FormItem name="pkds" label={t("pkd_label")}>
      <StyledSelect
        placeholder={t("select_placeholder")}
        mode="multiple"
        virtual={true}
        value={selectedValues}
        onChange={handleSelectChange}
        tagRender={tagRender}
        filterOption={(input, option) => {
          const label = typeof option?.label === "string" ? option?.label : ""
          return label.toLowerCase().includes(input.toLowerCase())
        }}
        showSearch
        showArrow
        allowClear
        disabled={isDisabled}
        optionLabelProp="label"
      >
        {allPKDs.map(({ label, value, level }) => {
          const pkdLabel = `${value} - ${label}`
          return (
            <Select.Option key={value} value={value} label={pkdLabel}>
              <Tooltip title={pkdLabel} placement="topLeft">
                <Text
                  key={value}
                  style={{
                    color: theme.colors.textSecondary,
                    paddingLeft: `${formType === "news" && (level ?? 1) * 10}px`,
                    fontWeight: selectedValues.includes(value) ? 500 : 400
                  }}
                >
                  {pkdLabel}
                </Text>
              </Tooltip>
            </Select.Option>
          )
        })}
      </StyledSelect>
    </FormItem>
  )
}

export const StyledSelect = styled(Select)`
  & .ant-select-selection-overflow {
    margin-top: 8px;
  }
`