import React from "react"

import { IconProps } from "components/Icons/Icons"

const DownArrow: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <polygon points="19.7 13.3 12.8 20.2 12.8 0.799999953 11.2 0.799999953 11.2 20.2 4.29999997 13.3 3.19999996 14.4 12 23.2 20.8 14.4" />
  </svg>
)
export default DownArrow
