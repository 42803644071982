import React from "react"

import { IconProps } from "components/Icons/Icons"

const Loading: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    className="anticon-spin"
    {...props}
  >
    <path d="M12,1.59999996 C6.29999998,1.59999996 1.59999996,6.29999998 1.59999996,12 C1.59999996,17.7 6.29999998,22.4 12,22.4 C17.7,22.4 22.4,17.7 22.4,12 L20.8,12 C20.8,16.9 16.9,20.8 12,20.8 C7.09999996,20.8 3.19999996,16.9 3.19999996,12 C3.19999996,7.09999996 7.09999996,3.19999996 12,3.19999996 L12,1.59999996 Z" />
  </svg>
)
export default Loading
