// eslint-disable-next-line import/no-named-as-default
import produce from "immer"
import { Reducer } from "redux"

import { ClientState } from "./ClientState"
import { ClientActions } from "./clientActions"

export const initialState: ClientState = {
  data: [],
  pagination: undefined,
  isFetching: false,
  isFetched: false,
  totalClients: 0
}

export const clientReducer: Reducer<ClientState, ClientActions> = (
  state = initialState,
  action
): ClientState =>
  produce(state, (draft) => {
    switch (action.type) {
      case "clients/FETCH_CLIENTS":
        draft.isFetching = true
        break
      case "clients/FETCH_CLIENTS_SUCCESS":
        draft.data = action.clients
        draft.pagination = action.pagination
        draft.isFetching = false
        draft.isFetched = true
        if (action.canUpdateTotal) {
          draft.totalClients = action.pagination?.total || 0
        }
        break
      case "clients/FETCH_CLIENTS_ERROR":
        draft.isFetching = false
        break
    }
  })
