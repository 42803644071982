import { Dispatch } from "redux"

import { AppState } from "store/AppState"
import { selectNewNewsAOList } from "store/newsAO/new"
import { selectAllSelected } from "store/newsUI"

export const toggleSelectNews = (id?: string) => (
  dispatch: Dispatch<any>,
  getState: () => AppState
) => {
  if (!id) return
  const selected = selectAllSelected(getState())

  if (selected.some((newsId) => newsId === id)) {
    const newSelected = selected.filter((newsId) => newsId !== id)
    if (newSelected.length === 0) {
      dispatch(setNewsConfirmation(null))
    }
    return dispatch(setSelectedNews(newSelected))
  }
  dispatch(setSelectedNews([...selected, id]))
  dispatch(setNewsConfirmation(null))
}

export const setSelectedNews = (selectedNews: string[], isSorted = false) => (
  dispatch: Dispatch,
  getState: () => AppState
) => {
  if (isSorted) {
    return dispatch(setNews(selectedNews))
  }
  const allNews = selectNewNewsAOList(getState())
  const sortedNews = selectedNews.sort((a, b) => {
    return allNews.findIndex((news) => news.id === a) >
      allNews.findIndex((news) => news.id === b)
      ? 1
      : -1
  })

  dispatch(setNews(sortedNews))
}

const setNews = (selectedNews: string[]) =>
  ({
    type: "newsUI/SET_SELECTED_NEWS",
    selectedNews
  } as const)

export const setNewsConfirmation = (newsId: string | null) =>
  ({
    type: "newsUI/SET_NEWS_CONFIRMATION",
    newsId
  } as const)

export const clearNewsUIData = () =>
  ({
    type: "newsUI/CLEAR_NEWS_UI_DATA"
  } as const)

export type NewsUIAction = ReturnType<
  typeof setNews | typeof setNewsConfirmation | typeof clearNewsUIData
>
