import { useState } from "react"

import { PaginationRequest } from "types/ApiResponse"

export const DEFAULT_PAGE_SIZE = 25

export const usePagination = () => {
  const [pagination, setPagination] = useState<PaginationRequest>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  })

  return [pagination, setPagination] as const
}
