import React from "react"

import { IconProps } from "components/Icons/Icons"

const Receivers: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill={color}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.36515 11.2121C9.09544 10.5427 9.56017 9.53866 9.56017 8.46768C9.56017 6.39266 7.90041 4.71926 5.84232 4.71926C3.78423 4.71926 2.12448 6.39266 2.12448 8.46768C2.12448 9.53866 2.58921 10.5427 3.3195 11.2121C1.3278 12.1492 0 14.1572 0 16.5H11.6846C11.6846 14.1572 10.3568 12.1492 8.36515 11.2121ZM3.18672 8.46768C3.18672 6.99509 4.38174 5.79024 5.84232 5.79024C7.30291 5.79024 8.49793 6.99509 8.49793 8.46768C8.49793 9.94027 7.30291 11.1451 5.84232 11.1451C4.38174 11.1451 3.18672 9.94027 3.18672 8.46768ZM1.19502 15.429C1.59336 13.7556 2.78838 12.4169 4.38174 11.8814C4.84647 12.0822 5.3112 12.2161 5.84232 12.2161C6.37344 12.2161 6.83817 12.0822 7.3029 11.8814C8.89627 12.4169 10.1577 13.7556 10.4896 15.429H1.19502ZM10.0913 1.50633C11.5519 1.50633 12.7469 2.71118 12.7469 4.18377C12.7469 5.58943 11.6846 6.72734 10.3568 6.86122C10.4896 7.1959 10.556 7.53057 10.6224 7.93219C10.9544 7.86525 11.2863 7.79832 11.6183 7.66445C13.2116 8.19994 14.473 9.53866 14.805 11.2121H10.2905C10.6888 11.5467 11.0207 11.8814 11.3527 12.283H16C16 9.94027 14.6722 7.93219 12.6805 6.99509C13.4108 6.32573 13.8755 5.32169 13.8755 4.25071C13.8755 2.17569 12.2158 0.502294 10.1577 0.502294C8.23236 0.435358 6.639 1.84101 6.43983 3.71522C6.77178 3.78216 7.17012 3.84909 7.50207 3.98297C7.56846 2.57731 8.69709 1.50633 10.0913 1.50633Z"
      fill={color}
    />
  </svg>
)
export default Receivers
