import { Tooltip } from "antd"
import { FormikProps } from "formik"
import { Checkbox, FormItem } from "formik-antd"
import React from "react"
import { useSelector } from "react-redux"

import SessionManager from "api/SessionManager"
import Icon from "components/Icons"
import Select from "components/Inputs/Select"
import { TagEnum } from "enums"
import { selectFormActivityList } from "store/formActivity"
import { selectLabelList } from "store/label"
import { Header, theme } from "style"
import { Tags } from "types/Template"
import usePrefix from "utils/usePrefix"

import { CustomTagSelect } from "./CustomTagSelect"
import { PkdSelect } from "./PkdSelect"
import { TagGroup } from "./TagGroup"
import {
  CheckboxWrapper,
  ErrorMessage,
  PkdAndActivityFormWrapper
} from "./styles"

interface TagSectionProps {
  formType: "news" | "client"
  disabled?: boolean
  isTagValidationDisabled?: boolean
}

export const TagSection = <T extends Tags>({
  formType,
  disabled = false,
  isTagValidationDisabled,
  ...formikProps
}: FormikProps<T> & TagSectionProps) => {
  const t = usePrefix("Tags")
  const allTags = useSelector(selectLabelList)
  const allActivityForms = useSelector(selectFormActivityList)
  const isAORole = SessionManager.getAORole()
  const realm = SessionManager.getRealm()

  const {
    onActivityFormChange,
    onPkdChange,
    onTagsChange,
    onCustomTagsChange
  } = useTagSetField(formikProps, formType, disabled)

  const {
    values: {
      activityFormCode,
      pkds,
      tags,
      customTags,
      isTagValidationOff,
      logicalAlternativeTags,
      logicalAlternativeCustomTags,
      logicalAlternativePkds
    },
    touched,
    errors
  } = formikProps

  return (
    <>
      <Header
        className={touched.tags && errors.tags ? "ant-form-item-has-error" : ""}
      >
        <div className="ant-form-item-label" style={{ width: "100%" }}>
          <label className="ant-form-item-required">
            {t("section_tags_label")}
          </label>
        </div>
      </Header>
      {!isTagValidationDisabled && (
        <PkdAndActivityFormWrapper>
          <FormItem name="activityFormCode" label={t("activity_form_label")}>
            <Select
              placeholder={t("select_placeholder")}
              value={activityFormCode || undefined}
              onChange={onActivityFormChange}
              options={allActivityForms}
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "").includes(input)
              }
              showArrow
              showSearch
              allowClear
              style={{ width: "100%", zIndex: 10 }}
              optionFilterProp="children"
              optionLabelProp="label"
              disabled={disabled}
            />
          </FormItem>

          <PkdSelect
            pkds={pkds || []}
            logicalAlternativePkds={logicalAlternativePkds || []}
            formType={formType}
            isDisabled={disabled}
            setValues={onPkdChange}
          />
        </PkdAndActivityFormWrapper>
      )}

      {realm === "Accountancy" && formType === "client" && (
        <CheckboxWrapper>
          <Checkbox
            name="isTagValidationOff"
            disabled={isTagValidationDisabled}
          >
            {t("tag_validation_checkbox")}
            <Tooltip title={t("tag_validation_checkbox_tooltip")}>
              <span>
                <Icon
                  type="infoRound"
                  width="14px"
                  height="14px"
                  color={theme.colors.textSecondary}
                  style={{ marginLeft: 4, transform: "translateY(2px)" }}
                />
              </span>
            </Tooltip>
          </Checkbox>
        </CheckboxWrapper>
      )}

      <div data-tour="add-client-step-3">
        {Object.values(allTags)?.map((tagGroup, index) => (
          <TagGroup
            key={tagGroup.header ?? `group-${index}`}
            tags={tagGroup}
            selected={tags}
            selectedAlternative={logicalAlternativeTags}
            onChange={onTagsChange}
            formType={formType}
            isTagValidationOff={isTagValidationOff}
          />
        ))}
        {isAORole && (
          <CustomTagSelect
            selectedTags={customTags || []}
            selectedAlternative={logicalAlternativeCustomTags || []}
            onChange={onCustomTagsChange}
            formType={formType}
          />
        )}
        {touched.tags && <ErrorMessage>{errors.tags as string}</ErrorMessage>}
      </div>
    </>
  )
}

const useTagSetField = <T extends Tags>(
  formikProps: FormikProps<T>,
  formType: "news" | "client",
  disabled?: boolean
) => {
  const { values, setValues, setFieldTouched } = formikProps

  const onActivityFormChange = async (value: unknown) => {
    let newTags = [...(values.tags || [])]

    if (formType === "news" && values.tags?.includes(TagEnum.General)) {
      newTags = []
    }

    await setFieldTouched("tags", true)
    await setValues({
      ...values,
      activityFormCode: value || null,
      tags: newTags
    })
  }

  const onPkdChange = (pkds: string[], logicalAlternativePkds: string[]) => {
    setValues({
      ...values,
      tags:
        values.tags?.includes(TagEnum.General) && formType === "news"
          ? []
          : values.tags,
      pkds,
      logicalAlternativePkds
    })
  }

  const onTagsChange = async (
    tags: number[],
    logicalAlternativeTags?: number[]
  ) => {
    if (disabled) return

    setFieldTouched("tags", true)
    setValues({
      ...values,
      activityFormCode:
        formType === "news" && tags.includes(TagEnum.General)
          ? null
          : values.activityFormCode,
      tags,
      customTags:
        formType === "news" && tags.includes(TagEnum.General)
          ? []
          : values.customTags,
      pkds:
        formType === "news" && tags.includes(TagEnum.General)
          ? []
          : values.pkds,
      logicalAlternativeTags: logicalAlternativeTags,
      logicalAlternativeCustomTags:
        formType === "news" && tags.includes(TagEnum.General)
          ? []
          : values.logicalAlternativeCustomTags,
      logicalAlternativePkds:
        formType === "news" && tags.includes(TagEnum.General)
          ? []
          : values.logicalAlternativePkds
    })
  }

  const onCustomTagsChange = async (
    customTags: string[],
    logicalAlternativeCustomTags?: string[]
  ) => {
    if (disabled) return
    setFieldTouched("tags", true)
    setValues({
      ...values,
      tags:
        formType === "news" && values.tags?.includes(TagEnum.General)
          ? []
          : values.tags,
      customTags,
      logicalAlternativeCustomTags
    })
  }

  return {
    onActivityFormChange,
    onPkdChange,
    onTagsChange,
    onCustomTagsChange
  }
}