import { Reducer } from "redux"

import { MulitStepState } from "./MulitStepState"
import { MulitStepAction } from "./mulitStepActions"

export const initialState: MulitStepState = {
  currentStep: 0,
  prevSteps: [],
  stepNames: [],
  additionalProps: null,
  editMode: false,
  pendingInit: true
}

export const multiStepReducer: Reducer<MulitStepState, MulitStepAction> = (
  state = initialState,
  action
): MulitStepState => {
  switch (action.type) {
    case "multiStep/SET_CURRENT_STEP":
      return {
        ...state,
        currentStep: action.step,
        prevSteps: [...state.prevSteps, state.currentStep],
        editMode: false
      }
    case "multiStep/SET_PREV_STEP":
      return {
        ...state,
        currentStep: state.currentStep - 1,
        prevSteps:
          state.currentStep < 2
            ? []
            : [...state.prevSteps.slice(0, state.currentStep - 1)],
        editMode: false
      }
    case "multiStep/INIT_MULTI_STEP":
      return {
        ...state,
        currentStep: 0,
        stepNames: action.stepNames,
        formState: undefined,
        pendingInit: false
      }
    case "multiStep/SET_MULTI_STEP_FORM_VALUES":
      return {
        ...state,
        formState: {
          ...state.formState,
          ...action.values
        }
      }
    case "multiStep/SET_STEP_IN_EDIT_MODE":
      return {
        ...state,
        currentStep: action.step,
        additionalProps: action.additionalProps,
        editMode: true
      }
    case "multiStep/CLEAR_MULTI_STEP_DATA":
      return initialState
    default:
      return state
  }
}
