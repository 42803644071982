import { styled } from "styled-components"

export const AvatarWrapper = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 9px;
  right: -5px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.blueActive};
  padding: 3px;

  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: scaleY(0.8) scaleX(1.2);
  }
`
