import { notification } from "antd"
import { Formik } from "formik"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import { Api } from "api"
import { AutoNewsletterDeliveryModal } from "components/Modal/AutoNewsletterDelivery/AutoNewsletterDeliveryForm"
import { useInitialValues } from "components/Modal/AutoNewsletterDelivery/form"
import { fetchAutoNewsletterDelivery } from "store/autoNewsletterDelivery"
import { AutoNewsletterDeliveryValues } from "types/AutoNewsletterDelivery"
import { showErrorNotification } from "utils/errors"
import usePrefix from "utils/usePrefix"

export interface AutoNewsletterDelivery {
  isOpen: boolean
  onClose: () => void
}

export const AutoNewsletterDelivery = ({
  isOpen,
  onClose
}: AutoNewsletterDelivery) => {
  const t = usePrefix("AutoDelivery")
  const dispatch = useDispatch()
  const initialValues = useInitialValues()

  const onSubmit = useCallback(
    async (values: AutoNewsletterDeliveryValues) => {
      try {
        await Api.updateAutoNewsletterDelivery({
          ...values,
          autoNewsletterDeliveryActive: true
        })
        dispatch(fetchAutoNewsletterDelivery())

        notification.success({
          message: t("success"),
          description: t("request_save_success")
        })
      } catch (err) {
        showErrorNotification(err, t("request_save_error"))
      }
    },
    [dispatch, t]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
      validateOnBlur={false}
    >
      {(formikProps) => (
        <AutoNewsletterDeliveryModal
          formikProps={formikProps}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Formik>
  )
}