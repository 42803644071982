import { useRef } from "react"
import { useDrop, DropTargetMonitor, XYCoord, useDrag } from "react-dnd"

interface UseDraggableSortProps {
  id: string | number
  index: number
  onMoveElement: (dragIndex: number, hoverIndex: number) => void
}

export const useDraggableSort = ({
  id,
  index,
  onMoveElement
}: UseDraggableSortProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop({
    accept: "draggableItem",
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      onMoveElement(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    item: { id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    type: "draggableItem"
  })

  drag(drop(ref))

  return {
    ref,
    isDragging
  }
}
