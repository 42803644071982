import { Button as AntdButton, ButtonProps } from "antd"
import React, { PropsWithChildren } from "react"

type Props = ButtonProps & PropsWithChildren

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { children, ...rest } = props

    return (
      <AntdButton {...rest} ref={ref}>
        {children}
      </AntdButton>
    )
  }
)
