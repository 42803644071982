import { styled, css } from "styled-components"

import { theme } from "style"

export const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.h1};
  ${({ color }) => color && `color: ${color}`}
`

export const H2 = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.h2};
  ${({ color }) => color && `color: ${color}`}
`

export const H3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.h3};
  ${({ color }) => color && `color: ${color}`}
`

export const H4 = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.h4};
  ${({ color }) => color && `color: ${color}`}
`

export const H5 = styled.h5`
  font-size: ${({ theme }) => theme.fontSize.h5};
  ${({ color }) => color && `color: ${color}`}
`

export const H6 = styled.h6`
  font-size: ${({ theme }) => theme.fontSize.h6};
  ${({ color }) => color && `color: ${color}`}
`

interface ParagraphProps {
  $fontSize?: number
  $padding?: string
  $textAlign?: string
  $margin?: string
  $weight?: number
  color?: string
  onClick?: () => void
}

export const Paragraph = styled.p<ParagraphProps>`
  word-break: break-word;
  white-space: pre-line;
  color: ${({ theme, color }) => color || theme.colors.textSecondary};
  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `}
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

    ${({ $fontSize }) =>
    $fontSize &&
    `
		font-size: ${$fontSize}px;
	`}

    ${({ $padding }) =>
    $padding &&
    `
		padding: ${$padding};
	`}

    ${({ $margin }) =>
    $margin &&
    `
		margin: ${$margin};
	`}

    ${({ $weight }) =>
    $weight &&
    `
		font-weight: ${$weight};
	`}
`

export const Header = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 1em;
`

export const SectionHeader = styled(H4)`
  color: ${({ theme }) => theme.colors.textSecondary};
`

export const ListMetaTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.h4};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-bottom: 8px;
  ${({ color }) => color && `color: ${color}`}
`

export type TextProps = {
  $type?: FontType
  $variant?: VariantType
  $color?: keyof typeof theme.colors
  $nowrap?: boolean
}

type FontType = "small" | "normal" | "large" | "xLarge" | "xxLarge" | "xxxLarge"
type VariantType = "light" | "normal" | "bold" | "bolder"

const fontType: Record<FontType, ReturnType<typeof css>> = {
  small: css`
    font-size: 10px;
  `,
  normal: css`
    font-size: 12px;
  `,
  large: css`
    font-size: 14px;
    line-height: 18px;
  `,
  xLarge: css`
    font-size: 16px;
    line-height: 18px;
  `,
  xxLarge: css`
    font-size: 18px;
    line-height: 24px;
  `,
  xxxLarge: css`
    font-size: 20px;
    line-height: 24px;
  `
}

const fontVariant: Record<VariantType, ReturnType<typeof css>> = {
  light: css`
    font-weight: 300;
  `,
  normal: css`
    font-weight: 400;
  `,
  bold: css`
    font-weight: 500;
  `,
  bolder: css`
    font-weight: 700;
  `
}

export const Text = styled.span<TextProps>`
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  white-space: ${({ $nowrap }) => $nowrap && "nowrap"};
  color: ${({ theme, $color = "textAdditional" }) => theme.colors[$color]};

  ${({ $type = "normal" }) => fontType[$type]}
  ${({ $variant = "normal" }) => fontVariant[$variant]}
`