import { Dispatch } from "redux"

import { Api } from "api"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import { News } from "types/News"
import { PromiseAction } from "types/RemoteAction"

export const fetchToPublishNews =
  (pagination: PaginationRequest) =>
  (dispatch: Dispatch): PromiseAction<ResultModel<News[]>> =>
    dispatch({
      type: "toPublish/FETCH",
      promise: Api.fetchNews("ToPublish", pagination)
    })
