import { SortOrder } from "antd/lib/table/interface"

import { serialize, request } from "api/config"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import { AccountLicense } from "types/Technical"

const PATH_NAME = "Technical"

export const getBorgLogOnUrl = async (): Promise<ResultModel<string>> =>
  request.get(`${PATH_NAME}/BorgLogOnUrl`)

export const getExpiredAccounts = async (
  pagination: PaginationRequest,
  sorting: SortOrder,
  phrase?: string
): Promise<ResultModel<AccountLicense[]>> =>
  request.get(
    `${PATH_NAME}/AccountingOffice/ExpiredAccounts${serialize({
      phrase,
      ...pagination,
      subscriptionEndDateSortingOrder:
        sorting === "ascend" ? "Ascending" : "Descending"
    })}`
  )

export const getTestAccounts = async (
  pagination: PaginationRequest,
  sorting: SortOrder,
  phrase?: string
): Promise<ResultModel<AccountLicense[]>> =>
  request.get(
    `${PATH_NAME}/AccountingOffice/TestAccount${serialize({
      phrase,
      ...pagination,
      subscriptionEndDateSortingOrder:
        sorting === "ascend" ? "Ascending" : "Descending"
    })}`
  )

export const removeAccount = async (
  borgId: number
): Promise<ResultModel<AccountLicense[]>> =>
  request.delete(`${PATH_NAME}/AccountingOffice/${borgId}`)

export const getTranslations = async (): Promise<any> =>
  request.get(`${PATH_NAME}/Translations`)