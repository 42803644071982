import React from "react"

import { IconProps } from "components/Icons/Icons"

const AutoSent: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill={color}
    {...props}
  >
    <path
      d="M4.19238 19.0367V5.88281L16.4732 11.1328H16.4039C16.1026 11.1585 15.7994 11.2027 15.4943 11.2655C15.1892 11.3283 14.9232 11.4123 14.6962 11.5174L5.19238 7.49821V11.3828L10.0386 12.4982L5.19238 13.6136V17.4982L11.227 14.929C11.145 15.12 11.077 15.3293 11.0232 15.5569C10.9693 15.7844 10.936 15.9764 10.9232 16.1328V16.1444L4.19238 19.0367ZM16.8078 21.1136C15.6937 21.1136 14.7485 20.7255 13.9722 19.9492C13.1959 19.1729 12.8078 18.2277 12.8078 17.1136C12.8078 15.9995 13.1959 15.0543 13.9722 14.278C14.7485 13.5017 15.6937 13.1136 16.8078 13.1136C17.9219 13.1136 18.8671 13.5017 19.6434 14.278C20.4196 15.0543 20.8078 15.9995 20.8078 17.1136C20.8078 18.2277 20.4196 19.1729 19.6434 19.9492C18.8671 20.7255 17.9219 21.1136 16.8078 21.1136ZM18.4578 19.3098L19.0039 18.7636L17.1924 16.9521V14.229H16.4232V17.2751L18.4578 19.3098ZM5.19238 14.929V7.49821V17.4982V14.929Z"
      fill={color}
    />
  </svg>
)
export default AutoSent
