import React from "react"

import { IconProps } from "components/Icons/Icons"

const Stop: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M16.8,0.799999953 L7.19999998,0.799999953 L0.799999953,7.19999998 L0.799999953,16.8 L7.19999998,23.2 L16.8,23.2 L23.2,16.8 L23.2,7.19999998 L16.8,0.799999953 Z M21.6,16.1 L16.1,21.6 L7.89999996,21.6 L2.39999996,16.1 L2.39999996,7.89999996 L7.89999996,2.39999996 L16.2,2.39999996 L21.7,7.89999996 L21.7,16.1 L21.6,16.1 Z M15.7,7.19999998 L12,10.9 L8.29999998,7.19999998 L7.19999998,8.29999998 L10.9,12 L7.19999998,15.7 L8.29999998,16.8 L12,13.1 L15.7,16.8 L16.8,15.7 L13.1,12 L16.8,8.29999998 L15.7,7.19999998 Z" />
  </svg>
)

export default Stop
