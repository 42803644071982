import { Dispatch } from "redux"

import { Api } from "api"
import { AppState } from "store/AppState"

import {
  fetchNotificationsRequest,
  fetchNotificationsSuccess,
  fetchNotificationsError,
  markAllAsReadRequest,
  markAllAsReadSuccess,
  markAllAsReadError,
  markAsReadRequest,
  markAsReadSuccess,
  markAsReadError,
  removeNotificationRequest,
  removeNotificationSuccess,
  removeNotificationError
} from "./actions"
import { selectIsFetching } from "./selectors"

export const fetchNotifications =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const isFetching = selectIsFetching(getState())
    if (!isFetching) {
      dispatch(fetchNotificationsRequest())
      try {
        const res = await Api.getUserNotifications()
        dispatch(fetchNotificationsSuccess(res.result))
      } catch (err) {
        dispatch(fetchNotificationsError(err))
      }
    }
  }

export const markAllAsRead = () => async (dispatch: Dispatch) => {
  dispatch(markAllAsReadRequest())
  try {
    await Api.markAllAsRead()
    dispatch(markAllAsReadSuccess())
  } catch (err) {
    dispatch(markAllAsReadError(err))
  }
}

export const markAsRead = (id: string) => async (dispatch: Dispatch) => {
  dispatch(markAsReadRequest())
  try {
    await Api.markAsRead(id)
    dispatch(markAsReadSuccess(id))
  } catch (err) {
    dispatch(markAsReadError(err))
  }
}

export const removeNotification =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(removeNotificationRequest())
    try {
      await Api.removeNotification(id)
      dispatch(removeNotificationSuccess(id))
    } catch (err) {
      dispatch(removeNotificationError(err))
    }
  }
