import React from "react"

import { IconProps } from "components/Icons/Icons"

const Highlight: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path d="M13.2,22.4 L23.2,22.4 L23.2,20.8 L13.2,20.8 L13.2,22.4 Z M22.6,4.89999997 L19.9,2.19999996 C19.1,1.39999996 17.9,1.39999996 17.1,2.09999996 L3.59999997,14.1 L3.59999997,19.6 L0.799999953,22.4 L3.79999995,22.4 L5.09999997,21.1 L10.6,21.1 L22.6,7.69999998 C23.4,6.79999998 23.4,5.59999997 22.6,4.89999997 Z M5.29999997,19.5 L5.29999997,14.9 L5.29999997,14.9 L9.99999999,19.5 L9.99999999,19.5 L5.29999997,19.5 Z M21.5,6.59999998 L11,18.2 L6.49999998,13.7 L18.2,3.29999996 C18.4,3.19999996 18.6,3.19999996 18.8,3.29999996 L21.4,5.99999997 C21.6,6.19999998 21.6,6.39999998 21.5,6.59999998 Z" />
  </svg>
)
export default Highlight
