import { styled } from "styled-components"

import { Box } from "components/Flexbox"
import { spaceProps, SpaceProps } from "components/Flexbox/spaceProps"

type Props = {
  $fullWidth?: boolean
} & SpaceProps

const Separator = styled(Box)<Props>`
  border: none;
  min-height: 1px;
  max-height: 1px;
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 24px;
  margin-bottom: 24px;

  ${({ $fullWidth }) =>
    $fullWidth &&
    `
    margin-left: -32px;
    margin-right: -32px;
  `}
  ${spaceProps}
`

export default Separator
